import { Button } from '@mui/material';
import React, { FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAuth } from '../../../../../auth/auth-provider';
import { localized } from '../../../../../i18n/i18n';
import { removeHoistToBeTransfered } from '../../../../../state/features/devices/devices-slice';
import { FlexColumn } from '../../../default-components/flex-column';
import { DeviceDealerTransferDialog } from './device-dealer-transfer-dialog';

export const DeviceDealerTransfer: FC = () => {
    const { isHoistManager } = useAuth();
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);

    const dispatch = useDispatch();

    const handleOpenDialog = useCallback(() => {
        setDialogOpen(true);
    }, []);

    const handleCloseDialog = useCallback(() => {
        dispatch(removeHoistToBeTransfered());
        setDialogOpen(false);
    }, [dispatch]);

    return isHoistManager ? (
        <FlexColumn>
            <FlexColumn>
                <DeviceDealerTransferDialog open={dialogOpen} onClose={handleCloseDialog} />
                <Button onClick={handleOpenDialog} disableRipple>
                    {localized('TransferHoistOwnership')}
                </Button>
            </FlexColumn>
        </FlexColumn>
    ) : null;
};

import React, { FC, useMemo, useState } from 'react';
import { localized } from '../../../../i18n/i18n';
import { EnhancedTable, getComparator, HeadCell, Order } from '../enhanced-table/enhanced-table';
import { useSelector } from 'react-redux';
import { selectFacilities } from '../../../../state/features/facilities/facilities-slice';
import { AppState } from '../../../../state/store';
import { TableBody } from '@mui/material';
import { IFacility } from '../../../../api/api';
import { FacilityTableRow } from './facility-table-row';
import { EnhancedTablePagination } from '../enhanced-table/enhanced-table-pagination';

const headCells: HeadCell[] = [
    { id: 'name', label: localized('Name'), width: '300px' },
    { id: 'address', label: localized('Address') },
    { id: 'postalCode', label: localized('PostalCode') },
    { id: 'city', label: localized('City') },
    { id: 'district', label: localized('District') },
    { id: 'country', label: localized('Country') },
    { id: 'contact', label: localized('Contact') },
    { id: 'phone', label: localized('Phone') },
    { id: 'email', label: localized('Email') },
    { id: 'edit', label: '' },
];

export const FacilityTable: FC = React.memo(() => {
    const facilities: any[] = useSelector(selectFacilities);
    const showSpinner = useSelector(
        (store: AppState) => store.facilitiesReducer.pending && store.facilitiesReducer.facilities.length === 0,
    );

    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof any>('name');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    const sortedFacilities = useMemo(
        () => facilities.slice().sort(getComparator(order, orderBy)),
        [facilities, order, orderBy],
    );

    return (
        <>
            <EnhancedTable
                headCells={headCells}
                order={order}
                setOrder={setOrder}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
                showSpinner={showSpinner}>
                <TableBody>
                    {sortedFacilities
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((facility: IFacility) => (
                            <FacilityTableRow facility={facility} />
                        ))}
                </TableBody>
            </EnhancedTable>
            <EnhancedTablePagination
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                count={facilities.length}
            />
        </>
    );
});

import { Drawer } from '@mui/material';
import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Facility } from '../../../../api/api';
import { localized, localizedInterpolation } from '../../../../i18n/i18n';
import { deleteFacility } from '../../../../state/features/facilities/operation';
import {
    closeFacilityDrawer,
    selectFacilityDrawerOpen,
    selectFacilityForEdit,
} from '../../../../state/features/facility-drawer/facility-drawer-slice';
import { FlexColumn } from '../../default-components/flex-column';
import { PageHeader } from '../../default-components/page-header.tsx/page-header';
import { DeleteButtonWithConfirm } from '../delete-button-with-confirm';
import { FacilityForm } from './facility-form';
import { useParams } from 'react-router-dom';

export const FacilityDrawer: FC = React.memo(() => {
    const dispatch = useDispatch();
    const { dealerId } = useParams();

    const drawerOpen = useSelector(selectFacilityDrawerOpen);
    const facilityForEdit = useSelector(selectFacilityForEdit);

    const handleClose = useCallback(() => {
        dispatch(closeFacilityDrawer());
    }, [dispatch]);

    return (
        <Drawer anchor={'right'} open={drawerOpen} onClose={handleClose}>
            <PageHeader
                title={
                    facilityForEdit === undefined
                        ? localized('NewFacility')
                        : localizedInterpolation('EditInt', { area: localized('Facility') })
                }
                area="facilityCreate"
            />
            <FlexColumn padding={'25px 50px 25px 50px'} width={'440px'} height={'100%'}>
                <FacilityForm
                    handleClose={handleClose}
                    existingFacility={facilityForEdit}
                    dealerId={Number(dealerId)}
                />
                {facilityForEdit !== undefined && (
                    <DeleteButtonWithConfirm
                        name={localized('Facilities')}
                        deleteAction={deleteFacility(facilityForEdit as Facility)}
                    />
                )}
            </FlexColumn>
        </Drawer>
    );
});

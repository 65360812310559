import { createAsyncThunk } from '@reduxjs/toolkit';
import { AsyncOperationHandler } from '../../../utilities/platform-helpers/api-helper';
import { FacilityClient, FacilityDto } from '../../../api/api';
import { facilityClient } from '../../api-clients';

export const getAllFacilities = createAsyncThunk('facilities/getAllFacilities', (allowAllForGlobalAdmin: boolean) => {
    return AsyncOperationHandler((client) => (client as FacilityClient).get(allowAllForGlobalAdmin), facilityClient);
});
export const getFacilities = createAsyncThunk('facilities/getFacilities', (organizationId: number) => {
    return AsyncOperationHandler((client) => (client as FacilityClient).getForList(organizationId), facilityClient);
});
export const getFacilitiesForDealerAndSubDealers = createAsyncThunk(
    'facilities/getFacilitiesForDealerAndSubDealers',
    (organizationId: number) => {
        return AsyncOperationHandler(
            (client) => (client as FacilityClient).getFacilitiesForDealerAndSubDealers(organizationId),
            facilityClient,
        );
    },
);

export const createFacility = createAsyncThunk('facilities/createFacility', (facility: FacilityDto) => {
    return AsyncOperationHandler((client) => (client as FacilityClient).post(facility), facilityClient, true);
});

export const updateFacility = createAsyncThunk('facilities/updateFacility', (facility: FacilityDto) => {
    return AsyncOperationHandler((client) => (client as FacilityClient).put(facility), facilityClient, true);
});

export const deleteFacility = createAsyncThunk('facilities/deleteFacility', (facility: FacilityDto) => {
    return AsyncOperationHandler((client) => (client as FacilityClient).delete(facility), facilityClient);
});

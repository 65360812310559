import { createAsyncThunk } from '@reduxjs/toolkit';
import { AsyncOperationHandler } from '../../../utilities/platform-helpers/api-helper';
import {
    DeviceClient,
    DeviceFilterDto,
    DeviceUpdateDTO,
    IDeviceFilterDto,
    IDeviceUpdateDTO,
    ITransfterOwnershipParams,
    TransfterOwnershipParams,
} from '../../../api/api';
import { deviceClient } from '../../api-clients';
import { CreateWebRemarksParams, GetDevicesOptions } from './types';

export const getFilteredDevices = createAsyncThunk('devices/getFilteredDevices', (filterOptions: GetDevicesOptions) => {
    const { filter, pageSize, currentPage, order, orderBy } = filterOptions;
    return AsyncOperationHandler(
        (client) =>
            (client as DeviceClient).getFiltered(filter as DeviceFilterDto, pageSize, currentPage, orderBy, order),
        deviceClient,
    );
});

export const getDeviceDetails = createAsyncThunk('devices/getDetails', (id: number) => {
    return AsyncOperationHandler((client) => (client as DeviceClient).getLatestSnapshotDetails(id), deviceClient);
});

export const getSnapshotsSlim = createAsyncThunk('devices/getSnapshotsSlim', (serialNumber: number) => {
    return AsyncOperationHandler((client) => (client as DeviceClient).getSnapshots(serialNumber), deviceClient);
});

export const getDeviceMetadata = createAsyncThunk('devices/getMetadata', (serialNumber: number) => {
    return AsyncOperationHandler((client) => (client as DeviceClient).getDeviceMetaData(serialNumber), deviceClient);
});

export const getSnapshotsFull = createAsyncThunk('devices/getSnapshotsFull', (serialNumber: number) => {
    return AsyncOperationHandler((client) => (client as DeviceClient).getSnapshotsFull(serialNumber), deviceClient);
});

export const getDeviceErrorsBySerialNumber = createAsyncThunk(
    'devices/getDeviceErrorsBySerialNumber',
    (serialNumber: number) => {
        return AsyncOperationHandler(
            (client) => (client as DeviceClient).getDeviceErrorsBySerialNumber(serialNumber),
            deviceClient,
        );
    },
);

export const getDeviceCount = createAsyncThunk('lazyLoading/getDeviceCount', (filters: IDeviceFilterDto) => {
    return AsyncOperationHandler(
        (client) => (client as DeviceClient).getDeviceCount(filters as DeviceFilterDto),
        deviceClient,
    );
});

export const updateFacilityAndDealerOnDevice = createAsyncThunk(
    'devices/updateFacilityAndDealerOnDevice',
    (deviceUpdateDTO: IDeviceUpdateDTO) => {
        return AsyncOperationHandler(
            (client) => (client as DeviceClient).updateFacilityAndDealerOnDevice(deviceUpdateDTO as DeviceUpdateDTO),
            deviceClient,
            true,
        );
    },
);

export const createWebRemarks = createAsyncThunk('devices/createWebRemarks', (params: CreateWebRemarksParams) => {
    return AsyncOperationHandler(
        (client) => (client as DeviceClient).createWebRemark(params.serialNumber, params.remarkText),
        deviceClient,
    );
});

export const makeHoistObsolete = createAsyncThunk('devices/makeHoistObsolete', (serialNumber: number) => {
    return AsyncOperationHandler(
        (client) => (client as DeviceClient).makeHoistObsolete(serialNumber),
        deviceClient,
        true,
    );
});

export const tryGetSlimDevice = createAsyncThunk('devices/tryGetSlimDevice', (serialNumber: number) => {
    return AsyncOperationHandler((client) => (client as DeviceClient).tryGetSlimDevice(serialNumber), deviceClient);
});

export const transferOwnership = createAsyncThunk(
    'devices/transferOwnership',
    (moveHoistDTO: ITransfterOwnershipParams) => {
        return AsyncOperationHandler(
            (client) => (client as DeviceClient).transferOwnership(moveHoistDTO as TransfterOwnershipParams),
            deviceClient,
            true,
        );
    },
);

export const getDeviceModels = createAsyncThunk('devices/getDeviceModels', () => {
    return AsyncOperationHandler((client) => (client as DeviceClient).getDeviceModelNames(), deviceClient);
});

import React, { FC } from 'react';
import { PageContentContainer } from '../default-components/page-container/page-content-container';
import { FlexColumn } from '../default-components/flex-column';
import { SxProps } from '@mui/material';

const styles: { [key: string]: SxProps } = {
    container: {
        display: 'flex',
        mr: '50px',
        ml: '50px',
    },
};

export const MainPageContainer: FC = React.memo((props) => {
    return (
        <PageContentContainer sx={styles.container}>
            <FlexColumn minWidth="calc(100vw - 220px)">{props.children}</FlexColumn>
        </PageContentContainer>
    );
});

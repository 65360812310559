import { Action, combineReducers, configureStore, Reducer, ThunkAction } from '@reduxjs/toolkit';
import { PersistConfig, persistReducer } from 'redux-persist';
import { PersistPartial } from 'redux-persist/lib/persistReducer';
import storage from 'redux-persist/lib/storage';
import authReducerBase from './features/auth/auth-slice';
import organizationReducer from './features/organization/organization-slice';
import pageSettingReducerBase from './features/page-header-setting/page-header-setting-slice';
import snackbarReducer from './features/snackbar/snackbar-slice';
import usersReducer from './features/users/users-slice';
import devicesReducer from './features/devices/devices-slice';
import webAppReducerBase from './features/webapp/webapp-slice';
import settingsReducerBase from './features/settings/settings-slice';
import userDrawerReducer from './features/user-drawer/user-drawer-slice';
import dealerDrawerReducer from './features/dealer-drawer/dealer-drawer-slice';
import facilitiesReducer from './features/facilities/facilities-slice';
import facilityDrawerReducer from './features/facility-drawer/facility-drawer-slice';
import productionTestReducer from './features/production-test/production-tests-slice';
import deviceLazyLoadingReducer from './features/device-pagination/device-lazy-loading-slice';
import { setStore } from './store-container';
import { paginationMiddleware } from './features/device-pagination/pagination-middleware';

const createPersistReducer = <T extends Reducer<any, Action<any>>>(config: PersistConfig<any>, reducer: T) => {
    return persistReducer(config, reducer) as typeof reducer & PersistPartial;
};

// Create persisted reducers
const webappPersistConfig: PersistConfig<any> = {
    key: 'webapp',
    storage,
    whitelist: ['open'],
    blacklist: ['showOverlaySpinner'],
};
const webAppReducer = createPersistReducer(webappPersistConfig, webAppReducerBase);

const settingsPersistConfig: PersistConfig<any> = {
    key: 'settingsReducer',
    storage,
    whitelist: ['selectedOrganization'],
};
const settingsReducer = createPersistReducer(settingsPersistConfig, settingsReducerBase);

const pageSettingPersistConfig: PersistConfig<any> = {
    key: 'pageSettingReducer',
    storage,
    whitelist: ['pageSettings'],
};

const pageSettingReducer = createPersistReducer(pageSettingPersistConfig, pageSettingReducerBase);

const authPersistConfig: PersistConfig<any> = {
    key: 'authReducer',
    storage,
    whitelist: ['token'],
};
const authReducer = createPersistReducer(authPersistConfig, authReducerBase);

const reducers = {
    snackbarReducer,
    authReducer,
    settingsReducer,
    webAppReducer,
    pageSettingReducer,
    usersReducer,
    devicesReducer,
    organizationReducer,
    userDrawerReducer,
    dealerDrawerReducer,
    facilitiesReducer,
    facilityDrawerReducer,
    productionTestReducer,
    deviceLazyLoadingReducer,
};

// Allow middleware to have app state without broken circular reference
const rootReducer = combineReducers(reducers);
export type AppState = ReturnType<typeof rootReducer>;

export const store = configureStore({
    reducer: reducers,
    // Middleware declard here now as old method was deprecated. Auth interceptor no longer used as functionality is handled elsewere.
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            // Needed as Date object is considered un-serializable by redux
            serializableCheck: false,
        }).prepend(paginationMiddleware.middleware),
});

setStore(store);

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action<string>>;

import React, { ChangeEvent, FC, KeyboardEvent, SetStateAction, useCallback } from 'react';
import { localized } from '../../../i18n/i18n';
import { FormControl, IconButton, InputAdornment, OutlinedInput, SxProps } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const textFieldStyle: SxProps = {
    backgroundColor: 'white',
};

interface Props {
    searchText: string;
    setSearchText: (value: SetStateAction<string>) => void;
}

export const Searchbar: FC<Props> = React.memo(({ searchText, setSearchText }) => {
    const handleChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setSearchText(e.target.value);
        },
        [setSearchText],
    );

    const handleMouseDownPassword = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    }, []);

    return (
        <FormControl>
            <OutlinedInput
                sx={textFieldStyle}
                placeholder={localized('Search')}
                value={searchText}
                onChange={handleChange}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton edge="end" onMouseDown={handleMouseDownPassword}>
                            <SearchIcon />
                        </IconButton>
                    </InputAdornment>
                }
            />
        </FormControl>
    );
});

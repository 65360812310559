export enum ModelType {
    air200,
    air205,
    air200Irc,
    air300,
    air350,
    air500,
    nomad255,
    nomad205,
    quickRaiser1,
    quickRaiser2,
    quickRaiser2Plus,
    partner255,
    partner230,
    mover205,
    quickRaiser205,
    mover180,
    mover300,
    smart150,
    oldSparePart,
    newSparePart,
    unknown,
}

import React, { FC, useEffect } from 'react';
import { Box, Divider, Paper, SxProps, Typography } from '@mui/material';
import { backgroundColor, backgroundColorPaper } from '../../styles/color-constants';
import { localized } from '../../i18n/i18n';
import { useDispatch, useSelector } from 'react-redux';
import { selectDeviceMetadata } from '../../state/features/devices/devices-slice';
import { getDeviceMetadata } from '../../state/features/devices/operation';

const styles: { [key: string]: SxProps } = {
    paper: {
        alignItems: 'center',
        display: 'flex',
        boxShadow: 'none',
        borderRadius: '5px',
        paddingRight: '20px',
        paddingLeft: '20px',
        height: '80px',
        backgroundColor: backgroundColorPaper,
    },
    divider: {
        backgroundColor: backgroundColor,
        borderColor: backgroundColor,
        marginRight: '20px',
        marginLeft: '60px',
    },
};

interface Props {
    serialNumber: number;
}

export const DeviceMetaData: FC<Props> = React.memo(({ serialNumber }) => {
    const dispatch = useDispatch();
    const deviceMetaData = useSelector(selectDeviceMetadata);

    useEffect(() => {
        dispatch(getDeviceMetadata(serialNumber));
    }, [serialNumber, dispatch]);

    return deviceMetaData !== undefined ? (
        <Paper sx={styles.paper}>
            <GridCell header={localized('SerialNumber')}>{deviceMetaData.serialNumber}</GridCell>
            <Divider orientation={'vertical'} variant={'middle'} flexItem sx={styles.divider} />
            <GridCell header={localized('Dealer')}>{deviceMetaData.dealerName}</GridCell>
            <Divider orientation={'vertical'} variant={'middle'} flexItem sx={styles.divider} />
            <GridCell header={localized('Facility')}>{deviceMetaData.facilityName}</GridCell>
            <Divider orientation={'vertical'} variant={'middle'} flexItem sx={styles.divider} />
            <GridCell header={localized('LifterType')}>{deviceMetaData.modelName}</GridCell>
        </Paper>
    ) : null;
});

interface GridCellProps {
    header: string;
}

const GridCell: React.FC<GridCellProps> = React.memo(({ header, children }) => {
    return (
        <Box>
            <Typography fontSize={'14px'} lineHeight={'1.2'}>
                {header}
            </Typography>
            <Typography fontSize={'21px'} fontWeight="bold" lineHeight={'1.2'}>
                {children}
            </Typography>
        </Box>
    );
});

import { TableBody, TablePagination } from '@mui/material';
import React, { ChangeEvent, FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IWebListDeviceDto } from '../../../../api/api';
import { localized } from '../../../../i18n/i18n';
import { AppState } from '../../../../state/store';
import { LeftBorderRadius, RightBorderRadius } from '../../../../styles/styling-constants';
import { EnhancedTable, EnhancedTableCell, EnhancedTableRow, HeadCell, Order } from '../enhanced-table/enhanced-table';
import { formatDateTimeFull, formatDateTimeShort } from '../../../../utilities/helpers/date-format-helper';
import {
    selectCount,
    selectCurrentPage,
    selectOrder,
    selectOrderBy,
    selectPageSize,
    setLazyOrdering,
    setPage,
    setPageSize,
} from '../../../../state/features/device-pagination/device-lazy-loading-slice';
import { getDeviceCount, getFilteredDevices } from '../../../../state/features/devices/operation';
import moment from 'moment/moment';
import { modelNumberToType, noInspectionVersions } from '../../../../utilities/helpers/model-type-helper';

const headCells: HeadCell[] = [
    { id: 'serialNumber', label: localized('Serial'), width: '145px' },
    { id: 'createdOn', label: localized('ProductionDate'), width: '200px' },
    { id: 'modelName', label: localized('Model'), width: '350px' },
    { id: 'appTime', label: localized('LastChangedDate'), width: '200px' },
    { id: 'remainingLiftsToService', label: localized('RemainingLiftsTillService'), width: '200px' },
    { id: 'lastInspection', label: localized('NextInspection'), width: '200px' },
    { id: 'facilityName', label: localized('Facility'), width: '200px' },
    { id: 'department', label: localized('Department'), width: '200px' },
];

interface Props {
    devices: any[];
    displayDeviceDetails: (device: IWebListDeviceDto) => () => void;
}

export const DevicesTable: FC<Props> = React.memo((props) => {
    const { displayDeviceDetails, devices } = props;
    const dispatch = useDispatch();

    const paginationPage = useSelector(selectCurrentPage);
    const pageSize = useSelector(selectPageSize);
    const deviceCount = useSelector(selectCount);
    const order = useSelector(selectOrder);
    const orderBy = useSelector(selectOrderBy);

    const showSpinner = useSelector(
        (store: AppState) => store.devicesReducer.pending && store.devicesReducer.devices.length === 0,
    );

    useEffect(() => {
        if (devices.length !== 0) return;

        dispatch(
            getFilteredDevices({
                currentPage: paginationPage,
                pageSize: pageSize,
                filter: {},
                orderBy: 'serialNumber',
                order: 'asc',
            }),
        );
        dispatch(getDeviceCount({}));
        // eslint-disable-next-line
    }, [dispatch]);

    const getRemainingLiftsTillServiceField = useCallback((row: IWebListDeviceDto) => {
        if (
            row.remainingLiftsToService === undefined ||
            row.liftsSinceService === undefined ||
            row.remainingLiftsToService + row.liftsSinceService < 1000
        ) {
            return '';
        }
        return row.remainingLiftsToService;
    }, []);

    const handlePageChange = useCallback(
        (e: unknown, newPage: number) => {
            dispatch(setPage(newPage));
        },
        [dispatch],
    );

    const handlePageSizeChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            dispatch(setPageSize(parseInt(event.target.value, 10)));
        },
        [dispatch],
    );

    const handleSort = useCallback(
        (e: React.MouseEvent<unknown>, property: keyof any) => {
            const isAsc = orderBy === property && order === 'asc';

            dispatch(setLazyOrdering({ order: isAsc ? 'desc' : 'asc', orderBy: property }));
        },
        [dispatch, order, orderBy],
    );

    return (
        <>
            <EnhancedTable
                headCells={headCells}
                order={order as Order}
                orderBy={orderBy}
                showSpinner={showSpinner}
                handleRequestSort={handleSort}>
                <TableBody>
                    {devices.map((row: IWebListDeviceDto) => {
                        return (
                            <EnhancedTableRow
                                key={row.serialNumber}
                                onClick={displayDeviceDetails(row)}
                                sx={{ cursor: 'pointer' }}>
                                <EnhancedTableCell sx={LeftBorderRadius}>{row.serialNumber}</EnhancedTableCell>
                                <EnhancedTableCell>{formatDateTimeFull(row.createdOn)}</EnhancedTableCell>
                                <EnhancedTableCell>{row.modelName}</EnhancedTableCell>
                                <EnhancedTableCell>{formatDateTimeFull(row.lastSnapshotTime)}</EnhancedTableCell>
                                <EnhancedTableCell>{getRemainingLiftsTillServiceField(row)}</EnhancedTableCell>
                                <EnhancedTableCell>
                                    {!noInspectionVersions.includes(modelNumberToType(row.productNumber)) &&
                                    row?.lastInspectionTime !== undefined
                                        ? formatDateTimeShort(moment(row?.lastInspectionTime).add(1, 'years').toDate())
                                        : ''}
                                </EnhancedTableCell>
                                <EnhancedTableCell>
                                    {row.facilityName === 'OBSOLETE HOISTS'
                                        ? `${localized('ObsoletedFrom')} ${row.previousOrganizationName ?? 'Unknown'}`
                                        : row.facilityName}
                                </EnhancedTableCell>
                                <EnhancedTableCell sx={RightBorderRadius}>{row.department ?? ''}</EnhancedTableCell>
                            </EnhancedTableRow>
                        );
                    })}
                </TableBody>
            </EnhancedTable>
            <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                count={deviceCount}
                page={paginationPage}
                rowsPerPage={pageSize}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handlePageSizeChange}
            />
        </>
    );
});

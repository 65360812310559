import React, { createContext, memo, useCallback, useContext, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { OrganizationRole } from '../api/api';
import { setAccessToken, setAuthPending, setInitLoginSilent } from '../state/features/auth/auth-slice';
import { AppState } from '../state/store';
import { getSubFromToken, isTokenExpired, setPostLoginRedirectUri } from '../utilities/platform-helpers/auth-helper';
import { usePrevious } from '../utilities/use-previous-hook';
import { tokenStorageKey } from './auth-constants';
import { AuthUser, IAuthContext } from './auth-types';
import {
    clientAcquireTokenSilent,
    clientLoginSilent,
    clientLoginWithRedirect,
    clientLogout,
    initAuthClient,
} from './b2c/b2c-auth-provider';

// Initial state for the auth context.
const initialState: IAuthContext = {
    isAuthenticated: undefined,
    user: undefined,
    loading: true,
    isHoistManager: false,
    isAdmin: false,
    isGlobalAdmin: false,
    authId: '',
    loginSilent: () => {},
    login: () => {},
    logout: () => {},
    getToken: () => '',
    validateOrganizationPermissions: () => {},
    tokenExpiredHandling: () => true,
};

const AuthContext = createContext(initialState);
export const useAuth = (): IAuthContext => useContext(AuthContext);

export const GeneralAuthProvider = memo(({ children }) => {
    // State for holding values related to the user
    const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>(undefined);
    const [user, setUser] = useState<AuthUser | undefined>();
    const [authId, setAuthId] = useState<string>('');
    const [token, setToken] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [isHoistManager, setIsHoistManager] = useState<boolean>(false);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [isGlobalAdmin, setIsGlobalAdmin] = useState(false);
    const [triggerLogin, setTriggerLogin] = useState<boolean>(false);
    const [triggerLogout, setTriggerLogout] = useState<boolean>(false);
    const prevTriggerLogin = usePrevious(triggerLogin);
    const prevTriggerLogout = usePrevious(triggerLogout);
    // Special case for api, as AsyncOperationHandler cannot access the useAuth hook.
    const { initLogin, initLoginSilent } = useSelector((store: AppState) => store.authReducer);
    const prevInitLogin = usePrevious(initLogin);
    const prevInitLoginSilent = usePrevious(initLoginSilent);
    const dispatch = useDispatch();
    const selectedOrganization = useSelector((store: AppState) => store.settingsReducer.selectedOrganization);
    const [validateOrganizationPermission, setValidateOrganizationPermission] = useState<boolean>(false);
    const activeUser = useSelector((store: AppState) => store.usersReducer.activeUser);
    const currentUserPermissions = useSelector((store: AppState) => store.usersReducer.currentUserPermissions);

    const setLoadingHelper = useCallback(
        (value: boolean) => {
            setLoading(value);
            dispatch(setAuthPending(value));
        },
        [dispatch],
    );

    const setTokenHelper = useCallback(
        (value: string) => {
            setToken(value);
            localStorage.setItem(tokenStorageKey, value);
            dispatch(setAccessToken(value));
        },
        [dispatch],
    );

    // Callback function for registering the relevant information about the user in the auth provider.
    const handleTokenResponseCallback = useCallback(
        (tokenResponse: string) => {
            setTokenHelper(tokenResponse);
            setAuthId(getSubFromToken()!);
            setTriggerLogin(false);
            setIsAuthenticated(true);
            setLoadingHelper(false);
        },
        [setLoadingHelper, setTokenHelper],
    );

    // Callback function to reset the authentication state held in the auth provider
    const handleLogoutCallback = useCallback(() => {
        setIsAuthenticated(false);
        setTokenHelper('');
        setAuthId('');
        setUser(undefined);
        setIsAdmin(false);
        setIsHoistManager(false);
        setIsGlobalAdmin(false);
        setTriggerLogout(false);
    }, [setTokenHelper]);

    // Responsible for initiating the client used in the service specific auth providers
    // For B2C and AAD this function instantiates the MSAL instance and registers the provided callbacks
    useEffect(() => {
        const initAuth = async () => {
            await initAuthClient(handleTokenResponseCallback, handleLogoutCallback);
        };
        initAuth();
    }, [handleTokenResponseCallback, handleLogoutCallback]);

    // Attempts to authenticate the user silently with at refresh token.
    // If it fails it will initiate a login with redirect automatically
    const loginSilent = useCallback(async () => {
        setLoadingHelper(true);
        await clientLoginSilent();
        dispatch(setInitLoginSilent(false));
    }, [setLoadingHelper]);
    const getToken = useCallback((): string => {
        return token ?? localStorage.getItem(tokenStorageKey);
    }, [token]);

    // Initiates the login flow
    const login = useCallback(() => {
        setTriggerLogin(true);
    }, []);

    // Initiates the logout flow
    const logout = useCallback(() => {
        // This is if the user chooses to sign in again after signing out
        setPostLoginRedirectUri();
        setTriggerLogout(true);
    }, []);

    // Effect comparing prevState of the variable to avoid multiple calls to login
    useEffect(() => {
        if (triggerLogin && triggerLogin !== prevTriggerLogin) {
            setLoadingHelper(true);
            clientLoginWithRedirect();
        }
    }, [triggerLogin, prevTriggerLogin, setLoadingHelper]);

    // Effect comparing prevState of the variable to avoid multiple calls to logout
    useEffect(() => {
        if (triggerLogout && triggerLogout !== prevTriggerLogout) {
            setLoadingHelper(true);
            clientLogout();
        }
    }, [triggerLogout, prevTriggerLogout, setLoadingHelper]);

    // Handlers for refresh token cases from api-helper
    useEffect(() => {
        if (initLogin && initLogin !== prevInitLogin) {
            setLoadingHelper(true);
            clientLoginWithRedirect();
        }
    }, [initLogin, prevInitLogin, setLoadingHelper]);

    useEffect(() => {
        if (initLoginSilent && initLoginSilent !== prevInitLoginSilent) {
            loginSilent();
        }
    }, [initLoginSilent, prevInitLoginSilent, loginSilent]);

    const validateOrganizationPermissions = useCallback(() => {
        setValidateOrganizationPermission(true);
    }, []);

    const tokenExpiredHandling = useCallback((tokenStorageKey: string) => {
        if (isTokenExpired(tokenStorageKey)) {
            clientAcquireTokenSilent(true);
            return true;
        }
        return false;
    }, []);

    useEffect(() => {
        setIsGlobalAdmin(currentUserPermissions.isGlobalAdmin);
        if (currentUserPermissions.isGlobalAdmin) {
            // Override admin permissions if user is global admin
            setIsAdmin(true);
            setIsHoistManager(true);
        } else if (currentUserPermissions?.organizationPermissions && selectedOrganization) {
            const organizationRoles = currentUserPermissions.organizationPermissions[selectedOrganization.id];
            if (organizationRoles) {
                setIsAdmin(organizationRoles.organizationRole === OrganizationRole.Admin);
            }
            setIsHoistManager(currentUserPermissions.hoistManager);
        }
        setValidateOrganizationPermission(false);
    }, [selectedOrganization, currentUserPermissions, user, setLoadingHelper]);

    useEffect(() => {
        if (!activeUser) {
            setUser(undefined);
            return;
        }

        let authUser: AuthUser = {
            name: (currentUserPermissions.isSimulated ? currentUserPermissions.userName : activeUser?.name) ?? '',
        };

        // This is done to avoid unnessesary updates in components that subscribe to user changes
        if (!shallowEqual(user, authUser)) {
            setUser(authUser);
        }
    }, [selectedOrganization, activeUser]);

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated,
                loading,
                user,
                isHoistManager,
                isAdmin,
                isGlobalAdmin,
                authId,
                login,
                logout,
                loginSilent,
                getToken,
                validateOrganizationPermissions,
                tokenExpiredHandling,
            }}>
            {children}
        </AuthContext.Provider>
    );
});

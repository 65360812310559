import { IconButton, SxProps, Typography } from '@mui/material';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UserSwapIcon from '@mui/icons-material/SwapHoriz';
import StopSimulationIcon from '@mui/icons-material/StopCircle';
import { localized } from '../../../../i18n/i18n';
import { setForSimulation, setUserToEdit } from '../../../../state/features/user-drawer/user-drawer-slice';
import { stopSimulation } from '../../../../state/features/users/operation';
import {
    refreshAfterSimulate,
    selectAllUsers,
    selectCurrentUser,
    selectCurrentUserPermissions,
    selectSimulateUserRefreshPending,
} from '../../../../state/features/users/users-slice';
import { primaryColor, primaryTextColor } from '../../../../styles/color-constants';
import { FlexColumn } from '../../default-components/flex-column';

const styles: { [key: string]: SxProps } = {
    userSimulatorButton: {
        marginTop: '8px',
        color: primaryTextColor,
        '&:hover': {
            backgroundColor: 'transparent',
            color: primaryColor,
        },
    },
    iconContainer: {
        alignItems: 'center',
    },
    iconStyle: {
        transform: 'scale(1.5)',
    },
};

export const UserSimulator: FC = () => {
    const dispatch = useDispatch();
    const currentUser = useSelector(selectCurrentUser);
    const allUsers = useSelector(selectAllUsers);
    const currentUserPermissions = useSelector(selectCurrentUserPermissions);
    const getSimulateUserRefreshPending = useSelector(selectSimulateUserRefreshPending);

    const user = useMemo(() => {
        const users = allUsers.filter((u) => u.authId === currentUser?.authId);
        if (users.length > 1) throw new Error('Multiple users found');
        return users[0];
    }, [currentUser, allUsers]);

    const userIcon = useMemo(() => {
        return currentUserPermissions?.isSimulated ? (
            <StopSimulationIcon sx={styles.iconStyle} />
        ) : (
            <UserSwapIcon sx={styles.iconStyle} />
        );
    }, [styles, currentUserPermissions]);

    const simulationButtonText = currentUserPermissions.isSimulated
        ? localized('EndSimulate')
        : localized('SimulateUser');

    useEffect(() => {
        if (!getSimulateUserRefreshPending) return;

        dispatch(refreshAfterSimulate());
    }, [getSimulateUserRefreshPending]);

    const handleSimulatedUserButtonClick = useCallback(() => {
        if (currentUserPermissions?.isSimulated) {
            stopCurrentSimulation();
        } else {
            openDrawer();
            dispatch(setForSimulation(true));
        }
    }, [currentUserPermissions, user, dispatch]);

    const stopCurrentSimulation = useCallback(() => {
        dispatch(stopSimulation());
    }, [dispatch]);

    const openDrawer = useCallback(() => {
        if (!user) return;

        dispatch(setUserToEdit(user));
    }, [user, dispatch]);

    return (
        <FlexColumn>
            <IconButton sx={styles.userSimulatorButton} disableRipple onClick={handleSimulatedUserButtonClick}>
                <FlexColumn sx={styles.iconContainer}>
                    {userIcon}
                    <Typography>{simulationButtonText}</Typography>
                </FlexColumn>
            </IconButton>
        </FlexColumn>
    );
};

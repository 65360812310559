import React, {FC, memo} from 'react';
import {Outlet} from 'react-router-dom';
import {BasicSpinner} from '../view/components/default-components/spinner/basic-spinner';
import {useAuth} from './auth-provider';
import {NoPermissionPage} from './no-permission-page';

// Redirect to start page, if somehow a normal user access an admin page
export const AdminRoute: FC = memo(({children}) => {
  const {isAdmin, loading} = useAuth();
  if (loading) {
    return <BasicSpinner size={100} />;
  }
  if (!isAdmin) {
    return <NoPermissionPage />;
  }
  return <Outlet />;
});

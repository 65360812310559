import { Grid, GridProps } from '@mui/material';
import React, { PropsWithChildren, useMemo } from 'react';
import { FC } from 'react';

interface Props {
    itemsPerRow?: number;
}

export const GridContainer: FC<PropsWithChildren<Props> & GridProps> = React.memo(
    ({ itemsPerRow = 1, children, ...props }) => {
        const gridContainerSize = 12 * itemsPerRow;

        return (
            <Grid container rowSpacing={0} columnSpacing={2} columns={gridContainerSize} {...props}>
                {React.Children.map(children, (child) => {
                    return (
                        <Grid item xs={12}>
                            {child}
                        </Grid>
                    );
                })}
            </Grid>
        );
    },
);

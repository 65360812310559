import { localized, localizedDynamic } from '../../i18n/i18n';
import { Monitor } from '../../services/telemetry-service';
import { ApiClient } from '../../state/api-clients';
import { initLogin, setInitLoginSilent, setAuthPending } from '../../state/features/auth/auth-slice';
import { showErrorSnackbar, showSuccessSnackbar } from '../../state/features/snackbar/snackbar-slice';
import { getState, dispatchAction } from '../../state/store-container';
import { isTokenExpired } from './auth-helper';
import { isJson } from './json-helper';

// Used for createAsyncThunk
export const AsyncOperationHandler = async <TApiReturn>(
    apiCall: (cl: ApiClient) => Promise<TApiReturn>,
    client: ApiClient,
    showResponseMessage: boolean = false,
) => {
    try {
        // Check to ensure accesstoken has not expired
        if (isTokenExpired()) {
            // Set login pending for wait function
            dispatchAction(setAuthPending(true));
            // Trigger login to get new token
            dispatchAction(setInitLoginSilent(true));
            // Wait for new token
            await waitForToken();
            // replace token with new token.
        }
        const result = await apiCall(client);
        if (showResponseMessage) {
            dispatchAction(showSuccessSnackbar(localized('Saved')));
        }
        return result;
    } catch (error) {
        const apiError = error as ApiError;
        if (apiError.status === 401) {
            apiError.name = 'Unauthorized';
            if (isTokenExpired()) {
                Monitor.logTrace('Unauthorized: Token has expired');
                // Failsafe for expired token
                dispatchAction(initLogin(true));
            } else {
                Monitor.logTrace("Unauthorized: User don't have the required permissions");
            }
            dispatchAction(showErrorSnackbar(localizedDynamic('Unauthorized')));
        } else {
            if (apiError.response) {
                apiError.message = apiError.response;
                apiError.name = 'Error';
                if (apiError.message) {
                    dispatchAction(showErrorSnackbar(localizedDynamic(getErrorMessage(apiError.message))));
                }
            }
        }

        throw apiError;
    }
};

export const getErrorMessage = (errorResponse: string) => {
    if (isJson(errorResponse)) {
        const jsonError = JSON.parse(errorResponse);
        const errorMessage = jsonError.message ? jsonError.message : jsonError;
        return errorMessage;
    }
    return errorResponse;
};

export interface ApiError {
    status: number;
    response: string;
    message: string;
    name: string;
}

export const waitForToken = async () => {
    const { pending } = getState().authReducer;
    if (pending) {
        // Checking once every 200 ms
        await sleep(200);
        await waitForToken();
    } else {
        // Sleep again to let accessToken be processed
        await sleep(500);
    }
};

function sleep(ms: number) {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
}

import React, { FC, useCallback, useState } from 'react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { localized } from '../../../../i18n/i18n';
import { Button, Dialog, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import { deviceDetailsStyles } from './device-details-dialog';
import { RoundButton } from '../round-button';
import { useDispatch } from 'react-redux';
import { makeHoistObsolete } from '../../../../state/features/devices/operation';

interface Props {
    closeParentDialog: () => void;
    deviceSerialNumber: number;
}

export const RemoveDeviceButton: FC<Props> = React.memo(({ closeParentDialog, deviceSerialNumber }) => {
    const dispatch = useDispatch();

    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

    const handleRemoveHoist = useCallback(() => {
        setConfirmDialogOpen(true);
    }, []);

    const handleDeleteCancel = useCallback(() => {
        setConfirmDialogOpen(false);
    }, []);

    const handleDeleteConfirm = useCallback(() => {
        dispatch(makeHoistObsolete(deviceSerialNumber));
        setConfirmDialogOpen(false);
        closeParentDialog();
    }, [dispatch, closeParentDialog, deviceSerialNumber]);

    return (
        <>
            <Button
                variant={'text'}
                color={'error'}
                sx={{ ...deviceDetailsStyles.button, marginTop: 'auto' }}
                endIcon={<DeleteOutlineIcon />}
                onClick={handleRemoveHoist}>
                {localized('RemoveHoist')}
            </Button>
            <Dialog open={confirmDialogOpen} onClose={handleDeleteCancel}>
                <DialogTitle>{localized('MakeHoistObsolete')}</DialogTitle>
                <DialogContent>
                    <Typography>{localized('MakeHoistObsoleteWarning1')}</Typography>
                    <Typography>{localized('MakeHoistObsoleteWarning2')}</Typography>
                    <Typography>{localized('MakeHoistObsoleteWarning3')}</Typography>
                </DialogContent>
                <Stack spacing={1} direction={'row'} justifyContent={'center'} padding={'20px'} paddingTop={0}>
                    <RoundButton slim color={'secondary'} onClick={handleDeleteCancel}>
                        {localized('Cancel')}
                    </RoundButton>
                    <RoundButton slim onClick={handleDeleteConfirm}>
                        {localized('Confirm')}
                    </RoundButton>
                </Stack>
            </Dialog>
        </>
    );
});

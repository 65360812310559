import React, { FC, useCallback, useState } from 'react';
import { localized, localizedInterpolation } from '../../../i18n/i18n';
import { RoundButton } from './round-button';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { AsyncThunkAction } from '@reduxjs/toolkit';

interface Props {
    name: string;
    deleteAction?: AsyncThunkAction<void, any, {}>;
    handleDeleteManual?: () => void;
    blocked?: boolean;
    blockedText?: string;
}

export const DeleteButtonWithConfirm: FC<Props> = React.memo(
    ({ deleteAction, name, handleDeleteManual, blocked, blockedText }) => {
        const dispatch = useDispatch();
        const { enqueueSnackbar } = useSnackbar();

        const [deleteClicked, setDeleteClicked] = useState(false);

        const handleDelete = useCallback(() => {
            if (!deleteClicked && !blocked) {
                setDeleteClicked(true);
                enqueueSnackbar(localized('DeleteConfirm'), {
                    variant: 'info',
                    autoHideDuration: 2000,
                    anchorOrigin: { horizontal: 'right', vertical: 'top' },
                });
                setTimeout(() => setDeleteClicked(false), 2000);
            } else if (blocked) {
                enqueueSnackbar(blockedText, {
                    variant: 'error',
                    autoHideDuration: 10000,
                    anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
                });
            }
            if (deleteClicked) {
                if (deleteAction !== undefined) {
                    dispatch(deleteAction);
                } else if (handleDeleteManual !== undefined) {
                    handleDeleteManual();
                }
            }
        }, [enqueueSnackbar, deleteClicked, deleteAction, handleDeleteManual, dispatch]);

        return (
            <RoundButton sx={{ marginTop: 'auto', alignSelf: 'center' }} color={'error'} slim onClick={handleDelete}>
                {localizedInterpolation('DeleteInt', { area: name })}
            </RoundButton>
        );
    },
);

import { Button, Stack, debounce } from '@mui/material';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { localized } from '../../../../i18n/i18n';
import {
    resetLazyState,
    selectSearchSerial,
    setSearchSerial,
} from '../../../../state/features/device-pagination/device-lazy-loading-slice';
import { getAllUsers } from '../../../../state/features/users/operation';
import { Searchbar } from '../searchbar';
import { DeviceFilterSelector, FilterOptions } from './device-filter-selector';
import { DynamicFilter } from './dynamic-filter';
import { DeviceDealerTransfer } from './transfer-ownership/device-dealer-transfer';

export const DeviceHeaderContent: FC = React.memo(() => {
    const dispatch = useDispatch();

    const searchSerial = useSelector(selectSearchSerial);

    const [searchText, setSearchText] = useState(searchSerial?.toString() ?? '');
    const [curSelectedFilter, setCurSelectedFilter] = useState<FilterOptions>();

    useEffect(() => {
        // Get users for filter dropdown
        dispatch(getAllUsers());
    }, [dispatch]);

    const updateSearchText = useCallback(
        debounce((val: number | undefined) => {
            dispatch(setSearchSerial(val));
        }, 750),
        [],
    );
    useEffect(() => {
        const number = Number(searchText);

        if (searchText === '') {
            updateSearchText(undefined);
            return;
        }

        if (isNaN(number)) return;
        updateSearchText(number);
    }, [dispatch, searchText]);

    const clearFilters = useCallback(() => {
        setSearchText('');
        dispatch(resetLazyState());
    }, [dispatch]);

    return (
        <Stack direction={'row'} spacing={'8px'} sx={{ marginLeft: 'auto' }} alignItems={'center'} display={'flex'}>
            <DeviceDealerTransfer />
            <Button onClick={clearFilters} disableRipple>
                {localized('ClearFilters')}
            </Button>
            <DeviceFilterSelector handleFilterChange={setCurSelectedFilter} />
            <DynamicFilter curFilterOption={curSelectedFilter} />
            <Searchbar searchText={searchText} setSearchText={setSearchText} />
        </Stack>
    );
});

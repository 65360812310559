import { Box, SxProps, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import React, { FC, useCallback } from 'react';
import { ReactComponent as SortIcon } from '../../../../assets/icons/sort-icon.svg';
import { primaryColor } from '../../../../styles/color-constants';
import { HeadCell, Order } from './enhanced-table';

interface Props {
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof any) => void;
    order: Order;
    orderBy: keyof any;
    headCells: HeadCell[];
}

const styles: { [key: string]: SxProps } = {
    headCell: {
        fontSize: '14px',
        fontWeight: 'bold',
    },
    sortLabel: {
        '&.Mui-active': {
            color: primaryColor,
            '& .MuiTableSortLabel-icon': {
                color: 'inherit',
            },
        },
        '&:hover': {
            color: primaryColor,
        },
    },
};

export const EnhancedTableHead: FC<Props> = React.memo((props) => {
    const { onRequestSort, order, orderBy, headCells } = props;
    const sortHandler = useCallback(
        (property: keyof any) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        },
        [onRequestSort],
    );

    return (
        <TableHead sx={{ marginBottom: '-10px' }}>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id.toString()}
                        align={'left'}
                        padding={'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={{ ...styles.headCell, width: headCell.width }}>
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={sortHandler(headCell.id)}
                            IconComponent={SortIcon}
                            sx={styles.sortLabel}>
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
});

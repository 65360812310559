import { Divider, OutlinedInput, SxProps, Typography } from '@mui/material';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { localized } from '../../../../../i18n/i18n';
import { transferOwnership } from '../../../../../state/features/devices/operation';
import { selectFacilitiesForDealerAndSubDealers } from '../../../../../state/features/facilities/facilities-slice';
import { getFacilitiesForDealerAndSubDealers } from '../../../../../state/features/facilities/operation';
import { selectOrganizationsForDropdown } from '../../../../../state/features/organization/organization-slice';
import { FlexColumn } from '../../../default-components/flex-column';
import { FlexRow } from '../../../default-components/flex-row';
import { RoundButton } from '../../round-button';
import { DealerSelect } from '../dealer-select';
import { FacilitySelect } from '../facility-select';

const styles: { [key: string]: SxProps } = {
    selectRow: {
        justifyContent: 'space-between',
        alignItems: 'center',
        minWidth: 250,
        maxWidth: 250,
        marginBottom: 2,
    },
    textStyle: {
        marginRight: 2,
    },
    reasonStyle: {
        flex: 1,
        minWidth: 160,
        maxWidth: 160,
    },
};

interface Props {
    deviceSerialNumber: number;
    closeDialog: () => void;
}

export const DeviceDealerTransferSelect: FC<Props> = ({ deviceSerialNumber, closeDialog }) => {
    const allOrganizations = useSelector(selectOrganizationsForDropdown);
    const allFacilities = useSelector(selectFacilitiesForDealerAndSubDealers);

    const [selectedOrganizationId, setSelectedOrganizationId] = useState<number | ''>('');
    const [selectedFacilityId, setSelectedFacilityId] = useState<number | ''>('');
    const [reasonPhrase, setReasonPhrase] = useState<string>();
    const [transferButtonDisabled, setTransferButtonDisabled] = useState<boolean>(true);
    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedOrganizationId === '') return;

        dispatch(getFacilitiesForDealerAndSubDealers(selectedOrganizationId));
    }, [selectedOrganizationId, dispatch]);

    useEffect(() => {
        setTransferButtonDisabled(selectedOrganizationId === '' || !reasonPhrase);
    }, [selectedFacilityId, selectedOrganizationId, reasonPhrase]);

    const handleNewOrganizationSelected = useCallback(
        (selectedOrgId: number) => {
            const org = allOrganizations.find((o) => o.id === selectedOrgId);

            setSelectedOrganizationId(org?.id ?? '');
        },
        [allOrganizations],
    );

    const handleNewFacilitySelected = useCallback(
        (selectedFacilityId: number) => {
            const facility = allFacilities.find((f) => f.id === selectedFacilityId);
            if (!facility) return;

            setSelectedFacilityId(facility.id);
            if (selectedOrganizationId === facility.organizationId) return;

            setSelectedOrganizationId(facility.organizationId);
        },
        [allFacilities],
    );

    const handleNewReasonPhrase = useCallback((e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setReasonPhrase(e.target.value);
    }, []);

    const handleTransferDevice = useCallback(() => {
        if (!selectedOrganizationId || !deviceSerialNumber) return;

        dispatch(
            transferOwnership({
                serialNumber: deviceSerialNumber,
                organizationId: selectedOrganizationId,
                facilityId: selectedFacilityId === '' ? undefined : selectedFacilityId,
                reasonPhrase: reasonPhrase,
            }),
        );
    }, [deviceSerialNumber, selectedFacilityId, selectedOrganizationId, reasonPhrase]);

    return (
        <FlexColumn>
            <FlexRow sx={styles.selectRow}>
                <Typography sx={styles.textStyle}>{localized('Dealer') + '*'}</Typography>
                <DealerSelect
                    selectedOrganizationId={selectedOrganizationId}
                    onDealerSelected={handleNewOrganizationSelected}
                />
            </FlexRow>
            <FlexRow sx={styles.selectRow}>
                <Typography sx={styles.textStyle}>{localized('Facility')}</Typography>
                <FacilitySelect
                    selectedFacilityId={selectedFacilityId}
                    selectedOrganizationId={selectedOrganizationId}
                    onNewFacilitySelected={handleNewFacilitySelected}
                />
            </FlexRow>
            <FlexRow sx={styles.selectRow}>
                <Typography sx={styles.textStyle}>{localized('Reason') + '*'}</Typography>
                <OutlinedInput sx={styles.reasonStyle} value={reasonPhrase} onChange={handleNewReasonPhrase} />
            </FlexRow>
            <Divider style={{ width: '100%' }} />
            <RoundButton
                sx={{ alignSelf: 'center', marginTop: 2 }}
                width="80%"
                slim
                onClick={handleTransferDevice}
                disabled={transferButtonDisabled}>
                {localized('TransferOwnership')}
            </RoundButton>
        </FlexColumn>
    );
};

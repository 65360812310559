import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import React, { FC, memo, useMemo } from 'react';
import { Controller, Message, useFormContext, ValidationRule } from 'react-hook-form';
import { theme } from '../../../../styles/theme';
import { DropdownOption } from './form-types';
import { KeyboardArrowDown } from '@mui/icons-material';

interface Props {
    name: string;
    label?: string;
    options: DropdownOption[];
    noDefault?: boolean;
    disabled?: boolean;
    multiple?: boolean;
    validators?: Partial<{
        required: Message | ValidationRule<boolean>;
        disabled: boolean;
    }>;
}

export const SelectInput: FC<Props> = memo((props) => {
    const {
        control,
        formState: { errors },
    } = useFormContext();

    const mapOptionsToItems = useMemo(
        () =>
            props.options.map((option) => (
                <MenuItem value={option.value} key={option.value}>
                    {option.label}
                </MenuItem>
            )),
        [props.options],
    );

    return props.options.length !== 0 ? (
        <Controller
            name={props.name}
            control={control}
            rules={props.validators}
            render={({ field }) => (
                <FormControl sx={{ marginTop: 1, marginBottom: 2 }}>
                    <InputLabel
                        shrink={true}
                        sx={{
                            backgroundColor: theme.palette.common.white,
                            paddingRight: theme.spacing(1),
                            paddingLeft: theme.spacing(1),
                            color: theme.palette.text.primary,
                            marginLeft: '-6px',
                        }}>
                        {props.label}
                    </InputLabel>
                    <Select
                        {...field}
                        disabled={props.disabled}
                        multiple={props.multiple}
                        error={!!errors[props.name]}
                        value={field.value ?? (props.multiple ? [] : props.noDefault ? '' : props.options[0].value)}
                        IconComponent={KeyboardArrowDown}>
                        {mapOptionsToItems}
                    </Select>
                    {!!errors[props.name] && (
                        <FormHelperText sx={{ color: theme.palette.error.main }}>
                            {errors[props.name].message}
                        </FormHelperText>
                    )}
                </FormControl>
            )}
        />
    ) : (
        <></>
    );
});

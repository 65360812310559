import { FacilitiesState } from './types';
import { createSlice } from '@reduxjs/toolkit';
import {
    createFacility,
    deleteFacility,
    getAllFacilities,
    getFacilities,
    getFacilitiesForDealerAndSubDealers,
    updateFacility,
} from './operation';
import { AppState } from '../../store';
import { createSelector } from 'reselect';
import { DropdownOption } from '../../../view/components/default-components/baisc-dropdown/basic-search-dropdown';

const initialState: FacilitiesState = {
    facilities: [],
    facilitiesForDealerAndSubDealers: [],
    allFacilities: [],
    pending: false,
};

export const facilitiesSlice = createSlice({
    name: 'facilities',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAllFacilities.fulfilled, (state, action) => {
            state.pending = false;
            state.allFacilities = action.payload;
        });

        builder.addCase(getAllFacilities.pending, (state) => {
            state.pending = true;
        });

        builder.addCase(getAllFacilities.rejected, (state) => {
            state.pending = false;
        });

        builder.addCase(getFacilities.fulfilled, (state, action) => {
            state.pending = false;
            state.facilities = action.payload;
        });

        builder.addCase(getFacilities.pending, (state) => {
            state.pending = true;
        });

        builder.addCase(getFacilities.rejected, (state) => {
            state.pending = false;
        });
        builder.addCase(getFacilitiesForDealerAndSubDealers.fulfilled, (state, action) => {
            state.pending = false;
            state.facilitiesForDealerAndSubDealers = action.payload;
        });

        builder.addCase(getFacilitiesForDealerAndSubDealers.pending, (state) => {
            state.pending = true;
        });

        builder.addCase(getFacilitiesForDealerAndSubDealers.rejected, (state) => {
            state.facilitiesForDealerAndSubDealers = [];
            state.pending = false;
        });

        builder.addCase(createFacility.fulfilled, (state, action) => {
            state.pending = false;
            state.facilities.push(action.payload);
            state.facilitiesForDealerAndSubDealers.push(action.payload);
        });

        builder.addCase(createFacility.pending, (state) => {
            state.pending = true;
        });

        builder.addCase(createFacility.rejected, (state) => {
            state.pending = false;
        });

        builder.addCase(updateFacility.fulfilled, (state, action) => {
            state.pending = false;
            const index = state.facilities.findIndex((f) => f.id === action.payload.id);
            if (index !== -1) {
                state.facilities[index] = action.payload;
            }
        });

        builder.addCase(updateFacility.pending, (state) => {
            state.pending = true;
        });

        builder.addCase(updateFacility.rejected, (state) => {
            state.pending = false;
        });

        builder.addCase(deleteFacility.fulfilled, (state, action) => {
            state.pending = false;
            const index = state.facilities.findIndex((f) => f.id === action.meta.arg.id);
            if (index !== -1) {
                state.facilities.splice(index, 1);
            }
        });

        builder.addCase(deleteFacility.pending, (state) => {
            state.pending = true;
        });

        builder.addCase(deleteFacility.rejected, (state) => {
            state.pending = false;
        });
    },
});

export const selectFacilities = (store: AppState) => store.facilitiesReducer.facilities;
export const selectFacilitiesForDealerAndSubDealers = (store: AppState) =>
    store.facilitiesReducer.facilitiesForDealerAndSubDealers;
export const selectAllFacilities = (store: AppState) => store.facilitiesReducer.allFacilities;

export const selectFacilityDropdownOptions = createSelector(selectFacilities, (facilities) => {
    return facilities.flatMap((f) => (f.name ? new DropdownOption(f.name, f.id) : []));
});

export default facilitiesSlice.reducer;

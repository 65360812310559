import { TableBody } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { localized, localizedInterpolation } from '../../../../i18n/i18n';
import { selectSnapshotLog } from '../../../../state/features/devices/devices-slice';
import { BasicHeadCell, BasicTable } from '../basic-table/basic-table';
import { SnapshotLogTableRow } from './snapshot-log-table-row';

const headCells: BasicHeadCell[] = [
    { id: 'Time', label: localized('Date&Time'), width: '170px' },
    { id: 'Reason', label: localized('Reason') },
    { id: 'FacilityId', label: localized('FacilityId') },
    { id: 'InspectionsPerformed', label: localized('InspectionCount') },
    { id: 'LastInspection', label: localized('LastInspectionTime'), width: '170px' },
    { id: 'ServicesPerformed', label: localized('ServiceCount') },
    { id: 'LastService', label: localized('LastServiceTime'), width: '170px' },
    { id: 'TotalStdLifts', label: localized('TotalStdLifts'), width: '80px' },
    { id: 'LiftsClass1', label: localizedInterpolation('TotalLiftsClass', { classNumber: 1 }), width: '90px' },
    { id: 'LiftsClass2', label: localizedInterpolation('TotalLiftsClass', { classNumber: 2 }), width: '90px' },
    { id: 'LiftsClass3', label: localizedInterpolation('TotalLiftsClass', { classNumber: 3 }), width: '90px' },
    { id: 'LiftsClass4', label: localizedInterpolation('TotalLiftsClass', { classNumber: 4 }), width: '90px' },
    { id: 'RemainingLiftsTillService', label: localized('RemainingLiftsTillService'), width: '100px' },
    { id: 'MaxKg', label: localized('MaxKg') },
    { id: 'MaxCurrent', label: localized('MaxCurrent') },
    { id: 'ModelName', label: localized('Model') },
    { id: 'OverloadLifts', label: localized('OverloadLifts') },
    { id: 'ProductNumber', label: localized('ProductNumber') },
    { id: 'ProductionWeek', label: localized('ProdWeek') },
    { id: 'ProductionYear', label: localized('ProdYear') },
    { id: 'Remarks', label: localized('Remarks'), width: '170px' },
    { id: 'Department', label: localized('Department') },
    { id: 'SoftwareVersion', label: localized('SoftwareVersion') },
];

export const SnapshotLogTable = React.memo(() => {
    const snapshotLog = useSelector(selectSnapshotLog);

    return (
        <BasicTable headCells={headCells} fullWidth>
            <TableBody>
                {snapshotLog.map((snapshot) => (
                    <SnapshotLogTableRow snapshot={snapshot} />
                ))}
            </TableBody>
        </BasicTable>
    );
});

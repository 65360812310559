import dayjs from 'dayjs';

export function formatDateTimeFull(date?: Date) {
    if (date === undefined) return '';

    return dayjs(date).format('YYYY-MM-DD HH:mm:ss');
}

export function formatTimeWithMilliseconds(date?: Date) {
    if (date === undefined) return '';

    return dayjs(date).format('HH:mm:ss.SSS');
}

export function formatDateTimeShort(date?: Date) {
    if (date === undefined) return '';

    return dayjs(date).format('YYYY-MM-DD');
}

export function getUnixTime(date: Date): number {
    return Math.floor(date.getTime() / 1000);
}

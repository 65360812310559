import React, { FC, useCallback, useState } from 'react';
import { PageTitle } from '../../components/default-components/page-title/page-title';
import { localized } from '../../../i18n/i18n';
import { PageHeader } from '../../components/default-components/page-header.tsx/page-header';
import { MainPageContainer } from '../../components/etac-components/main-page-container';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveDevice, selectDevices } from '../../../state/features/devices/devices-slice';
import { getDeviceDetails } from '../../../state/features/devices/operation';
import { IWebListDeviceDto } from '../../../api/api';
import { DeviceDetailsDialog } from '../../components/etac-components/devices/device-details-dialog';
import { DevicesTable } from '../../components/etac-components/devices/devices-table';
import { DeviceHeaderContent } from '../../components/etac-components/devices/device-header-content';

export const Devices: FC = React.memo(() => {
    const dispatch = useDispatch();
    const devices = useSelector(selectDevices);
    const activeDevice = useSelector(selectActiveDevice);

    const [dialogOpen, setDialogOpen] = useState(false);

    const displayDeviceDetails = useCallback(
        (device: IWebListDeviceDto) => () => {
            dispatch(getDeviceDetails(device.id));
            setDialogOpen(true);
        },
        [dispatch],
    );

    const handleClose = useCallback(() => {
        setDialogOpen(false);
    }, []);

    return (
        <PageTitle title={localized('Devices')}>
            <PageHeader title={localized('DeviceAdministration')} area={'devices'}>
                <DeviceHeaderContent />
            </PageHeader>
            <MainPageContainer>
                <DevicesTable devices={devices} displayDeviceDetails={displayDeviceDetails} />
            </MainPageContainer>
            <DeviceDetailsDialog open={dialogOpen} onClose={handleClose} device={activeDevice} />
        </PageTitle>
    );
});

import { FileResponse } from '../api/api';
import React, { useRef, useState } from 'react';

// Taken from https://www.techprescient.com/react-custom-hook-typescript-to-download-a-file-through-api/
interface DownloadFileProps {
    readonly apiDefinition: () => Promise<FileResponse | null>;
    readonly preDownloading: () => void;
    readonly postDownloading: () => void;
    readonly onError: () => void;
    readonly getFileName: () => string;
}

interface DownloadedFileInfo {
    readonly download: () => Promise<void>;
    readonly ref: React.MutableRefObject<HTMLAnchorElement | null>;
    readonly name: string | undefined;
    readonly url: string | undefined;
}

export const useDownloadFile = ({
    apiDefinition,
    preDownloading,
    postDownloading,
    onError,
    getFileName,
}: DownloadFileProps): DownloadedFileInfo => {
    const ref = useRef<HTMLAnchorElement | null>(null);
    const [url, setFileUrl] = useState<string>();
    const [name, setFileName] = useState<string>();

    const download = async () => {
        try {
            preDownloading();
            const { data } = (await apiDefinition()) as FileResponse;
            const url = URL.createObjectURL(data instanceof Blob ? data : new Blob([data]));
            setFileUrl(url);
            setFileName(getFileName());
            ref.current?.click();
            postDownloading();
            URL.revokeObjectURL(url);
        } catch (error) {
            console.error(error);
            onError();
        }
    };

    return { download, ref, url, name };
};

import { FacilityDrawerState } from './types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFacility } from '../../../api/api';
import { createFacility, deleteFacility, updateFacility } from '../facilities/operation';
import { AppState } from '../../store';

const initialState: FacilityDrawerState = {
    drawerOpen: false,
};

export const facilityDrawerSlice = createSlice({
    name: 'facilityDrawer',
    initialState,
    reducers: {
        openFacilityDrawer: (state) => {
            state.drawerOpen = true;
        },
        closeFacilityDrawer: (state) => {
            state.drawerOpen = false;
            state.facilityForEdit = undefined;
        },
        openFacilityDrawerForEdit: (state, action: PayloadAction<IFacility | undefined>) => {
            state.facilityForEdit = action.payload;
            state.drawerOpen = true;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createFacility.fulfilled, (state) => {
            state.drawerOpen = false;
            state.facilityForEdit = undefined;
        });

        builder.addCase(updateFacility.fulfilled, (state) => {
            state.drawerOpen = false;
            state.facilityForEdit = undefined;
        });

        builder.addCase(deleteFacility.fulfilled, (state) => {
            state.drawerOpen = false;
            state.facilityForEdit = undefined;
        });
    },
});
export const selectFacilityDrawerOpen = (store: AppState) => store.facilityDrawerReducer.drawerOpen;
export const selectFacilityForEdit = (store: AppState) => store.facilityDrawerReducer.facilityForEdit;

export const { openFacilityDrawer, closeFacilityDrawer, openFacilityDrawerForEdit } = facilityDrawerSlice.actions;
export default facilityDrawerSlice.reducer;

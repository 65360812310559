import { Divider, ListItemButton, MenuItem, SelectChangeEvent } from '@mui/material';
import { SxProps } from '@mui/system';
import React, { FC, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IFacilityDto } from '../../../../api/api';
import { useAuth } from '../../../../auth/auth-provider';
import { localized } from '../../../../i18n/i18n';
import { selectFacilitiesForDealerAndSubDealers } from '../../../../state/features/facilities/facilities-slice';
import { openFacilityDrawer } from '../../../../state/features/facility-drawer/facility-drawer-slice';
import { selectOrganizationsForDropdown } from '../../../../state/features/organization/organization-slice';
import { SortByProperty } from '../../../../utilities/platform-helpers/sorting-helper';
import SelectWithGroupings, { SelectGroup } from './select-with-groupings';

const styles: { [key: string]: SxProps } = {
    selectStyle: {
        flex: 1,
        minWidth: 160,
        maxWidth: 160,
    },
};

interface Props {
    selectedFacilityId: number | '';
    selectedOrganizationId: number | '';
    onNewFacilitySelected: (newFacilityId: number) => void;
    allowCreateNewFacilities?: boolean;
}

export const FacilitySelect: FC<Props> = ({
    selectedFacilityId,
    selectedOrganizationId,
    onNewFacilitySelected,
    allowCreateNewFacilities = false,
}) => {
    const allOrganizations = useSelector(selectOrganizationsForDropdown);
    const allFacilities = useSelector(selectFacilitiesForDealerAndSubDealers);
    const { isAdmin, isHoistManager } = useAuth();
    const dispatch = useDispatch();

    const allFacilityMenuItemsSorted = useMemo(() => {
        const facilitiesWithAName = allFacilities.filter((f) => f.name);
        const groupedFacilitiesByOrgId = facilitiesWithAName.reduce(
            (group: { [orgId: number]: IFacilityDto[] }, facility) => {
                if (!group[facility.organizationId]) {
                    group[facility.organizationId] = [];
                }
                group[facility.organizationId].push(facility);
                return group;
            },
            {},
        );

        const facilityGroups: SelectGroup[] = Object.entries(groupedFacilitiesByOrgId).map(
            ([orgId, groupedFacilities]) => {
                const organization = allOrganizations.filter((o) => o.id === Number(orgId));

                if (organization.length === 0) return { header: '', menuOptions: [] };

                const sortedFacilities = groupedFacilities.sort((a, b) =>
                    SortByProperty(a.name?.trim(), b.name?.trim()),
                );
                return {
                    header: organization[0].name ?? '',
                    menuOptions: sortedFacilities.map((f) => (
                        <MenuItem key={f.id} value={f.id}>
                            {f.name}
                        </MenuItem>
                    )),
                };
            },
        );
        return facilityGroups;
    }, [allFacilities, allOrganizations]);

    const handleNewFacilitySelected = useCallback(
        (e: SelectChangeEvent<number>) => {
            const facility = allFacilities.find((f) => f.id === e.target.value);
            if (!facility) return;

            onNewFacilitySelected(facility.id);
        },
        [allFacilities],
    );

    const openDrawer = useCallback(() => {
        dispatch(openFacilityDrawer());
    }, [dispatch]);

    return (
        <SelectWithGroupings
            disabled={(!isAdmin && !isHoistManager) || selectedOrganizationId === ''}
            value={selectedFacilityId}
            onChange={handleNewFacilitySelected}
            groups={allFacilityMenuItemsSorted}
            sx={styles.selectStyle}>
            {allowCreateNewFacilities ? (
                <>
                    <Divider />
                    <ListItemButton onClick={openDrawer}>{localized('NewFacility')}</ListItemButton>
                </>
            ) : null}
        </SelectWithGroupings>
    );
};

import React, { FC, useCallback } from 'react';
import { Drawer } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
    closeDealerDrawer,
    selectDealerDrawerOpen,
    selectDealerForEdit,
} from '../../../../state/features/dealer-drawer/dealer-drawer-slice';
import { PageHeader } from '../../default-components/page-header.tsx/page-header';
import { localized } from '../../../../i18n/i18n';
import { FlexColumn } from '../../default-components/flex-column';
import { DealerForm } from './dealer-form';
import { deleteOrganization } from '../../../../state/features/organization/operation';
import { Organization } from '../../../../api/api';
import { DeleteButtonWithConfirm } from '../delete-button-with-confirm';
import { useAuth } from '../../../../auth/auth-provider';

export const DealerDrawer: FC = React.memo(() => {
    const dispatch = useDispatch();
    const { isGlobalAdmin } = useAuth();

    const drawerOpen = useSelector(selectDealerDrawerOpen);
    const dealerForEdit = useSelector(selectDealerForEdit);

    const handleClose = useCallback(() => {
        dispatch(closeDealerDrawer());
    }, [dispatch]);

    return (
        <Drawer anchor={'right'} open={drawerOpen} onClose={handleClose}>
            <PageHeader
                title={dealerForEdit === undefined ? localized('NewDealer') : localized('EditDealer')}
                area="dealerCreate"
            />
            <FlexColumn padding={'25px 50px 25px 50px'} width={'440px'} height={'100%'}>
                <DealerForm handleClose={handleClose} existingDealer={dealerForEdit} />
                {dealerForEdit !== undefined && (
                    <DeleteButtonWithConfirm
                        name={localized('Dealer')}
                        deleteAction={deleteOrganization(dealerForEdit as Organization)}
                        blocked={!isGlobalAdmin}
                        blockedText={localized('ContactSupportForDeletion')}
                    />
                )}
            </FlexColumn>
        </Drawer>
    );
});

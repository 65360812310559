import { Button, ButtonProps, SxProps } from '@mui/material';
import React, { FC, ReactNode, useCallback, useMemo } from 'react';

interface Props extends ButtonProps {
    children?: ReactNode;
    onClick?: () => void;
    slim?: boolean;
    width?: string;
    disabled?: boolean;
    sx?: SxProps;
}

export const RoundButton: FC<Props> = React.memo((props) => {
    const { onClick, slim, width } = props;

    const buttonStyle: SxProps = useMemo(() => {
        return {
            height: slim ? '39px' : '60px',
            width: width ?? '145px',
            textTransform: 'none',
            borderRadius: '30px',
            fontSize: slim ? '14px' : '21px',
        };
    }, [slim, width]);

    const localOnClick = useCallback(() => {
        if (onClick) {
            onClick();
        }
    }, [onClick]);

    return (
        <Button
            variant="contained"
            onClick={localOnClick}
            sx={{ ...buttonStyle, ...props.sx }}
            disabled={props.disabled}
            color={props.color}
            type={props.type}>
            {props.children}
        </Button>
    );
});

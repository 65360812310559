import React, { FC } from 'react';
import { IDeviceErrorDTO } from '../../../../api/api';
import { BasicTableRow } from '../basic-table/basic-table';
import { EnhancedTableCell } from '../enhanced-table/enhanced-table';

interface Props {
    error: IDeviceErrorDTO;
}

export const DeviceErrorTableRow: FC<Props> = React.memo(({ error }) => {
    return (
        <BasicTableRow>
            <EnhancedTableCell>{error.register}</EnhancedTableCell>
            <EnhancedTableCell>{error.request}</EnhancedTableCell>
            <EnhancedTableCell>{error.response}</EnhancedTableCell>
            <EnhancedTableCell>{error.expectedValue}</EnhancedTableCell>
            <EnhancedTableCell>{error.actualValue}</EnhancedTableCell>
        </BasicTableRow>
    );
});

import { ListItemButton, ListItemIcon, ListItemText, SxProps } from '@mui/material';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import {primaryColor, primaryTextColor} from "../../../../styles/color-constants";

const styles: { [key: string]: SxProps } = {
    centered: {
        display: 'flex',
        justifyContent: 'center',
    },
    listItemButton: {
        marginTop: '8px',
        flexWrap: 'wrap',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: primaryTextColor,
        '& .MuiListItemIcon-root': {
            color: primaryTextColor,
        },
        '&:hover': {
            color: primaryColor,
            backgroundColor: 'transparent',
            '& .MuiListItemIcon-root': {
                color: primaryColor,
            },
        },
        '&.Mui-selected': {
            color: primaryColor,
            backgroundColor: 'transparent',
            '& .MuiListItemIcon-root': {
                color: primaryColor,
            },
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
    },
};

interface Props {
    linkTo: string;
    selected: boolean;
    text?: string;
    icon?: JSX.Element;
}

export const MenuItem: FC<Props> = React.memo((props) => {
    return (
        <Link to={props.linkTo} style={{ textDecoration: 'none', textTransform: 'uppercase' }}>
            <ListItemButton selected={props.selected} disableRipple sx={styles.listItemButton}>
                <div>
                    <ListItemIcon sx={styles.centered}>{props.icon}</ListItemIcon>
                    <ListItemText
                        sx={styles.centered}
                        primary={props.text}
                        primaryTypographyProps={{ fontSize: '10px', fontWeight: 600 }}
                    />
                </div>
            </ListItemButton>
        </Link>
    );
});

import { IconButton, TableBody } from '@mui/material';
import moment from 'moment';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IUserDto, InviteStatus, OrganizationRole, ServiceToolRole } from '../../../../api/api';
import { ReactComponent as CheckmarkIcon } from '../../../../assets/icons/checkmark-icon.svg';
import { ReactComponent as GearIcon } from '../../../../assets/icons/gear-icon.svg';
import { localized } from '../../../../i18n/i18n';
import { setUserToEdit } from '../../../../state/features/user-drawer/user-drawer-slice';
import { getAllUsers } from '../../../../state/features/users/operation';
import { selectAllUsers } from '../../../../state/features/users/users-slice';
import { AppState } from '../../../../state/store';
import { primaryTextColor } from '../../../../styles/color-constants';
import { LeftBorderRadius } from '../../../../styles/styling-constants';
import {
    EnhancedTable,
    EnhancedTableCell,
    EnhancedTableRow,
    HeadCell,
    Order,
    getComparator,
} from '../enhanced-table/enhanced-table';
import { EnhancedTablePagination } from '../enhanced-table/enhanced-table-pagination';

const headCells: HeadCell[] = [
    { id: 'name', label: localized('Name'), width: '300px' },
    { id: 'dealerName', label: localized('Dealer'), width: '300px' },
    { id: 'serviceToolRole', label: localized('STLevel'), width: '100px' },
    { id: 'organizationRole', label: localized('WULevel'), width: '100px' },
    { id: 'canUseProductionTool', label: 'PT', width: '25px' },
    { id: 'dealerOnly', label: 'DO', width: '25px' },
    { id: 'hoistManager', label: 'HM', width: '25px' },
    { id: 'lastActive', label: localized('LastActive'), width: '150px' },
    { id: 'edit', label: '', width: '25px' },
];

interface Props {
    searchText: string;
    dealerFilter: string | undefined;
}

export const UsersTable: FC<Props> = React.memo(({ searchText, dealerFilter }) => {
    const dispatch = useDispatch();

    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof any>('name');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    const users: any[] = useSelector(selectAllUsers);
    const showSpinner = useSelector(
        (store: AppState) => store.usersReducer.pending && store.usersReducer.users.length === 0,
    );

    useEffect(() => {
        dispatch(getAllUsers());
    }, [dispatch]);

    useEffect(() => {
        if (page !== 0) {
            setPage(0);
        }
        // eslint-disable-next-line
    }, [searchText]);

    const openDrawer = useCallback(
        (user: IUserDto) => () => {
            dispatch(setUserToEdit(user));
        },
        [dispatch],
    );

    const sortedUsers = useMemo(
        () =>
            users
                .slice()
                .filter(
                    (u) =>
                        u.name.toLowerCase().includes(searchText) &&
                        (dealerFilter === undefined || u.dealerName === dealerFilter),
                )
                .sort(getComparator(order, orderBy)),
        [order, orderBy, users, searchText, dealerFilter],
    );

    const getLastActiveText = useCallback((user: IUserDto): string => {
        switch (user.inviteStatus) {
            case InviteStatus.Invited:
                return localized('ActivationEmailSent');
            case InviteStatus.InviteExpired:
                return localized('ActivationEmailExpired');
            case InviteStatus.InviteAccepted:
                return lastActiveDateTimeToLocalizedString(moment().utc().toDate(), user.lastActive);
        }
    }, []);

    return (
        <>
            <EnhancedTable
                headCells={headCells}
                order={order}
                setOrder={setOrder}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
                showSpinner={showSpinner}>
                <TableBody>
                    {sortedUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: IUserDto) => {
                        return (
                            <EnhancedTableRow key={row.id}>
                                <EnhancedTableCell sx={LeftBorderRadius}>{row.name}</EnhancedTableCell>
                                <EnhancedTableCell>{row.dealerName}</EnhancedTableCell>
                                <EnhancedTableCell>{ServiceToolRole[row.serviceToolRole]}</EnhancedTableCell>
                                <EnhancedTableCell>{OrganizationRole[row.organizationRole]}</EnhancedTableCell>
                                <EnhancedTableCell>{row.canUseProductionTool && <CheckmarkIcon />}</EnhancedTableCell>
                                <EnhancedTableCell>{row.dealerOnly && <CheckmarkIcon />}</EnhancedTableCell>
                                <EnhancedTableCell>{row.hoistManager && <CheckmarkIcon />}</EnhancedTableCell>
                                <EnhancedTableCell>{getLastActiveText(row)}</EnhancedTableCell>
                                <EnhancedTableCell>
                                    <IconButton sx={{ padding: 0, color: primaryTextColor }} onClick={openDrawer(row)}>
                                        <GearIcon />
                                    </IconButton>
                                </EnhancedTableCell>
                            </EnhancedTableRow>
                        );
                    })}
                </TableBody>
            </EnhancedTable>
            <EnhancedTablePagination
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                count={sortedUsers.length}
            />
        </>
    );
});

const lastActiveDateTimeToLocalizedString = (date1: Date, date2: Date) => {
    const diff = Math.floor(date1.getTime() - date2.getTime());
    const day = 1000 * 60 * 60 * 24;
    let days = Math.floor(diff / day);
    let months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    //We only want months excluding the months already counted in the years
    months -= years * 12;
    //We only want days excluding days already counted in years and months
    days -= years * 12 * 30 + months * 30;

    //Default value from db is year 0
    if (years > 1000) return localized('NoLastActiveTime');
    //If last active was today
    if (years === 0 && months === 0 && days === 0) return localized('LastActiveLowerCase') + ' ' + localized('Today');

    //Only show value if its larger than zero
    let message = localized('LastActiveLowerCase') + ' ';
    message += days > 0 ? days + ' ' + localized('Days') + ' ' : '';
    message += months > 0 ? months + ' ' + localized('Months') + ' ' : '';
    message += years > 0 ? years + ' ' + localized('Years') + ' ' : '';
    message += localized('Ago');
    return message;
};

import { Divider } from '@mui/material';
import { Stack } from '@mui/system';
import React, { FC } from 'react';
import { IDeviceSlimDTO } from '../../../../../api/api';
import { localized } from '../../../../../i18n/i18n';
import { DeviceDetailsLine } from '../device-details-line';

interface Props {
    deviceToBeTransfered: IDeviceSlimDTO;
}

export const DeviceDealerTransferFields: FC<Props> = ({ deviceToBeTransfered }) => {
    return (
        <Stack spacing={'8px'} width={'300px'} divider={<Divider />}>
            <DeviceDetailsLine text={localized('SerialNumber')} value={deviceToBeTransfered.serialNumber} />
            <DeviceDetailsLine text={localized('Dealer')} value={deviceToBeTransfered.dealerName} />
            <DeviceDetailsLine text={localized('Facility')} value={deviceToBeTransfered.facilityName} />
            <DeviceDetailsLine text={localized('Department')} value={deviceToBeTransfered.department} />
            <DeviceDetailsLine text={localized('Model')} value={deviceToBeTransfered.model} />
            <DeviceDetailsLine text={localized('ProdYear')} value={deviceToBeTransfered.productionYear} />
            <DeviceDetailsLine text={localized('ProdWeek')} value={deviceToBeTransfered.productionWeek} />
        </Stack>
    );
};

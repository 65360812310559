import { DevicePaginationState, FilterInfo } from './types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from '../../store';
import { getDeviceCount } from '../devices/operation';
import { Order } from '../../../view/components/etac-components/enhanced-table/enhanced-table';

const initialState: DevicePaginationState = {
    order: 'asc',
    orderBy: 'serialNumber',
    curFilters: [],
    searchSerial: undefined,
    currentPage: 0,
    pageSize: 25,
    count: 0,
};

export const deviceLazyLoadingSlice = createSlice({
    name: 'devicePagination',
    initialState,
    reducers: {
        resetLazyState: () => initialState,
        setPage: (state, action) => {
            state.currentPage = action.payload;
        },
        setPageSize: (state, action: PayloadAction<number>) => {
            state.pageSize = action.payload;
            state.currentPage = 0;
        },
        setSearchSerial: (state, action: PayloadAction<number | undefined>) => {
            state.searchSerial = action.payload;
            state.currentPage = 0;
        },
        setCurFilters: (state, action: PayloadAction<FilterInfo[]>) => {
            state.curFilters = action.payload;
            state.currentPage = 0;
        },
        setLazyOrdering: (state, action: PayloadAction<{ orderBy: keyof any; order: Order }>) => {
            state.orderBy = action.payload.orderBy;
            state.order = action.payload.order;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getDeviceCount.fulfilled, (state, action) => {
            state.count = action.payload;
        });
    },
});

export const selectCurrentPage = (store: AppState) => store.deviceLazyLoadingReducer.currentPage;
export const selectPageSize = (store: AppState) => store.deviceLazyLoadingReducer.pageSize;
export const selectCount = (store: AppState) => store.deviceLazyLoadingReducer.count;
export const selectOrder = (store: AppState) => store.deviceLazyLoadingReducer.order;
export const selectOrderBy = (store: AppState) => store.deviceLazyLoadingReducer.orderBy;
export const selectSearchSerial = (store: AppState) => store.deviceLazyLoadingReducer.searchSerial;
export const selectCurFilters = (store: AppState) => store.deviceLazyLoadingReducer.curFilters;

export const { resetLazyState, setPageSize, setPage, setSearchSerial, setCurFilters, setLazyOrdering } =
    deviceLazyLoadingSlice.actions;
export default deviceLazyLoadingSlice.reducer;

import { Checkbox, CheckboxProps, FormControl, FormControlLabel, FormHelperText } from '@mui/material';
import React, { FC, memo, ReactNode } from 'react';
import { Controller, Message, useFormContext, ValidationRule } from 'react-hook-form';
import { theme } from '../../../../styles/theme';

interface Props extends CheckboxProps {
    name: string;
    label?: string;
    disabled?: boolean;
    validators?: Partial<{
        required: Message | ValidationRule<boolean>;
        disabled: boolean;
    }>;
    icon?: ReactNode;
    checkedIcon?: ReactNode;
}

export const CheckboxInput: FC<Props> = memo((props) => {
    const {
        control,
        formState: { errors },
    } = useFormContext();
    return (
        <Controller
            name={props.name}
            control={control}
            rules={props.validators}
            defaultValue={props.defaultChecked}
            render={({ field }) => (
                <FormControl sx={{ marginTop: 1, marginBottom: 1, marginLeft: 0 }} disabled={props.disabled}>
                    <FormControlLabel
                        label={props.label ?? ''}
                        control={
                            <Checkbox
                                checked={field.value ?? false}
                                onChange={(e, checked) => {
                                    field.onChange(e.target.checked);
                                    props.onChange?.(e, checked);
                                }}
                                icon={props.icon}
                                checkedIcon={props.checkedIcon}
                            />
                        }
                    />
                    {!!errors[props.name] && (
                        <FormHelperText sx={{ color: theme.palette.error.main }}>
                            {errors[props.name].message}
                        </FormHelperText>
                    )}
                </FormControl>
            )}
        />
    );
});

// Inspired by https://mui.com/material-ui/react-table/#sorting-amp-selecting
import { Box, styled, SxProps, Table, TableCell, TableCellProps, TableContainer } from '@mui/material';
import TableRow, { TableRowProps } from '@mui/material/TableRow';
import React, { FC, useCallback } from 'react';
import { EnhancedTableHead } from './enhanced-table-head';
import { BasicSpinner } from '../../default-components/spinner/basic-spinner';

export interface HeadCell {
    id: keyof any;
    label: any;
    width?: string;
}

interface Props {
    headCells: HeadCell[];
    order: Order;
    setOrder?: (action: React.SetStateAction<Order>) => void;
    orderBy: keyof any;
    setOrderBy?: (action: React.SetStateAction<keyof any>) => void;
    showSpinner: boolean;
    handleRequestSort?: (e: React.MouseEvent<unknown>, property: keyof any) => void;
}

const tableStyles: { [key: string]: SxProps } = {
    table: {
        borderCollapse: 'initial',
        borderSpacing: '0px 10px',
    },
};

export const EnhancedTable: FC<Props> = React.memo((props) => {
    const { order, setOrder, orderBy, setOrderBy, handleRequestSort } = props;

    const handleRequestSortLocal = useCallback(
        (e: React.MouseEvent<unknown>, property: keyof any) => {
            if (!setOrder || !setOrderBy) return;

            const isAsc = orderBy === property && order === 'asc';
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(property);
        },
        [orderBy, order, setOrderBy, setOrder],
    );

    return (
        <Box sx={{ width: '100%' }}>
            <TableContainer sx={{ minHeight: 'calc(100vh - 161px)' }}>
                <Table sx={tableStyles.table} stickyHeader>
                    <EnhancedTableHead
                        onRequestSort={handleRequestSort ?? handleRequestSortLocal}
                        order={order}
                        orderBy={orderBy}
                        headCells={props.headCells}
                    />
                    {props.children}
                    {props.showSpinner ? <BasicSpinner /> : null}
                </Table>
            </TableContainer>
        </Box>
    );
});

export const EnhancedTableRow = styled(TableRow)<TableRowProps>(() => ({
    height: '39px',
    backgroundColor: 'white',
}));

export const EnhancedTableCell = styled(TableCell)<TableCellProps>(() => ({
    fontSize: '14px',
    border: 'none',
    paddingTop: '8px',
    paddingBottom: '8px',
}));

export type Order = 'asc' | 'desc';

export function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string | Date | undefined },
    b: { [key in Key]: number | string | Date | undefined },
) => number {
    return order === 'desc' ? (a, b) => comparator(a, b, orderBy, false) : (a, b) => comparator(a, b, orderBy, true);
}

function comparator<T>(a: T, b: T, orderBy: keyof T, ascending: boolean) {
    if (a[orderBy] === b[orderBy]) {
        return 0;
    }
    if (!a[orderBy]) {
        return 1;
    }
    if (!b[orderBy]) {
        return -1;
    }

    if (typeof a[orderBy] === 'string') {
        return (
            (ascending ? 1 : -1) *
            (a[orderBy] as unknown as string).localeCompare(b[orderBy] as unknown as string, ['da-DK', 'en-US'], {
                numeric: true,
                sensitivity: 'base',
            })
        );
    }
    if (ascending) {
        return a[orderBy] < b[orderBy] ? -1 : 1;
    }

    return a[orderBy] < b[orderBy] ? 1 : -1;
}

import React, { FC, useEffect } from 'react';
import { PageTitle } from '../../components/default-components/page-title/page-title';
import { localized } from '../../../i18n/i18n';
import { MainPageContainer } from '../../components/etac-components/main-page-container';
import { ServiceHistoryTable } from '../../components/etac-components/service-history/service-history-table';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getSnapshotsSlim } from '../../../state/features/devices/operation';
import { selectServiceHistory } from '../../../state/features/devices/devices-slice';
import { SubPageHeader } from '../../components/etac-components/sub-page-header';
import { CreateRemarkButton } from './create-remark/create-remark-button';

export const ServiceHistory: FC = React.memo(() => {
    const { serialNumber } = useParams();
    const dispatch = useDispatch();

    const serviceHistory = useSelector(selectServiceHistory);

    useEffect(() => {
        if (serialNumber === undefined) return;

        dispatch(getSnapshotsSlim(Number(serialNumber)));
    }, [serialNumber, dispatch]);

    return (
        <PageTitle title={localized('ServiceHistory')}>
            <SubPageHeader title={localized('ServiceHistory')} includeMetadata>
                <CreateRemarkButton />
            </SubPageHeader>
            <MainPageContainer>
                <ServiceHistoryTable serviceHistory={serviceHistory} />
            </MainPageContainer>
        </PageTitle>
    );
});

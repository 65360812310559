import React, { FC, useCallback } from 'react';
import { InputAdornment, OutlinedInput, OutlinedInputProps, SxProps } from '@mui/material';
import { localized } from '../../../i18n/i18n';
import SearchIcon from '@mui/icons-material/Search';

const textFieldStyle: SxProps = {
    backgroundColor: 'white',
};

interface Props extends OutlinedInputProps {
    searchText: string;
    setSearchText: (value: string) => void;
}

export const SimpleSearchbar: FC<Props> = React.memo(({ searchText, setSearchText }) => {
    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setSearchText(e.target.value);
        },
        [setSearchText],
    );

    return (
        <OutlinedInput
            sx={textFieldStyle}
            placeholder={localized('Search')}
            value={searchText}
            onChange={handleChange}
            endAdornment={
                <InputAdornment position="end">
                    <SearchIcon />
                </InputAdornment>
            }
        />
    );
});

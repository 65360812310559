// Theme colors
export const primaryColor = '#78BE20';
export const primaryTextColor = '#333333';

export const secondaryColor = '#E7E7E7';
export const secondaryTextColor = '#333333';

export const backgroundColor = '#F4F4F4';
export const validColor = '#31FF00';
export const invalidColor = '#f44336';
export const warningColor = '#FDB900';
export const whiteColor = '#FFFFFF';

export const backgroundColorPaper = '#E9E9E9';

export const headerColor = '#999999';

export const notificationRed = '#B40000';

export const iconBackgroundGrey = '#DDDDDD';

// Component colors

// Header
export const headerBackgroundColor = '#EEEEEE';

// Sidebar
export const sidebarBackgroundColor = '#F8F8F8';
export const sidebarIconColor = '#78BE20';
export const sidebarBackgroundSelectedColor = '#ECECEC';
export const sidebarSelectedBorder = '#B8B9C8';
export const sideBarProfileBackgroundColor = '#f5f4f4';

// Content Wrapper

// Basic Card
export const basicCardBgColor = '#FFFFFF';
export const basicCardBoxShadow = '0px 3px 6px #00000029';
// Button

// Input

// Toggle

// Switch

// Info item

// Textfield

// Checkbox Filter
export const checkboxFilterBg = '#FAFAFA';

// SearchInput
export const searchBgColor = '#E6E6E6';

// List Table

// Info item 2
export const infoItem2BackgroundColor = '#FAFAFA';

import {
    FacilityClient,
    DeviceClient,
    OrganizationClient,
    ProductionTestRunClient,
    UserClient,
    FileDownloadClient,
} from '../api/api';
import { ApiBaseUrl } from '../utilities/constants';

export const userClient = new UserClient(ApiBaseUrl);

export const organizationClient = new OrganizationClient(ApiBaseUrl);

export const deviceClient = new DeviceClient(ApiBaseUrl);

export const facilityClient = new FacilityClient(ApiBaseUrl);

export const productionTestRunClient = new ProductionTestRunClient(ApiBaseUrl);

export const fileDownloadClient = new FileDownloadClient(ApiBaseUrl);

export type ApiClient =
    | UserClient
    | OrganizationClient
    | DeviceClient
    | FacilityClient
    | ProductionTestRunClient
    | FileDownloadClient;

import { Box } from '@mui/system';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserByAuthId } from '../../../../state/features/users/operation';
import { selectCurrentUser } from '../../../../state/features/users/users-slice';
import { CurrentConsentVersion } from '../../../../utilities/constants';
import { ConsentCheckBox } from '../consent-checkbox/consent-checkbox';
import { BasicModal } from '../modal/basic-modal';

export const UserConsent: FC = React.memo(() => {
    const dispatch = useDispatch();

    const user = useSelector(selectCurrentUser);
    const [modalIsOpen, setModalIsOpen] = useState(true);

    useEffect(() => {
        dispatch(getUserByAuthId());
    }, [dispatch]);

    return CurrentConsentVersion > (user ? user.acceptedConsentVersion : Number.MAX_VALUE) ? (
        <Box>
            <BasicModal open={modalIsOpen}>
                <ConsentCheckBox setModalOpen={setModalIsOpen} />
            </BasicModal>
        </Box>
    ) : null;
});

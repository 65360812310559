import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from '../state/routes';
import { RedirectUriStorageKey } from '../utilities/constants';
import { useAuth } from './auth-provider';

export const Callback: FC = () => {
    const { isAuthenticated } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated) {
            let redirectUrl: string | null = window.sessionStorage.getItem(RedirectUriStorageKey);
            if (redirectUrl && redirectUrl !== routes.callback && redirectUrl !== routes.loggedOut) {
                window.sessionStorage.removeItem(RedirectUriStorageKey);

                navigate(redirectUrl);
            } else {
                navigate('/');
            }
        } else {
            // Something must have gone wrong after reading the hash - navigate to trigger a new login
            if (location.hash == '') {
                navigate('/');
            }
        }
    }, [isAuthenticated, navigate]);

    // This page is shown after login redirect and before the hash is processed
    return null;
};

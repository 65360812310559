import { createAsyncThunk } from '@reduxjs/toolkit';
import { AsyncOperationHandler } from '../../../utilities/platform-helpers/api-helper';
import { Organization, OrganizationClient } from '../../../api/api';
import { organizationClient } from '../../api-clients';

export const getOrganizations = createAsyncThunk('organizations/getOrganizations', () => {
    return AsyncOperationHandler((client) => (client as OrganizationClient).get(), organizationClient);
});

export const getOrganizationForDropdown = createAsyncThunk('organizations/getForDropdown', () => {
    return AsyncOperationHandler(
        (client) => (client as OrganizationClient).getByAuthIdForDealerDropdown(),
        organizationClient,
    );
});

export const saveOrganization = createAsyncThunk('organizations/saveOrganizations', (organization: Organization) => {
    return AsyncOperationHandler(
        (client) => (client as OrganizationClient).post(organization),
        organizationClient,
        true,
    );
});

export const updateOrganization = createAsyncThunk(
    'organizations/updateOrganizations',
    (organization: Organization) => {
        return AsyncOperationHandler(
            (client) => (client as OrganizationClient).put(organization),
            organizationClient,
            true,
        );
    },
);

export const deleteOrganization = createAsyncThunk(
    'organizations/deleteOrganizations',
    (organization: Organization) => {
        return AsyncOperationHandler(
            (client) => (client as OrganizationClient).delete(organization),
            organizationClient,
        );
    },
);

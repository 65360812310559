import React, { FC, useCallback } from 'react';
import { TableMaxWidth } from '../../../../styles/styling-constants';
import { TablePagination } from '@mui/material';

interface Props {
    page: number;
    setPage: (value: React.SetStateAction<number>) => void;
    rowsPerPage: number;
    setRowsPerPage: (value: React.SetStateAction<number>) => void;
    count: number;
}

export const EnhancedTablePagination: FC<Props> = React.memo((props) => {
    const { page, setPage, rowsPerPage, setRowsPerPage } = props;

    const handlePageChange = useCallback(
        (event: unknown, newPage: number) => {
            setPage(newPage);
        },
        [setPage],
    );

    const handleChangeRowsPerPage = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        },
        [setRowsPerPage, setPage],
    );

    return (
        <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={props.count}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
    );
});

import React, { FC, useCallback, useState } from 'react';
import { FlexRow } from '../../../components/default-components/flex-row';
import { RoundButton } from '../../../components/etac-components/round-button';
import { localized } from '../../../../i18n/i18n';
import { Dialog, DialogContent, DialogTitle, Stack, TextField } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { createWebRemarks } from '../../../../state/features/devices/operation';

export const CreateRemarkButton: FC = React.memo(() => {
    const { serialNumber } = useParams();
    const dispatch = useDispatch();

    const [remarkDialogOpen, setRemarkDialogOpen] = useState(false);
    const [remarkText, setRemarkText] = useState('');

    const openRemarkDialog = useCallback(() => {
        setRemarkDialogOpen(true);
    }, []);

    const closeRemarkDialog = useCallback(() => {
        setRemarkDialogOpen(false);
        setRemarkText('');
    }, []);

    const saveRemark = useCallback(() => {
        if (serialNumber === undefined || remarkText.trim().length === 0) return;

        dispatch(createWebRemarks({ serialNumber: Number(serialNumber), remarkText }));
        setRemarkText('');
        setRemarkDialogOpen(false);
    }, [serialNumber, dispatch, remarkText]);

    const onRemarkChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setRemarkText(event.currentTarget.value);
    }, []);

    return (
        <>
            <FlexRow marginLeft={'auto'} alignItems={'center'}>
                <RoundButton slim onClick={openRemarkDialog}>
                    {localized('CreateRemark')}
                </RoundButton>
            </FlexRow>

            <Dialog open={remarkDialogOpen} onClose={closeRemarkDialog} PaperProps={{ sx: { width: '400px' } }}>
                <DialogTitle> {localized('CreateRemark')}</DialogTitle>
                <DialogContent>
                    <TextField multiline rows={4} fullWidth onChange={onRemarkChange} value={remarkText} />
                </DialogContent>
                <Stack spacing={1} direction={'row'} justifyContent={'center'} paddingBottom={'20px'}>
                    <RoundButton slim color={'secondary'} onClick={closeRemarkDialog}>
                        {localized('Cancel')}
                    </RoundButton>
                    <RoundButton slim onClick={saveRemark}>
                        {localized('Save')}
                    </RoundButton>
                </Stack>
            </Dialog>
        </>
    );
});

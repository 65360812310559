import { Drawer } from '@mui/material';
import React, { FC, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { localized } from '../../../../i18n/i18n';
import {
    closeEditUserDrawer,
    selectForSimulation,
    selectUserDrawerOpen,
    selectUserForEdit,
} from '../../../../state/features/user-drawer/user-drawer-slice';
import { FlexColumn } from '../../default-components/flex-column';
import { PageHeader } from '../../default-components/page-header.tsx/page-header';
import { UserFormEtac } from './user-form-etac';
import { DeleteButtonWithConfirm } from '../delete-button-with-confirm';
import { deleteFromOrganization } from '../../../../state/features/users/operation';
import { User } from '../../../../api/api';

export const CreateUserDrawer: FC = React.memo(() => {
    const dispatch = useDispatch();
    const isOpen = useSelector(selectUserDrawerOpen);
    const user = useSelector(selectUserForEdit);
    const simulateUser = useSelector(selectForSimulation);

    const userDrawerType = useMemo(() => {
        switch (true) {
            case user === undefined:
                return UserDrawerType.Create;
            case user !== undefined && simulateUser:
                return UserDrawerType.Simulate;
            case user !== undefined:
                return UserDrawerType.Edit;
            default:
                throw new Error('User type could not be determined');
        }
    }, [user]);

    const userDrawerTitle = useMemo(() => {
        return userDrawerTypeTitleMapper[userDrawerType];
    }, [userDrawerType]);

    const onClose = useCallback(() => {
        dispatch(closeEditUserDrawer());
    }, [dispatch]);

    const handleDelete = useCallback(() => {
        if (user === undefined) return;

        const localUser = new User();
        localUser.init(user);

        dispatch(deleteFromOrganization({ user: localUser, organizationId: user.organizationId }));
    }, [dispatch, user]);

    return (
        <Drawer anchor="right" open={isOpen} onClose={onClose}>
            <PageHeader area="userCreate" title={userDrawerTitle} />
            <FlexColumn padding={'25px 50px 25px 50px'} width={'440px'} height={'100%'}>
                <UserFormEtac existingUser={user} onClose={onClose} userDrawerType={userDrawerType} />
                {userDrawerTypesWithDeleteButtons.includes(userDrawerType) && (
                    <DeleteButtonWithConfirm name={localized('User')} handleDeleteManual={handleDelete} />
                )}
            </FlexColumn>
        </Drawer>
    );
});

export enum UserDrawerType {
    Create,
    Edit,
    Simulate,
}

const userDrawerTypeTitleMapper: Record<UserDrawerType, string> = {
    [UserDrawerType.Create]: localized('NewUser'),
    [UserDrawerType.Edit]: localized('EditUser'),
    [UserDrawerType.Simulate]: localized('SimulateUser'),
};

const userDrawerTypesWithDeleteButtons = [UserDrawerType.Edit];

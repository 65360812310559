import { TableBody } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { localized } from '../../../../i18n/i18n';
import { selectDeviceErrors } from '../../../../state/features/devices/devices-slice';
import { BasicHeadCell, BasicTable } from '../basic-table/basic-table';
import { DeviceErrorTableRow } from './device-error-table-row';

const headCells: BasicHeadCell[] = [
    { id: 'Register', label: localized('Register'), width: '170px' },
    { id: 'Request', label: localized('Request') },
    { id: 'Response', label: localized('Response') },
    { id: 'ExpectedValue', label: localized('ExpectedValue') },
    { id: 'ActualValue', label: localized('ActualValue') },
];

export const DeviceErrorTable = React.memo(() => {
    const deviceErrors = useSelector(selectDeviceErrors);
    return (
        <BasicTable headCells={headCells} fullWidth>
            <TableBody>
                {deviceErrors.map((error, i) => (
                    <DeviceErrorTableRow key={i} error={error} />
                ))}
            </TableBody>
        </BasicTable>
    );
});

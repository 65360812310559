import { Autocomplete, FilterOptionsState, TextField } from '@mui/material';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { localized } from '../../../../i18n/i18n';
import {
    selectCurFilters,
    setCurFilters,
} from '../../../../state/features/device-pagination/device-lazy-loading-slice';
import { FilterInfo } from '../../../../state/features/device-pagination/types';
import { selectAllFacilities } from '../../../../state/features/facilities/facilities-slice';
import { getAllFacilities } from '../../../../state/features/facilities/operation';
import { selectOrganizationsForDropdown } from '../../../../state/features/organization/organization-slice';
import { selectAllUsers } from '../../../../state/features/users/users-slice';
import { SortByProperty } from '../../../../utilities/platform-helpers/sorting-helper';
import { FilterOptions } from './device-filter-selector';
import { getOrganizationForDropdown } from '../../../../state/features/organization/operation';
import { selectDeviceModelNames } from '../../../../state/features/devices/devices-slice';
import { getDeviceModels } from '../../../../state/features/devices/operation';

interface Props {
    curFilterOption: FilterOptions | undefined;
}

export const DynamicFilter: FC<Props> = React.memo((props) => {
    const users = useSelector(selectAllUsers);
    const dealers = useSelector(selectOrganizationsForDropdown);
    const facilities = useSelector(selectAllFacilities);
    const deviceModels = useSelector(selectDeviceModelNames);
    const dispatch = useDispatch();
    const curFilters = useSelector(selectCurFilters);
    const [department, setDepartment] = useState<string>();

    const usersSorted = useMemo(() => {
        let sortedUsers = [...users];
        const usersWithAName = sortedUsers.filter((u) => u.name);
        return usersWithAName.sort((a, b) => SortByProperty(a.name, b.name));
    }, [users]);

    const dealersSorted = useMemo(() => {
        let sortedDealers = [...dealers];
        const dealersWithAName = sortedDealers.filter((o) => o.name);
        return dealersWithAName.sort((a, b) => SortByProperty(a.name, b.name));
    }, [dealers]);

    const facilitiesSorted = useMemo(() => {
        let sortedFacilities = [...facilities];
        const facilitiesWithAName = sortedFacilities.filter((f) => f.name);
        return facilitiesWithAName.sort((a, b) => SortByProperty(a.name, b.name));
    }, [facilities]);

    const deviceModelsSorted = useMemo(() => {
        return deviceModels
            .slice()
            .filter((n) => n != null)
            .sort((a, b) => SortByProperty(a, b));
    }, [deviceModels]);

    const autocompleteRender = useCallback(
        (params) => <TextField {...params} label={localized('FilterOptions')} />,
        [],
    );

    const autoCompleteStyles = useMemo(() => {
        //95% to make space for the cross/arrow icon
        let maxWidthPerItem = 95 / curFilters.length;
        return {
            backgroundColor: 'white',
            minWidth: 250,
            '& .MuiAutocomplete-inputRoot': { flexWrap: 'nowrap!important' },
            '& .MuiAutocomplete-tag': { maxWidth: maxWidthPerItem.toString().concat('%!important') },
        };
    }, [curFilters.length]);

    useEffect(() => {
        dispatch(getAllFacilities(true));
        dispatch(getOrganizationForDropdown());
        dispatch(getDeviceModels());
    }, []);

    const autoCompleteRenderOption = useCallback((props, option) => {
        return (
            <li {...props} key={option?.id}>
                {option?.label}
            </li>
        );
    }, []);

    const filterOptions = useMemo(() => {
        if (!props.curFilterOption) return [];
        //@ts-ignore
        switch (FilterOptions[props.curFilterOption] as FilterOptions) {
            case FilterOptions.User:
                return usersSorted.map((user) => ({
                    label: user.name ?? '',
                    id: user.id,
                    category: props.curFilterOption!,
                }));
            case FilterOptions.Dealer:
                return dealersSorted.map((dealer) => ({
                    label: dealer.name ?? '',
                    id: dealer.id,
                    category: props.curFilterOption!,
                }));
            case FilterOptions.Facility:
                return facilitiesSorted.map((facility) => ({
                    label: facility.name ?? '',
                    id: facility.id,
                    category: props.curFilterOption!,
                }));
            case FilterOptions.Department:
                return [{ label: department ?? '', id: 0, category: props.curFilterOption! }];
            case FilterOptions.DeviceModel:
                return deviceModelsSorted.map((modelName) => ({
                    label: modelName,
                    id: modelName,
                    category: props.curFilterOption!,
                }));
            default:
                return [];
        }
    }, [props.curFilterOption, department, usersSorted, dealersSorted, facilitiesSorted, deviceModelsSorted]);

    const handleCurFilterChanged = useCallback(
        (event: React.SyntheticEvent<Element, Event>, value: FilterInfo[]) => {
            if (!value) return;
            const intersection = value.filter((v) => v.category === props.curFilterOption);

            if (intersection.length <= 1) {
                dispatch(setCurFilters(value));
                return;
            }
            dispatch(setCurFilters(value.filter((f) => f !== intersection[0])));
        },
        [curFilters, props.curFilterOption],
    );
    //As our department is just a string, we manually set this value, and return the options, filtered by label.
    const handleDepartmentFilterChange = useCallback(
        (options: FilterInfo[], state: FilterOptionsState<any>) => {
            const input = state.inputValue;
            if (props.curFilterOption === FilterOptions.Department) {
                setDepartment(input);
            }
            return options.filter((o) => o.label.toLowerCase().includes(input.toLowerCase()));
        },
        [props.curFilterOption],
    );

    return (
        <Autocomplete
            sx={autoCompleteStyles}
            value={curFilters}
            options={filterOptions}
            renderOption={autoCompleteRenderOption}
            renderInput={autocompleteRender}
            multiple
            autoHighlight
            onChange={handleCurFilterChanged}
            filterOptions={handleDepartmentFilterChange}
        />
    );
});

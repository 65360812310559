import { Box, Divider, Stack, styled } from '@mui/material';
import React, { FC, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IUserDto, OrganizationRole, OrganizationUser, User, UserRole } from '../../../../api/api';
import { useAuth } from '../../../../auth/auth-provider';
import { localized, localizedInterpolation } from '../../../../i18n/i18n';
import {
    createSimulation,
    resendInvitationEmail,
    saveUser,
    updateUser,
} from '../../../../state/features/users/operation';
import { AppState } from '../../../../state/store';
import { Form } from '../../default-components/form/form';
import { RoundButton } from '../round-button';
import { UserFormFieldsEtac } from './user-form-fields-etac';
import { selectFacilities } from '../../../../state/features/facilities/facilities-slice';
import { UserDrawerType } from './create-user-drawer';

interface PropsFromParent {
    onClose: () => void;
    existingUser?: IUserDto;
    userDrawerType: UserDrawerType;
}

export const UserFormEtac: FC<PropsFromParent> = React.memo(({ onClose, existingUser, userDrawerType }) => {
    const dispatch = useDispatch();
    const { isGlobalAdmin } = useAuth();
    const savePending = useSelector((store: AppState) => store.usersReducer.pending);
    const facilities = useSelector(selectFacilities);

    const save = useCallback(
        (data: any) => {
            const orgUser = new OrganizationUser();
            orgUser.init({
                id: data.organizationUserId,
                organizationId: data.organizationId,
                organizationRole: data.organizationRole as OrganizationRole,
                serviceToolRole: data.serviceToolRole,
            } as OrganizationUser);

            const user = new User();
            user.init({
                ...data,
                role: data.role ? UserRole.GlobalAdmin : UserRole.User,
                organizationUser: [orgUser],
                dealerOnly: isGlobalAdmin ? data.dealerOnly : true,
                allowedFacilities: data.allowedFacilityIds
                    ? facilities.filter((f) => data.allowedFacilityIds.includes(f.id))
                    : [],
            });

            switch (userDrawerType) {
                case UserDrawerType.Create:
                    dispatch(saveUser(user));
                    break;
                case UserDrawerType.Edit:
                    dispatch(updateUser(user));
                    break;
                case UserDrawerType.Simulate:
                    dispatch(createSimulation(user));
                    break;
            }
        },
        [isGlobalAdmin, facilities, dispatch, userDrawerType],
    );

    const resendInvitation = useCallback(() => {
        if (!existingUser?.email) return;

        dispatch(resendInvitationEmail(existingUser.email));
    }, [existingUser?.email, dispatch]);

    // Mapping the user object to form values as organization role value is in a nested object
    const defaultValues = useMemo(() => {
        if (!existingUser) return;

        return {
            ...existingUser,
            role: existingUser.role === UserRole.GlobalAdmin,
        };
    }, [existingUser]);

    return (
        <Form onSubmit={save} defaultValues={defaultValues}>
            <UserFormFieldsEtac existingUser={existingUser} />
            <Stack spacing={1} direction={'row'} marginTop={'10px'} justifyContent={'center'}>
                <RoundButton slim color={'secondary'} onClick={onClose} width={'160px'}>
                    {localized('Cancel')}
                </RoundButton>
                <RoundButton type="submit" slim disabled={savePending} width={'160px'}>
                    {localizedInterpolation(existingUser !== undefined ? 'SaveInt' : 'CreateNewInt', {
                        area: localized('User'),
                    })}
                </RoundButton>
            </Stack>
            {existingUser && !existingUser.authId && (
                <Box
                    sx={{
                        marginTop: 2,
                        marginBottom: 2,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                    <RoundButton slim color={'primary'} onClick={resendInvitation} width={'auto'}>
                        {localized('ResendInvitationEmail')}
                    </RoundButton>
                </Box>
            )}
        </Form>
    );
});

export const DividerWithMargin = styled(Divider)(() => ({
    marginBottom: '25px',
    marginTop: '10px',
}));

import React, { FC, useEffect } from 'react';
import { PageTitle } from '../../components/default-components/page-title/page-title';
import { localized } from '../../../i18n/i18n';
import { SubPageHeader } from '../../components/etac-components/sub-page-header';
import { MainPageContainer } from '../../components/etac-components/main-page-container';
import { FlexColumn } from '../../components/default-components/flex-column';
import { ProductionTestTable } from '../../components/etac-components/production-tests/production-test-table';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getProductionTestRuns } from '../../../state/features/production-test/operation';
import { ProductionTestGraph } from '../../components/etac-components/production-tests/production-test-graph';
import { FlexRow } from '../../components/default-components/flex-row';

export const ProductionTests: FC = React.memo(() => {
    const { serialNumber } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getProductionTestRuns(Number(serialNumber)));
    }, [serialNumber, dispatch]);

    return (
        <PageTitle title={localized('ProductionTests')}>
            <SubPageHeader title={localized('ProductionTests')} includeMetadata />
            <MainPageContainer>
                <FlexRow height={'100%'}>
                    <FlexColumn width={'50%'} marginRight={'30px'}>
                        <ProductionTestTable />
                    </FlexColumn>
                    <FlexColumn width={'50%'}>
                        <ProductionTestGraph />
                    </FlexColumn>
                </FlexRow>
            </MainPageContainer>
        </PageTitle>
    );
});

import { Dialog, DialogTitle, IconButton, OutlinedInput, Stack, Typography } from '@mui/material';
import React, { FC, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { localized } from '../../../../../i18n/i18n';
import { selectDeviceToBeTransfered } from '../../../../../state/features/devices/devices-slice';
import { tryGetSlimDevice } from '../../../../../state/features/devices/operation';
import { AppState } from '../../../../../state/store';
import { FlexColumn } from '../../../default-components/flex-column';
import { BasicSpinner } from '../../../default-components/spinner/basic-spinner';
import { RoundButton } from '../../round-button';
import { deviceDetailsStyles } from '../device-details-dialog';
import { DeviceDealerTransferFields } from './device-dealer-transfer-fields';
import { DeviceDealerTransferSelect } from './device-dealer-transfer-select';

interface Props {
    open: boolean;
    onClose: () => void;
}

export const DeviceDealerTransferDialog: FC<Props> = ({ open, onClose }) => {
    const [inputSerial, setInputSerial] = useState<number>();

    const getDevicePending = useSelector((store: AppState) => store.devicesReducer.pending);

    const deviceToBeTransfered = useSelector(selectDeviceToBeTransfered);
    const dispatch = useDispatch();

    const handleNewSerial = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            var parsedNumber = Number.parseInt(e.target.value);

            if (isNaN(parsedNumber)) {
                setInputSerial(undefined);
                return;
            }

            setInputSerial(parsedNumber);
        },
        [dispatch, inputSerial, setInputSerial],
    );

    const handleGetDeviceToChange = useCallback(() => {
        if (!inputSerial) return;

        dispatch(tryGetSlimDevice(inputSerial));
    }, [dispatch, inputSerial]);

    const handleEnterKeyPressed = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            if (e.code === 'Enter') {
                handleGetDeviceToChange();
                e.preventDefault();
            }
        },
        [handleGetDeviceToChange],
    );

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{ sx: { padding: '20px', maxWidth: '2000px', minWidth: 800 } }}>
            <DialogTitle sx={{ fontSize: '24px', marginTop: '-12px', justifyContent: 'center', display: 'flex' }}>
                {localized('TransferHoistOwnership')}
                <IconButton disableRipple sx={deviceDetailsStyles.closeButton} onClick={onClose}>
                    X
                </IconButton>
            </DialogTitle>
            <FlexColumn sx={{ alignItems: 'center', justifyContent: 'flex-start' }}>
                <Typography>{localized('SerialNumber')}</Typography>
                <OutlinedInput
                    type="number"
                    placeholder={localized('Search')}
                    value={inputSerial}
                    onChange={handleNewSerial}
                    onKeyDown={handleEnterKeyPressed}
                />
                {getDevicePending ? (
                    <FlexColumn sx={{ marginY: 2 }}>
                        <BasicSpinner />
                    </FlexColumn>
                ) : (
                    <RoundButton slim sx={{ marginY: 2 }} onClick={handleGetDeviceToChange} disabled={!inputSerial}>
                        {localized('GetDevice')}
                    </RoundButton>
                )}
            </FlexColumn>
            {deviceToBeTransfered ? (
                <Stack direction={'row'} spacing={'40px'} marginBottom={'20px'} justifyContent={'space-evenly'}>
                    <DeviceDealerTransferFields deviceToBeTransfered={deviceToBeTransfered} />
                    <DeviceDealerTransferSelect
                        closeDialog={onClose}
                        deviceSerialNumber={deviceToBeTransfered.serialNumber}
                    />
                </Stack>
            ) : null}
        </Dialog>
    );
};

import {Box, SxProps, Theme} from '@mui/material';
import React, {FC, memo, useEffect, useMemo, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';

interface Props {
  defaultValues?: any;
  onSubmit: any;
  sx?: SxProps<Theme>;
}

export const Form: FC<Props> = memo((props) => {
  const [resetValues, setResetValues] = useState<boolean>(true);
  const methods = useForm({
    defaultValues: useMemo(() => {
      return props.defaultValues;
    }, [props]),
  });
  useEffect(() => {
    if (resetValues && props.defaultValues) {
      methods.reset(props.defaultValues);
      setResetValues(false);
    }
  }, [props, methods, resetValues]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(props.onSubmit)}>
        <Box sx={{...props.sx, display: 'flex', flexDirection: 'column'}}>{props.children}</Box>
      </form>
    </FormProvider>
  );
});

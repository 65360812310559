import { createSelector, createSlice } from '@reduxjs/toolkit';
import { DropdownOption } from '../../../view/components/default-components/baisc-dropdown/basic-search-dropdown';
import { AppState } from '../../store';
import {
    deleteOrganization,
    getOrganizationForDropdown,
    getOrganizations,
    saveOrganization,
    updateOrganization,
} from './operation';
import { OrganizationState } from './types';

const initialState: OrganizationState = {
    organizations: [] as any,
    organizationsForList: [],
    pending: false,
    isSuccess: false,
};

export const organizationsSlice = createSlice({
    name: 'organizations',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Get
        builder.addCase(getOrganizations.fulfilled, (state, action) => {
            state.pending = false;
            state.organizations = action.payload;
        });

        builder.addCase(getOrganizations.pending, (state) => {
            state.pending = true;
        });

        builder.addCase(getOrganizations.rejected, (state) => {
            state.pending = false;
        });

        // GetForDropdown
        builder.addCase(getOrganizationForDropdown.fulfilled, (state, action) => {
            state.pending = false;
            state.organizationsForList = action.payload;
        });

        builder.addCase(getOrganizationForDropdown.pending, (state) => {
            state.pending = true;
        });

        builder.addCase(getOrganizationForDropdown.rejected, (state) => {
            state.pending = false;
        });

        // Save
        builder.addCase(saveOrganization.fulfilled, (state, action) => {
            state.pending = false;
            state.isSuccess = true;
            state.organizations.push(action.payload);
        });

        builder.addCase(saveOrganization.pending, (state) => {
            state.pending = true;
            state.isSuccess = false;
        });

        builder.addCase(saveOrganization.rejected, (state) => {
            state.pending = false;
            state.isSuccess = false;
        });

        // Update
        builder.addCase(updateOrganization.fulfilled, (state, action) => {
            state.pending = true;
            state.isSuccess = true;
            const index = state.organizations.findIndex((org) => org.id === action.payload.id);
            if (index > -1) {
                state.organizations[index] = action.payload;
            }
        });

        builder.addCase(updateOrganization.pending, (state) => {
            state.pending = true;
            state.isSuccess = false;
        });

        builder.addCase(updateOrganization.rejected, (state) => {
            state.pending = false;
            state.isSuccess = false;
        });

        // Delete
        builder.addCase(deleteOrganization.fulfilled, (state, action) => {
            state.pending = false;
            const index = state.organizations.findIndex((org) => org.id === action.meta.arg.id);
            if (index > -1) {
                state.organizations.splice(index, 1);
            }
        });

        builder.addCase(deleteOrganization.pending, (state) => {
            state.pending = true;
        });

        builder.addCase(deleteOrganization.rejected, (state) => {
            state.pending = false;
        });
    },
});

export const selectOrganizations = (store: AppState) => store.organizationReducer.organizations;
export const selectOrganizationsForDropdown = (store: AppState) => store.organizationReducer.organizationsForList;

export const selectOrganizationById = (id: number | undefined) =>
    createSelector(selectOrganizations, (organizations) => {
        return organizations.find((org) => org.id === id);
    });

export const selectOrganizationDropdownOptions = createSelector(selectOrganizations, (organizations) => {
    return organizations.flatMap((org) => (org.name ? new DropdownOption(org.name!, org.id) : []));
});

// eslint-disable-next-line
export const {} = organizationsSlice.actions;

export default organizationsSlice.reducer;

import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Organization } from '../../../api/api';
import { DropdownOption } from '../../../view/components/default-components/baisc-dropdown/basic-search-dropdown';
import { AppState } from '../../store';
import { deleteOrganization, getOrganizations } from '../organization/operation';
import { SettingsState } from './types';
import { deleteFromOrganization } from '../users/operation';

const initialState: SettingsState = {
    selectedOrganization: undefined,
};
export const settingsSlice = createSlice({
    name: 'settingSlice',
    initialState,
    reducers: {
        setOrganizationSetting: (state, action: PayloadAction<Organization>) => {
            state.selectedOrganization = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getOrganizations.fulfilled, (state, action) => {
            if (!action.payload.some((organization) => organization.id === state.selectedOrganization?.id)) {
                state.selectedOrganization = undefined;
            }
        });
        builder.addCase(deleteOrganization.fulfilled, (state, action) => {
            if (state.selectedOrganization?.id === action.meta.arg.id) {
                state.selectedOrganization = undefined;
            }
        });

        builder.addCase(deleteFromOrganization.fulfilled, (state, action) => {
            if (
                action.meta.arg.user.organizationUser?.some(
                    (orgUser) => orgUser.organizationId === state.selectedOrganization?.id,
                )
            ) {
                state.selectedOrganization = undefined;
            }
        });
    },
});

export const selectOrganization = (store: AppState) => store.settingsReducer.selectedOrganization;

export const selectOrganizationSettingDropdown = createSelector(selectOrganization, (organization) => {
    return new DropdownOption(organization?.name!, organization?.id);
});

// eslint-disable-next-line no-empty-pattern
export const { setOrganizationSetting } = settingsSlice.actions;
export default settingsSlice.reducer;

import React, { FC, useCallback, useEffect } from 'react';
import { PageTitle } from '../../components/default-components/page-title/page-title';
import { localized } from '../../../i18n/i18n';
import { MainPageContainer } from '../../components/etac-components/main-page-container';
import { useParams } from 'react-router-dom';
import { SubPageHeader } from '../../components/etac-components/sub-page-header';
import { FacilityTable } from '../../components/etac-components/facilities/facility-table';
import { useDispatch } from 'react-redux';
import { getFacilities } from '../../../state/features/facilities/operation';
import { Typography } from '@mui/material';
import { RoundButton } from '../../components/etac-components/round-button';
import { openFacilityDrawer } from '../../../state/features/facility-drawer/facility-drawer-slice';
import { FacilityDrawer } from '../../components/etac-components/facilities/facility-drawer';

export const Facilities: FC = React.memo(() => {
    const { dealerId } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getFacilities(Number(dealerId)));
    }, [dealerId]);

    const openDrawer = useCallback(() => {
        dispatch(openFacilityDrawer());
    }, [dispatch]);

    return (
        <PageTitle title={localized('Facilities')}>
            <SubPageHeader title={localized('Facilities')} includeMetadata={false}>
                <RoundButton slim sx={{ marginLeft: 'auto' }} width={'178px'} onClick={openDrawer}>
                    <Typography>{localized('NewFacility')}</Typography>
                </RoundButton>
            </SubPageHeader>
            <MainPageContainer>
                <FacilityTable />
            </MainPageContainer>
            <FacilityDrawer />
        </PageTitle>
    );
});

export const VirtualizedContentBoxHeight = 'calc(100vh - 145px) !important';
export const VirtualizedContentBoxHeightmobile = 'calc(100vh - 145px - 2 * 56px) !important';
export const VirtualizedListTableBoxHeight = 'calc(100vh - 225px) !important';
export const VirtualizedListTableBoxHeightMobile = 'calc(100vh - 225px - 2 * 56px) !important';

export const ListItemWidth = 'calc(100% - 17px)';
export const ListItemHeaderWidth = 'calc(100% - 34px)';

export const NoMarginPageWrapper = {
    marginTop: -24,
};

export const LeftBorderRadius = { borderRadius: '5px 0 0 5px' };
export const RightBorderRadius = { borderRadius: '0 5px 5px 0' };
export const TableMaxWidth = { maxWidth: '1650px' };

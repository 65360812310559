export const routes = {
    userManagement: '/user-management',
    userManagementCreate: '/user-management/create',
    userManagementEdit: '/user-management/:userId/edit',

    organization: '/organization',
    organizationCreate: '/organization/create',
    organizationEdit: '/organization/:organizationId/edit',

    template: '/template',

    callback: '/callback',
    loggedOut: '/logged-out',

    noPermission: '/no-permission',

    userAdministration: '/user-administration',

    facilities: '/facilities',

    dealers: '/dealers',

    devices: '/devices',
    devicesServiceHistory: '/devices/service-history',
    devicesServiceHistoryWithSerial: '/devices/service-history/:serialNumber',

    devicesProductionTests: '/devices/production-tests',

    devicesSnapshotLog: '/devices/snapshot-log',
    devicesSnapshotLogWithSerial: '/devices/snapshot-log/:serialNumber',

    deviceErrors: '/devices/errors',
};

export const defaultRoute = routes.template;

import { ProductionTestsState } from './types';
import { createSlice } from '@reduxjs/toolkit';
import { getProductionTestRuns, toggleDisplayProductionTest } from './operation';
import { AppState } from '../../store';

const initialState: ProductionTestsState = {
    productionTestRuns: [],
    displayedProductionTestRuns: [],
    pending: false,
};

export const productionTestSlice = createSlice({
    name: 'productionTests',
    initialState,
    reducers: {
        clearDisplayedProductionTestRuns: (state) => {
            state.displayedProductionTestRuns = [];
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getProductionTestRuns.fulfilled, (state, action) => {
            state.pending = false;
            state.productionTestRuns = action.payload;
            state.productionTestRuns.forEach((testRun) =>
                testRun.productionTestDatas?.forEach((data) => (data.msSinceStart /= 1000)),
            );
        });

        builder.addCase(getProductionTestRuns.pending, (state) => {
            state.pending = true;
        });

        builder.addCase(getProductionTestRuns.rejected, (state) => {
            state.pending = false;
        });

        builder.addCase(toggleDisplayProductionTest, (state, action) => {
            const productionTestRun = action.payload;
            const index = state.displayedProductionTestRuns.findIndex((testRun) => testRun.id === productionTestRun.id);

            if (index === -1) {
                state.displayedProductionTestRuns.push(productionTestRun);
            } else {
                state.displayedProductionTestRuns.splice(index, 1);
            }
        });
    },
});

export const selectProductionTests = (store: AppState) => store.productionTestReducer.productionTestRuns;
export const selectDisplayedProductionTestRuns = (store: AppState) =>
    store.productionTestReducer.displayedProductionTestRuns;

export const { clearDisplayedProductionTestRuns } = productionTestSlice.actions;

export default productionTestSlice.reducer;

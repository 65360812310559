import React, { FC, useCallback } from 'react';
import { IFacility } from '../../../../api/api';
import { EnhancedTableCell, EnhancedTableRow } from '../enhanced-table/enhanced-table';
import { IconButton } from '@mui/material';
import { primaryTextColor } from '../../../../styles/color-constants';
import { ReactComponent as GearIcon } from '../../../../assets/icons/gear-icon.svg';
import { useDispatch } from 'react-redux';
import { openFacilityDrawerForEdit } from '../../../../state/features/facility-drawer/facility-drawer-slice';
import { LeftBorderRadius, RightBorderRadius } from '../../../../styles/styling-constants';

interface Props {
    facility: IFacility;
}

export const FacilityTableRow: FC<Props> = React.memo(({ facility }) => {
    const dispatch = useDispatch();

    const openDrawer = useCallback(
        (facility: IFacility) => () => {
            dispatch(openFacilityDrawerForEdit(facility));
        },
        [dispatch],
    );

    return (
        <EnhancedTableRow key={facility.id}>
            <EnhancedTableCell sx={LeftBorderRadius}>{facility.name}</EnhancedTableCell>
            <EnhancedTableCell>{facility.address}</EnhancedTableCell>
            <EnhancedTableCell>{facility.postalCode}</EnhancedTableCell>
            <EnhancedTableCell>{facility.city}</EnhancedTableCell>
            <EnhancedTableCell>{facility.district}</EnhancedTableCell>
            <EnhancedTableCell>{facility.country}</EnhancedTableCell>
            <EnhancedTableCell>{facility.contact}</EnhancedTableCell>
            <EnhancedTableCell>{facility.phone}</EnhancedTableCell>
            <EnhancedTableCell>{facility.email}</EnhancedTableCell>
            <EnhancedTableCell sx={RightBorderRadius}>
                <IconButton sx={{ padding: 0, color: primaryTextColor }} onClick={openDrawer(facility)}>
                    <GearIcon />
                </IconButton>
            </EnhancedTableCell>
        </EnhancedTableRow>
    );
});

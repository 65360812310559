import React, { FC, useEffect, useMemo } from 'react';
import { TextInput } from '../../default-components/form/text-input';
import { nameof } from '../../../../utilities/platform-helpers/nameof-helper';
import { IUserDto, OrganizationUser, ServiceToolRole, User, UserDto } from '../../../../api/api';
import { localized } from '../../../../i18n/i18n';
import { requiredValidator } from '../../../../utilities/platform-helpers/validator-helper';
import { SelectInput } from '../../default-components/form/select-input';
import { EtacCheckbox } from '../etac-checkbox/etac-checkbox';
import { FlexRow } from '../../default-components/flex-row';
import { DividerWithMargin } from './user-form-etac';
import { useAuth } from '../../../../auth/auth-provider';
import {
    getOrganizationRoleDropdownOptions,
    getServiceToolRoleDropdownOptions,
} from '../../../../utilities/platform-helpers/enum-helper';
import { SortByProperty } from '../../../../utilities/platform-helpers/sorting-helper';
import { useDispatch, useSelector } from 'react-redux';
import { selectOrganizationDropdownOptions } from '../../../../state/features/organization/organization-slice';
import { selectFacilityDropdownOptions } from '../../../../state/features/facilities/facilities-slice';
import { useFormContext } from 'react-hook-form';
import { getFacilities } from '../../../../state/features/facilities/operation';

interface Props {
    existingUser?: IUserDto;
}

export const UserFormFieldsEtac: FC<Props> = React.memo(({ existingUser }) => {
    const { watch } = useFormContext();
    const organizationWatch = watch(nameof<OrganizationUser>('organizationId'));
    const { isGlobalAdmin } = useAuth();
    const dispatch = useDispatch();

    const organizationOptions = useSelector(selectOrganizationDropdownOptions);
    const facilityOptions = useSelector(selectFacilityDropdownOptions);

    const organizationRoleOptions = useMemo(() => getOrganizationRoleDropdownOptions(), []);
    const serviceToolRoleDropdownOptions = useMemo(
        //Filter Power role from options, if you are not GlobalAdmin and you either arent updating a user, or the one you are updating does not have the power role.
        () => {
            let serviceToolOptions = getServiceToolRoleDropdownOptions();
            if (!isGlobalAdmin && (!existingUser || existingUser.serviceToolRole !== ServiceToolRole.Power))
                serviceToolOptions = serviceToolOptions.filter((r) => r.value > ServiceToolRole.Power);
            return serviceToolOptions;
        },
        [existingUser, isGlobalAdmin],
    );

    const sortedOrganizationOptions = useMemo(() => {
        return organizationOptions.sort((a, b) => SortByProperty(a.label, b.label));
    }, [organizationOptions]);

    const sortedFacilityOptions = useMemo(
        () => facilityOptions.sort((a, b) => SortByProperty(a.label, b.label)),
        [facilityOptions],
    );

    useEffect(() => {
        if (!organizationWatch) {
            return;
        }

        dispatch(getFacilities(organizationWatch));
    }, [organizationWatch, dispatch]);

    return (
        <>
            <TextInput name={nameof<User>('name')} label={localized('Name')} validators={requiredValidator} />
            <TextInput
                name={nameof<User>('email')}
                label={localized('Email')}
                validators={requiredValidator}
                disabled={existingUser !== undefined}
            />
            <DividerWithMargin />
            <SelectInput
                noDefault
                name={nameof<OrganizationUser>('organizationId')}
                label={localized('Dealer')}
                options={sortedOrganizationOptions}
                validators={requiredValidator}
            />
            <SelectInput
                multiple
                name={nameof<UserDto>('allowedFacilityIds')}
                label={localized('Facilities')}
                options={sortedFacilityOptions}
            />
            <DividerWithMargin />
            <SelectInput
                noDefault
                name={nameof<OrganizationUser>('serviceToolRole')}
                label={localized('ServiceToolRole')}
                options={serviceToolRoleDropdownOptions}
                validators={requiredValidator}
            />
            {isGlobalAdmin && (
                <FlexRow>
                    <FlexRow>
                        <EtacCheckbox
                            name={nameof<User>('role')}
                            label={localized('Superuser')}
                            onChange={(event, checked) => {
                                if (checked) {
                                    alert(localized('SuperUserAlert'));
                                }
                            }}
                        />
                    </FlexRow>
                    <FlexRow>
                        <EtacCheckbox name={nameof<User>('hoistManager')} label={localized('HoistManager')} />
                    </FlexRow>
                </FlexRow>
            )}
            <SelectInput
                noDefault
                name={nameof<OrganizationUser>('organizationRole')}
                label={localized('WebUserLevel')}
                options={organizationRoleOptions}
                validators={requiredValidator}
            />
            {isGlobalAdmin && (
                <FlexRow>
                    <FlexRow>
                        <EtacCheckbox name={nameof<User>('canUseProductionTool')} label={localized('ProductionTool')} />
                    </FlexRow>
                    <FlexRow>
                        <EtacCheckbox
                            name={nameof<User>('dealerOnly')}
                            label={localized('DealerOnly')}
                            defaultChecked
                        />
                    </FlexRow>
                </FlexRow>
            )}
        </>
    );
});

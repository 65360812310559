import React, { FC, useCallback } from 'react';
import { PageHeader } from '../default-components/page-header.tsx/page-header';
import { Box, Button, SxProps } from '@mui/material';
import { localized } from '../../../i18n/i18n';
import { useNavigate, useParams } from 'react-router-dom';
import { DeviceMetaData } from '../device-meta-data';

const styles: { [key: string]: SxProps } = {
    button: {
        textTransform: 'none',
        fontSize: '14px',
        fontWeight: 'normal',
        width: '100px',
        padding: 0,
        height: '25px',
        marginTop: '-20px',
        left: '160px',
        position: 'absolute',
    },
};

interface Props {
    title: string;
    includeMetadata: boolean;
}

export const SubPageHeader: FC<Props> = React.memo((props) => {
    const navigate = useNavigate();
    const { serialNumber } = useParams();

    const navigateBack = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    return (
        <PageHeader title={props.title} area={props.title.toLowerCase()}>
            <Button variant={'text'} sx={styles.button} onClick={navigateBack}>
                {'< '}
                {localized('BackToList')}
            </Button>
            {props.children}
            {props.includeMetadata && (
                <Box
                    marginLeft={!props.children ? 'auto' : '20px'}
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}>
                    <DeviceMetaData serialNumber={Number(serialNumber)} />
                </Box>
            )}
        </PageHeader>
    );
});

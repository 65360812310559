import { createAsyncThunk } from '@reduxjs/toolkit';
import { IUser, User, UserClient } from '../../../api/api';
import { AsyncOperationHandler } from '../../../utilities/platform-helpers/api-helper';
import { userClient } from '../../api-clients';
import { DeleteUserData } from './types';

export const getUsers = createAsyncThunk('users/getUsers', (organizationId: number) => {
    return AsyncOperationHandler((client) => (client as UserClient).getByOrganizationId(organizationId), userClient);
});

export const getUserByAuthId = createAsyncThunk('users/getUserByAuthId', () => {
    return AsyncOperationHandler((client) => (client as UserClient).getByAuthId(), userClient);
});

export const saveUser = createAsyncThunk('users/saveUser', (user: IUser) => {
    return AsyncOperationHandler((client) => (client as UserClient).post(user as User), userClient, true);
});

export const updateUser = createAsyncThunk('users/updateUser', (user: IUser) => {
    return AsyncOperationHandler((client) => (client as UserClient).put(user as User), userClient, true);
});

export const updateUserLastActive = createAsyncThunk('users/updateLastActiveTime', () => {
    return AsyncOperationHandler((client) => (client as UserClient).updateLastActiveTime(), userClient);
});

export const updateUserConsent = createAsyncThunk('users/updateUserConsentVersion', (consentVersion: number) => {
    return AsyncOperationHandler(
        (client) => (client as UserClient).updateUserConsentVersion(consentVersion),
        userClient,
        true,
    );
});

export const deleteUser = createAsyncThunk('users/deleteUser', (user: IUser) => {
    return AsyncOperationHandler((client) => (client as UserClient).delete(user as User), userClient);
});

export const deleteFromOrganization = createAsyncThunk(
    'users/deleteFromOrganization',
    (deleteUserData: DeleteUserData) => {
        const { user, organizationId } = deleteUserData;
        return AsyncOperationHandler(
            (client) => (client as UserClient).deleteFromOrganization(user as User, organizationId),
            userClient,
        );
    },
);

export const getAllUsers = createAsyncThunk('users/getAll', () => {
    return AsyncOperationHandler((client) => (client as UserClient).get(), userClient);
});

export const resendInvitationEmail = createAsyncThunk('users/resendInvitationEmail', (email: string) => {
    return AsyncOperationHandler((client) => (client as UserClient).resendInvitationEmail(email), userClient, true);
});

export const getCurrentUserPermissions = createAsyncThunk('users/getCurrentUserPermissions', () => {
    return AsyncOperationHandler((client) => (client as UserClient).getUserPermissions(), userClient);
});

export const createSimulation = createAsyncThunk('users/createSimulation', (user: IUser) => {
    return AsyncOperationHandler((client) => (client as UserClient).createSimulatedUser(user as User), userClient);
});

export const stopSimulation = createAsyncThunk('users/stopSimulation', () => {
    return AsyncOperationHandler((client) => (client as UserClient).stopSimulation(), userClient);
});

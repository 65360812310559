import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AsyncOperationHandler } from '../../../utilities/platform-helpers/api-helper';
import { IProductionTestRun, ProductionTestRunClient } from '../../../api/api';
import { productionTestRunClient } from '../../api-clients';

export const getProductionTestRuns = createAsyncThunk(
    'productionTestRuns/getProductionTestRuns',
    (serialNumber: number) => {
        return AsyncOperationHandler(
            (client) => (client as ProductionTestRunClient).get(serialNumber),
            productionTestRunClient,
        );
    },
);

export const toggleDisplayProductionTest = createAction<IProductionTestRun>(
    'productionTestRuns/toggleDisplayProductionTest',
);

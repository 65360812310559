import {
    AuthenticationResult,
    BrowserAuthError,
    Configuration,
    EndSessionRequest,
    LogLevel,
    PopupRequest,
    PublicClientApplication,
    RedirectRequest,
    SilentRequest,
} from '@azure/msal-browser';
import jwtDecode from 'jwt-decode';
import { localized } from '../../i18n/i18n';
import { Monitor } from '../../services/telemetry-service';
import { routes } from '../../state/routes';
import { AuthRoles } from '../../utilities/constants';
import { setPostLoginRedirectUri } from '../../utilities/platform-helpers/auth-helper';
import { B2CConfig, tokenStorageKey, TOKEN_ROLE_IDENTIFIER } from '../auth-constants';
import { AuthUser } from '../auth-types';

// Configuration for the msal PublicClientApplication
const authConfig: Configuration = {
    auth: {
        authority: B2CConfig.Authority,
        clientId: B2CConfig.ClientId,
        knownAuthorities: [B2CConfig.Authority],
        redirectUri: B2CConfig.RedirectUri,
        postLogoutRedirectUri: `${location.origin}${routes.loggedOut}`,
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation: B2CConfig.CacheLocation,
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        Monitor.logTrace('[Auth error]' + message);
                        return;
                    case LogLevel.Info:
                        // console.info(message);
                        // Monitor.logTrace('[Auth info]' + message);
                        return;
                    case LogLevel.Verbose:
                        // console.debug(message);
                        // Monitor.logTrace('[Auth debug]' + message);
                        return;
                    case LogLevel.Warning:
                        Monitor.logTrace('[Auth warning]' + message);
                        return;
                }
            },
            piiLoggingEnabled: false,
        },
    },
};

// Helper function to get active account selected in login
export const getActiveAccount = () => {
    const account = msalInstance?.getActiveAccount();
    if (account) {
        return account;
    }
};

// Msal instance object through which all authentication flows
let msalInstance: PublicClientApplication | null;

// Different types of request for the authentication flows.
const redirectRequest: RedirectRequest = {
    scopes: ['openid', B2CConfig.Scope],
    redirectStartPage: window.location.href,
};
const popUpRequest: PopupRequest = { scopes: ['openid', B2CConfig.Scope] };

// Callback functions for success and logout
let handleTokenResponse: (accessToken: string) => void | undefined;
let handleLogout: () => void | undefined;

// Initiating the Msal instance, registering the redirectPromise callback function and initiate login
export const initAuthClient = async (
    handleTokenResponseCallback: (accessToken: string) => void,
    handleLogoutCallback: () => void,
) => {
    handleTokenResponse = handleTokenResponseCallback;
    handleLogout = handleLogoutCallback;
    msalInstance = await new PublicClientApplication(authConfig);

    setPostLoginRedirectUri();
    try {
        var response = await msalInstance.handleRedirectPromise();
        await handleAuthenticationResult(response);
    } catch (error) {
        await handleAuthenticationError(error as BrowserAuthError);
    }
};

// Handler for the authenticationResult from authentication requests
export const handleAuthenticationResult = async (response: AuthenticationResult | undefined | null) => {
    if (!msalInstance) {
        return;
    }

    // This happends on page refresh and log out
    if (!response) {
        const account = msalInstance.getActiveAccount();
        if (account) {
            // Get new token on page refresh
            await clientAcquireTokenSilent(false);
        } else {
            // Show login page - this happends on log out
            await clientLoginWithRedirect();
        }

        return;
    }

    if (response.account) {
        msalInstance.setActiveAccount(response.account);
    } else {
        const account = msalInstance.getActiveAccount();
        if (!account) {
            await clientLoginWithRedirect();
        }
    }

    handleTokenResponse(response.accessToken);
};

// Error handler for auth errors
export const handleAuthenticationError = async (error: BrowserAuthError) => {
    // In case of password reset the masl instance throws this error, which we need to handle and start password reset flow
    if (error.errorMessage.includes('AADB2C90077') && msalInstance) {
        await clientLoginWithRedirect();
    }
    // In case of password reset the masl instance throws this error, which we need to handle and start password reset flow
    else if (error.errorMessage.includes('AADB2C90118') && msalInstance) {
        let passwordResetRequest: RedirectRequest = {
            scopes: redirectRequest.scopes,
            authority: B2CConfig.PasswordResetAuthority,
        };

        await msalInstance.loginRedirect(passwordResetRequest);
        // Error is user returning from aborted password redirect, redirect to login page
    } else if (error.errorMessage.includes('AADB2C90091') && msalInstance) {
        await clientLoginWithRedirect();
    } else {
        Monitor.logException(error);
    }
};

// Login with redirect
export const clientLoginWithRedirect = async () => {
    setPostLoginRedirectUri();
    try {
        await msalInstance?.loginRedirect(redirectRequest);
    } catch (error) {
        await handleAuthenticationError(error as BrowserAuthError);
    }
};

// Login with popup
export const clientLoginWithPopup = async () => {
    try {
        const response = await msalInstance?.acquireTokenPopup(popUpRequest);
        await handleAuthenticationResult(response);
    } catch (error) {
        await handleAuthenticationError(error as BrowserAuthError);
    }
};

// Login silent
export const clientLoginSilent = async () => {
    // acquireTokenSilent doesn't work without an active account
    if (getActiveAccount() === undefined) return;

    const silentRequest: SilentRequest = {
        scopes: ['openid', B2CConfig.Scope],
        forceRefresh: false,
        account: getActiveAccount(),
    };

    try {
        const authResult = await msalInstance?.acquireTokenSilent(silentRequest);
        await handleAuthenticationResult(authResult);
    } catch (error) {
        await handleAuthenticationError(error as BrowserAuthError);
    }
};

// Aquire token silent. Used in case the user is validated, but does not have a token.
export const clientAcquireTokenSilent = async (forceRefreshToken: boolean) => {
    // This is done to avoid an exception at logout
    if (getActiveAccount() === undefined) return;

    const silentRequest: SilentRequest = {
        scopes: ['openid', B2CConfig.Scope],
        forceRefresh: forceRefreshToken,
        account: getActiveAccount(),
        redirectUri: window.location.origin + '/blank.html',
    };
    try {
        const authResult = await msalInstance?.acquireTokenSilent(silentRequest);
        await handleAuthenticationResult(authResult);
    } catch (error) {
        await handleAuthenticationError(error as BrowserAuthError);
    }
};

// Logout
export const clientLogout = async () => {
    const logoutRequest: EndSessionRequest = {
        account: getActiveAccount(),
    };
    handleLogout();
    await msalInstance?.logoutRedirect(logoutRequest);
};

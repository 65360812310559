import Grid from '@mui/material/Grid';
import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { useLayoutStyles } from './app-routes';
import { OverlaySpinner } from './view/components/default-components/spinner/overlay-spinner';
import { SideMenu } from './view/navigation/desktop/etac/side-menu';

export const AppLayout: FC = React.memo(() => {
    const classes = useLayoutStyles();

    return (
        <div>
            <Grid wrap="nowrap" container={true}>
                <SideMenu />
                <OverlaySpinner />
                <div className={classes.appContent}>
                    <Outlet />
                </div>
            </Grid>
        </div>
    );
});

import type { TypedStartListening } from '@reduxjs/toolkit';
import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import {
    resetLazyState,
    setCurFilters,
    setLazyOrdering,
    setPage,
    setPageSize,
    setSearchSerial,
} from './device-lazy-loading-slice';
import type { AppState } from '../../store';
import { getDeviceCount, getFilteredDevices } from '../devices/operation';
import { FilterOptions } from '../../../view/components/etac-components/devices/device-filter-selector';

export const paginationMiddleware = createListenerMiddleware();

export type AppStartListening = TypedStartListening<AppState>;

export const startAppListening = paginationMiddleware.startListening as AppStartListening;

startAppListening({
    matcher: isAnyOf(setPage, setPageSize, setSearchSerial, setCurFilters, setLazyOrdering, resetLazyState),
    effect: (action, listenerApi) => {
        listenerApi.cancelActiveListeners();

        const lazyLoaderValues = listenerApi.getState().deviceLazyLoadingReducer;

        listenerApi.dispatch(
            getFilteredDevices({
                currentPage: lazyLoaderValues.currentPage,
                pageSize: lazyLoaderValues.pageSize,
                filter: {
                    serialNumber: lazyLoaderValues.searchSerial,
                    userId: lazyLoaderValues.curFilters.find((u) => u.category === FilterOptions.User)?.id,
                    dealerId: lazyLoaderValues.curFilters.find((u) => u.category === FilterOptions.Dealer)?.id,
                    facilityId: lazyLoaderValues.curFilters.find((u) => u.category === FilterOptions.Facility)?.id,
                    department: lazyLoaderValues.curFilters.find((u) => u.category === FilterOptions.Department)?.label,
                    deviceModelName: lazyLoaderValues.curFilters.find((u) => u.category === FilterOptions.DeviceModel)
                        ?.label,
                },
                orderBy: lazyLoaderValues.orderBy as string,
                order: lazyLoaderValues.order,
            }),
        );
    },
});

startAppListening({
    matcher: isAnyOf(setSearchSerial, setCurFilters, resetLazyState),
    effect: (action, listenerApi) => {
        listenerApi.cancelActiveListeners();

        const lazyLoaderValues = listenerApi.getState().deviceLazyLoadingReducer;

        listenerApi.dispatch(
            getDeviceCount({
                serialNumber: lazyLoaderValues.searchSerial,
                userId: lazyLoaderValues.curFilters.find((u) => u.category === FilterOptions.User)?.id,
                dealerId: lazyLoaderValues.curFilters.find((u) => u.category === FilterOptions.Dealer)?.id,
                facilityId: lazyLoaderValues.curFilters.find((u) => u.category === FilterOptions.Facility)?.id,
                department: lazyLoaderValues.curFilters.find((u) => u.category === FilterOptions.Department)?.label,
                deviceModelName: lazyLoaderValues.curFilters.find((u) => u.category === FilterOptions.DeviceModel)
                    ?.label,
            }),
        );
    },
});

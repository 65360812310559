import { DevicesState } from './types';
import { createSlice, isAnyOf, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import {
    createWebRemarks,
    getDeviceDetails,
    getDeviceErrorsBySerialNumber,
    getDeviceMetadata,
    getDeviceModels,
    getFilteredDevices,
    getSnapshotsFull,
    getSnapshotsSlim,
    makeHoistObsolete,
    transferOwnership,
    tryGetSlimDevice,
    updateFacilityAndDealerOnDevice,
} from './operation';
import { AppState } from '../../store';

const initialState: DevicesState = {
    devices: [],
    activeDevice: undefined,
    deviceMetadata: undefined,
    serviceHistory: [],
    pending: false,
    isSuccess: false,
    snapshotLog: [],
    deviceErrors: [],
    deviceToBeTransfered: undefined,
    deviceModelNames: [],
};

export const devicesSlice = createSlice({
    name: 'devices',
    initialState,
    reducers: {
        removeActiveDevice: (state) => {
            state.activeDevice = undefined;
        },
        removeHoistToBeTransfered: (state) => {
            state.deviceToBeTransfered = undefined;
        },
    },
    extraReducers: (builder) => {
        // Get filtered
        builder.addCase(getFilteredDevices.fulfilled, (state, action) => {
            state.devices = action.payload;
        });

        // Get details
        builder.addCase(getDeviceDetails.fulfilled, (state, action) => {
            state.activeDevice = action.payload;
        });

        // Get service history
        builder.addCase(getSnapshotsSlim.fulfilled, (state, action) => {
            state.serviceHistory = action.payload;
        });

        // Get metadata
        builder.addCase(getDeviceMetadata.fulfilled, (state, action) => {
            state.deviceMetadata = action.payload;
        });

        // Get snapshot log
        builder.addCase(getSnapshotsFull.fulfilled, (state, action) => {
            state.snapshotLog = action.payload;
        });

        // Get device errors
        builder.addCase(getDeviceErrorsBySerialNumber.fulfilled, (state, action) => {
            state.pending = false;
            state.deviceErrors = action.payload;
        });

        builder.addCase(getDeviceErrorsBySerialNumber.pending, (state, _) => {
            state.pending = true;
        });

        builder.addCase(getDeviceErrorsBySerialNumber.rejected, (state, _) => {
            state.pending = false;
        });

        //Change device facility/dealer
        builder.addCase(updateFacilityAndDealerOnDevice.fulfilled, (state, action) => {
            const index = state.devices.findIndex((device) => device.serialNumber === action.payload.serialNumber);
            if (index > -1) {
                state.devices[index] = action.payload;
            }
        });

        builder.addCase(tryGetSlimDevice.fulfilled, (state, action) => {
            state.deviceToBeTransfered = action.payload;
        });
        builder.addCase(tryGetSlimDevice.rejected, (state, action) => {
            state.deviceToBeTransfered = undefined;
        });

        builder.addCase(transferOwnership.fulfilled, (state, action) => {
            state.deviceToBeTransfered = undefined;
            const index = state.devices.findIndex((d) => d.serialNumber === action.payload.serialNumber);
            if (index > -1) {
                state.devices[index] = action.payload;
            }
        });

        builder.addCase(createWebRemarks.fulfilled, (state, action) => {
            state.serviceHistory.unshift(action.payload);
        });

        builder.addCase(makeHoistObsolete.fulfilled, (state, action) => {
            const index = state.devices.findIndex((device) => device.serialNumber === action.meta.arg);
            if (index > -1) {
                state.devices.splice(index, 1);
            }
        });

        builder.addCase(getDeviceModels.fulfilled, (state, action) => {
            state.deviceModelNames = action.payload;
        });

        builder.addMatcher(
            isPending(
                getFilteredDevices,
                getDeviceDetails,
                getSnapshotsSlim,
                getDeviceMetadata,
                getSnapshotsFull,
                updateFacilityAndDealerOnDevice,
                createWebRemarks,
                makeHoistObsolete,
                tryGetSlimDevice,
            ),
            (state) => {
                state.pending = true;
            },
        );
        builder.addMatcher(
            isAnyOf(
                isRejected(
                    getFilteredDevices,
                    getDeviceDetails,
                    getSnapshotsSlim,
                    getDeviceMetadata,
                    getSnapshotsFull,
                    updateFacilityAndDealerOnDevice,
                    createWebRemarks,
                    makeHoistObsolete,
                    tryGetSlimDevice,
                ),
                isFulfilled(
                    getFilteredDevices,
                    getDeviceDetails,
                    getSnapshotsSlim,
                    getDeviceMetadata,
                    getSnapshotsFull,
                    updateFacilityAndDealerOnDevice,
                    createWebRemarks,
                    makeHoistObsolete,
                    tryGetSlimDevice,
                ),
            ),
            (state) => {
                state.pending = false;
            },
        );
    },
});

export const selectDevices = (store: AppState) => store.devicesReducer.devices;
export const selectActiveDevice = (store: AppState) => store.devicesReducer.activeDevice;
export const selectServiceHistory = (store: AppState) => store.devicesReducer.serviceHistory;
export const selectDeviceMetadata = (store: AppState) => store.devicesReducer.deviceMetadata;
export const selectSnapshotLog = (store: AppState) => store.devicesReducer.snapshotLog;
export const selectDeviceErrors = (store: AppState) => store.devicesReducer.deviceErrors;
export const selectDeviceToBeTransfered = (store: AppState) => store.devicesReducer.deviceToBeTransfered;
export const selectDeviceModelNames = (store: AppState) => store.devicesReducer.deviceModelNames;

// eslint-disable-next-line
export const { removeActiveDevice, removeHoistToBeTransfered } = devicesSlice.actions;

export default devicesSlice.reducer;

import { PaletteOptions } from '@mui/material/styles';
import {
    backgroundColor,
    primaryColor,
    primaryTextColor,
    secondaryColor,
    secondaryTextColor,
    whiteColor,
} from './color-constants';

export const DefaultPalette: PaletteOptions = {
    primary: {
        main: primaryColor,
        contrastText: whiteColor,
    },
    secondary: {
        main: secondaryColor,
        contrastText: whiteColor,
    },
    background: {
        default: backgroundColor,
    },
    text: {
        primary: primaryTextColor,
        secondary: secondaryTextColor,
    },
    Example: { main: '#333' },
};

import React, { FC, useMemo } from 'react';
import { styled, SxProps, Table, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { TableRowProps } from '@mui/material/TableRow';
import { backgroundColorPaper } from '../../../../styles/color-constants';
import { TableMaxWidth } from '../../../../styles/styling-constants';

export interface BasicHeadCell {
    id: string;
    label: string;
    width?: string;
}

interface Props {
    headCells: BasicHeadCell[];
    fullWidth?: boolean;
}

export const BasicTable: FC<Props> = React.memo((props) => {
    const containerProps = useMemo(() => {
        let containerProps: SxProps = { height: '80vh', marginTop: '10px' };

        if (!props.fullWidth) {
            containerProps.maxWidth = TableMaxWidth.maxWidth;
        }

        return containerProps;
    }, [props.fullWidth]);

    return (
        <TableContainer sx={containerProps}>
            <Table stickyHeader sx={{ borderCollapse: 'separate', borderSpacing: '0px 10px' }}>
                <TableHead>
                    <TableRow sx={{ height: '40px' }}>
                        {props.headCells.map((cell) => (
                            <TableCell key={cell.id} sx={{ minWidth: cell.width }}>
                                <TableHeadTypography>{cell.label}</TableHeadTypography>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                {props.children}
            </Table>
        </TableContainer>
    );
});

const TableHeadTypography = styled(Typography)(() => ({
    fontSize: '14px',
    fontWeight: 'bold',
}));

export const BasicTableRow = styled(TableRow)<TableRowProps>(() => ({
    height: '39px',
    backgroundColor: backgroundColorPaper,
}));

import { SxProps, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { FC, useContext, useEffect, useState } from 'react';
import { backgroundColor, primaryTextColor } from '../../../../styles/color-constants';
import { FlexRow } from '../flex-row';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            backgroundColor: backgroundColor,
            padding: '32px 50px 0px 50px',
        },
        title: {
            color: primaryTextColor,
            fontWeight: 'bold',
            fontSize: '38px',
        },
    }),
);

interface PropsFromParent {
    title: string;
    area: string;
    sx?: SxProps;
    marginTop?: string;
}

export const PageHeaderContext = React.createContext({} as any);
export const usePageHeader = () => useContext(PageHeaderContext);

export const PageHeader: FC<PropsFromParent> = React.memo(({ title, area, ...props }) => {
    const classes = useStyles();
    const [areaId, setAreaId] = useState<string>();
    useEffect(() => {
        setAreaId(area);
    }, [area]);

    return (
        <PageHeaderContext.Provider value={{ areaId }}>
            <FlexRow className={classes.root} marginTop={props.marginTop}>
                <Typography className={classes.title} sx={props.sx}>
                    {title}
                </Typography>
                {props.children}
            </FlexRow>
        </PageHeaderContext.Provider>
    );
});

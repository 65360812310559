import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { SimpleSearchbar } from '../simple-searchbar';
import { RoundButton } from '../round-button';
import { Stack, TextField, Typography } from '@mui/material';
import { localized } from '../../../../i18n/i18n';
import { openEditUserDrawer } from '../../../../state/features/user-drawer/user-drawer-slice';
import { useDispatch, useSelector } from 'react-redux';
import Autocomplete from '@mui/material/Autocomplete';
import { getOrganizationForDropdown } from '../../../../state/features/organization/operation';
import { selectOrganizationsForDropdown } from '../../../../state/features/organization/organization-slice';
import { SortByProperty } from '../../../../utilities/platform-helpers/sorting-helper';

interface Props {
    searchText: string;
    setSearchText: (value: string) => void;
    setDealerFilter: (dealerName: string | undefined) => void;
}

export const UsersHeaderContent: FC<Props> = React.memo(({ searchText, setSearchText, setDealerFilter }) => {
    const dispatch = useDispatch();
    const organizationsForDropdown = useSelector(selectOrganizationsForDropdown);

    const organizationOptions = useMemo(
        () =>
            organizationsForDropdown
                .filter((org) => org.name)
                .map((org) => ({ id: org.id, label: org.name }))
                .sort((a, b) => SortByProperty(a.label, b.label)),
        [organizationsForDropdown],
    );

    useEffect(() => {
        dispatch(getOrganizationForDropdown());
    }, []);

    const handleAutoCompleteChange = useCallback(
        (event: any, value: { id: number; label: string | undefined } | null) => {
            setDealerFilter(value?.label);
        },
        [setDealerFilter],
    );

    const openDrawer = useCallback(() => {
        dispatch(openEditUserDrawer());
    }, [dispatch]);

    const autocompleteRender = useCallback((params) => <TextField {...params} label={localized('DealerFilter')} />, []);

    const autoCompleteRenderOption = useCallback((props, option) => {
        return (
            <li {...props} key={option?.id}>
                {option?.label}
            </li>
        );
    }, []);

    return (
        <Stack direction={'row'} marginLeft={'auto'} spacing={'8px'} alignItems={'center'}>
            <Autocomplete
                renderInput={autocompleteRender}
                options={organizationOptions}
                renderOption={autoCompleteRenderOption}
                sx={{ width: 216, backgroundColor: 'white' }}
                onChange={handleAutoCompleteChange}
            />
            <SimpleSearchbar searchText={searchText} setSearchText={setSearchText} />
            <RoundButton slim sx={{ marginLeft: 'auto' }} onClick={openDrawer} width={'178px'}>
                <Typography>{localized('NewUser')}</Typography>
            </RoundButton>
        </Stack>
    );
});

import { ListSubheader, Select, SelectChangeEvent, SxProps, Theme } from '@mui/material';
import React, { PropsWithChildren, useMemo } from 'react';

export interface SelectGroup {
    header: string;
    menuOptions: JSX.Element[];
}

interface Props<T> {
    value: T | '';
    onChange: (newVal: SelectChangeEvent<T>) => void;
    groups: SelectGroup[];
    disabled: boolean;
    sx?: SxProps<Theme>;
    labelId?: string;
    label?: string;
}

function SelectWithGroupings<T>(props: PropsWithChildren<Props<T>>) {
    const groupsWithHeaders = useMemo(() => {
        return props.groups.map((g) => {
            return [<ListSubheader>{g.header}</ListSubheader>, g.menuOptions];
        });
    }, [props.groups]);
    return (
        <Select
            defaultValue={props.value !== '' ? props.value : undefined}
            value={props.value}
            sx={props.sx}
            onChange={props.onChange}
            labelId={props.labelId}
            label={props.label}
            disabled={props.disabled}>
            {groupsWithHeaders}
            {props.children}
        </Select>
    );
}

export default React.memo(SelectWithGroupings) as typeof SelectWithGroupings;

import { Box, IconButton, List, SxProps, Typography } from '@mui/material';
import React, { FC, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import moliftLogo from '../../../../assets/molift-logo.png';
import PrivacyTipOutlinedIcon from '@mui/icons-material/PrivacyTipOutlined';
import { ReactComponent as LogoutIcon } from '../../../../assets/icons/logout-icon.svg';
import { ReactComponent as UserIcon } from '../../../../assets/icons/users-icon.svg';
import { ReactComponent as DevicesIcon } from '../../../../assets/icons/devices-icon.svg';
import { ReactComponent as DealersIcon } from '../../../../assets/icons/dealer-icon.svg';
import { routes } from '../../../../state/routes';
import { primaryColor, primaryTextColor, secondaryTextColor } from '../../../../styles/color-constants';
import { MenuItem } from './menu-item';
import { localized } from '../../../../i18n/i18n';
import { FlexColumn } from '../../../components/default-components/flex-column';
import { useAuth } from '../../../../auth/auth-provider';
import { DownloadButton } from '../../../components/etac-components/download-button';
import { useDispatch, useSelector } from 'react-redux';
import { Organization } from '../../../../api/api';
import { selectOrganization, setOrganizationSetting } from '../../../../state/features/settings/settings-slice';
import { getCurrentUserPermissions, getUserByAuthId } from '../../../../state/features/users/operation';
import { selectOrganizations } from '../../../../state/features/organization/organization-slice';
import { getOrganizations } from '../../../../state/features/organization/operation';
import { UserSimulator } from '../../../components/etac-components/users/user-simulator';
import { selectCurrentUserIsSimulation } from '../../../../state/features/users/users-slice';
import { CreateUserDrawer } from '../../../components/etac-components/users/create-user-drawer';

const styles: { [key: string]: SxProps } = {
    menu: {
        backgroundColor: 'white',
        height: '100vh',
        minWidth: '120px',
        maxWidth: '120px',
        overflow: 'auto',
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        zIndex: 100,
    },
    list: {
        flexGrow: 1,
        marginTop: '24px',
    },
    logout: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginBottom: '32px',
    },
    logoutButton: {
        marginTop: '8px',
        color: primaryTextColor,
        '&:hover': {
            backgroundColor: 'transparent',
            color: primaryColor,
        },
    },
    privacyButton: {
        marginTop: '8px',
        color: primaryTextColor,
        '&:hover': {
            backgroundColor: 'transparent',
            color: primaryColor,
        },
    },
};

export const SideMenu: FC = React.memo(() => {
    const { logout, isAdmin, user, isGlobalAdmin } = useAuth();
    const isSimulated = useSelector(selectCurrentUserIsSimulation);
    const dispatch = useDispatch();
    const location = useLocation();
    const locationBaseString = '/' + location.pathname.split('/')[1];

    const selectedOrganization = useSelector(selectOrganization);
    const organizations = useSelector(selectOrganizations);

    useEffect(() => {
        dispatch(getOrganizations());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getUserByAuthId());
        dispatch(getCurrentUserPermissions());

        if (!selectedOrganization && organizations?.length >= 1) {
            const organizationToSet = organizations.find((o) => o.parentOrganization === undefined);
            dispatch(setOrganizationSetting((organizationToSet as Organization) ?? (organizations[0] as Organization)));
        }
    }, [dispatch, selectedOrganization, organizations]);

    const handleLogout = useCallback(() => {
        logout();
    }, [logout]);

    return (
        <>
            <div style={{ minWidth: '120px' }}></div>
            <FlexColumn sx={styles.menu}>
                <FlexColumn alignItems="center" justifyContent="flex-end" marginTop="18px">
                    <img alt="Logo" src={moliftLogo} height={'16px'} />
                    <Typography textTransform={'uppercase'} color={secondaryTextColor} fontSize={'10px'}>
                        {localized('WebPortal')}
                    </Typography>
                    <Typography marginTop={'16px'} fontSize={'14px'}>
                        {localized('SignedInAs')}:{' '}
                    </Typography>
                    <Typography fontSize={'12px'} textAlign={'center'} paddingX={'2px'}>
                        {user?.name ?? 'Unknown'}
                    </Typography>
                </FlexColumn>
                <List sx={styles.list}>
                    {isAdmin && (
                        <MenuItem
                            linkTo={routes.userAdministration}
                            selected={locationBaseString === routes.userAdministration}
                            icon={<UserIcon height={'50px'} width={'50px'} />}
                            text={localized('Users')}
                            key={routes.userAdministration}
                        />
                    )}
                    <MenuItem
                        linkTo={routes.devices}
                        selected={locationBaseString === routes.devices}
                        icon={<DevicesIcon height={'55px'} width={'48px'} />}
                        text={localized('Devices')}
                        key={routes.devices}
                    />
                    <MenuItem
                        linkTo={routes.dealers}
                        selected={locationBaseString === routes.dealers}
                        icon={<DealersIcon height={'55px'} width={'55px'} />}
                        text={localized('Dealers')}
                        key={routes.dealers}
                    />
                </List>
                <Box sx={styles.logout}>
                    {(isGlobalAdmin || isSimulated) && <UserSimulator />}
                    {/* This has been moved here to exist on all pages for userSimulator */}
                    <CreateUserDrawer />
                    <DownloadButton />
                    <IconButton sx={styles.logoutButton} disableRipple onClick={handleLogout}>
                        <FlexColumn>
                            <LogoutIcon />
                            <Typography>{localized('LogOut')}</Typography>
                        </FlexColumn>
                    </IconButton>

                    <IconButton
                        sx={styles.privacyButton}
                        disableRipple
                        target="_blank"
                        href="https://www.etac.com/about-us/about-etac/legal/privacy-policy/">
                        <FlexColumn>
                            <PrivacyTipOutlinedIcon sx={{ width: '100%' }} />
                            <Typography>{localized('Privacy')}</Typography>
                        </FlexColumn>
                    </IconButton>
                </Box>
            </FlexColumn>
        </>
    );
});

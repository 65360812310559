import React, { FC } from 'react';
import { Box, BoxProps } from '@mui/material';

export const FlexRow: FC<BoxProps> = React.memo((props) => {
    return (
        <Box display="flex" flexDirection="row" {...props}>
            {props.children}
        </Box>
    );
});

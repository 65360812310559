import { ModelType } from '../enums/model-type';

export function modelNumberToType(modelNumber: number | undefined): ModelType {
    switch (modelNumber) {
        case 1301:
        case 1303:
            return ModelType.partner255;
        case 1302:
            return ModelType.partner230;
        case 1401:
            return ModelType.nomad255;
        case 1402:
            return ModelType.nomad205;
        case 1801:
        case 1804:
            return ModelType.quickRaiser1;
        case 1802:
        case 1805:
            return ModelType.quickRaiser2;
        case 1803:
        case 1806:
            return ModelType.quickRaiser2Plus;
        case 2001:
            return ModelType.air205;
        case 2002:
        case 2005:
        case 2012:
        case 2014:
            return ModelType.air200;
        case 2003:
        case 2013:
            return ModelType.air350;
        case 2004:
            return ModelType.air300;
        case 2102:
            return ModelType.mover205;
        case 2201:
            return ModelType.quickRaiser205;
        case 2401:
        case 2411:
            return ModelType.air500;
        case 2501:
        case 2502:
            return ModelType.mover180;
        case 2701:
        case 2702:
            return ModelType.mover300;
        case 9301:
        case 9302:
            return ModelType.smart150;
        case 1199:
        case 1299:
        case 1399:
        case 2099:
            return ModelType.oldSparePart;
        case 2098:
            return ModelType.newSparePart;
        default:
            return ModelType.unknown;
    }
}

export const noInspectionVersions = [
    ModelType.mover180,
    ModelType.mover300,
    ModelType.mover205,
    ModelType.quickRaiser1,
    ModelType.quickRaiser2,
    ModelType.quickRaiser2Plus,
    ModelType.smart150,
    ModelType.air205,
    ModelType.air300,
    ModelType.partner230,
    ModelType.partner255,
    ModelType.nomad205,
    ModelType.nomad255,
    ModelType.unknown,
    ModelType.oldSparePart,
];

import React, { FC } from 'react';
import { IDeviceSnapshotSlimDto, SnapshotReason } from '../../../../api/api';
import { BasicTableRow } from '../basic-table/basic-table';
import { EnhancedTableCell } from '../enhanced-table/enhanced-table';
import { formatDateTimeFull } from '../../../../utilities/helpers/date-format-helper';
import { LeftBorderRadius } from '../../../../styles/styling-constants';

interface Props {
    snapshot: IDeviceSnapshotSlimDto;
}

export const ServiceHistoryTableRow: FC<Props> = React.memo(({ snapshot }) => {
    return (
        <BasicTableRow>
            <EnhancedTableCell sx={LeftBorderRadius}>{formatDateTimeFull(snapshot.appTime)}</EnhancedTableCell>
            <EnhancedTableCell>{snapshot.updatedBy}</EnhancedTableCell>
            <EnhancedTableCell>{SnapshotReason[snapshot.snapshotReason]}</EnhancedTableCell>
            <EnhancedTableCell>{snapshot.remark}</EnhancedTableCell>
        </BasicTableRow>
    );
});

import React, { FC } from 'react';
import { IDevice, SnapshotReason } from '../../../../api/api';
import { BasicTableRow } from '../basic-table/basic-table';
import { EnhancedTableCell } from '../enhanced-table/enhanced-table';
import { formatDateTimeFull } from '../../../../utilities/helpers/date-format-helper';
import { LeftBorderRadius } from '../../../../styles/styling-constants';

interface Props {
    snapshot: IDevice;
}

export const SnapshotLogTableRow: FC<Props> = React.memo(({ snapshot }) => {
    return (
        <BasicTableRow>
            <EnhancedTableCell sx={LeftBorderRadius}>{formatDateTimeFull(snapshot.appTime)}</EnhancedTableCell>
            <EnhancedTableCell>{SnapshotReason[snapshot.snapshotReason]}</EnhancedTableCell>
            <EnhancedTableCell>{snapshot.facilityId}</EnhancedTableCell>
            <EnhancedTableCell>{snapshot.inspectionsPerformed}</EnhancedTableCell>
            <EnhancedTableCell>{formatDateTimeFull(snapshot.lastInspection)}</EnhancedTableCell>
            <EnhancedTableCell>{snapshot.servicesPerformed}</EnhancedTableCell>
            <EnhancedTableCell>{formatDateTimeFull(snapshot.lastService)}</EnhancedTableCell>
            <EnhancedTableCell>{snapshot.totalLifts}</EnhancedTableCell>
            <EnhancedTableCell>{snapshot.liftsClass1}</EnhancedTableCell>
            <EnhancedTableCell>{snapshot.liftsClass2}</EnhancedTableCell>
            <EnhancedTableCell>{snapshot.liftsClass3}</EnhancedTableCell>
            <EnhancedTableCell>{snapshot.liftsClass4}</EnhancedTableCell>
            <EnhancedTableCell>{snapshot.remainingLiftsToService}</EnhancedTableCell>
            <EnhancedTableCell>{snapshot.maxKg}</EnhancedTableCell>
            <EnhancedTableCell>{snapshot.maxCurrent}</EnhancedTableCell>
            <EnhancedTableCell>{snapshot.modelName}</EnhancedTableCell>
            <EnhancedTableCell>{snapshot.overloadLifts}</EnhancedTableCell>
            <EnhancedTableCell>{snapshot.productNumber}</EnhancedTableCell>
            <EnhancedTableCell>{snapshot.productionWeek}</EnhancedTableCell>
            <EnhancedTableCell>{snapshot.productionYear}</EnhancedTableCell>
            <EnhancedTableCell>{snapshot.remark}</EnhancedTableCell>
            <EnhancedTableCell>{snapshot.department}</EnhancedTableCell>
            <EnhancedTableCell>{snapshot.softwareVersion}</EnhancedTableCell>
        </BasicTableRow>
    );
});

import React, { FC } from 'react';
import { Box, Divider, Stack, Typography } from '@mui/material';

interface Props {
    text: string;
    value: any;
    divider?: boolean;
}

export const DeviceDetailsLine: FC<Props> = React.memo((props) => {
    const fontSize = '14px';

    return (
        <Stack spacing={'8px'}>
            <Box width={'inherit'} sx={{ display: 'flex', flexDirection: 'row' }}>
                <Typography fontSize={fontSize}>{props.text}</Typography>
                <Typography fontSize={fontSize} fontWeight={'bold'} marginLeft={'auto'}>
                    {props.value}
                </Typography>
            </Box>
            {props.divider && <Divider sx={{ flexGrow: 1 }} />}
        </Stack>
    );
});

import { OrganizationRole, ServiceToolRole, UserRole } from '../../api/api';
import { localizedDynamic } from '../../i18n/i18n';
import { DropdownOption } from '../../view/components/default-components/baisc-dropdown/basic-search-dropdown';

export function getUserRoleDropdownOptions(): DropdownOption[] {
    var options = Object.keys(UserRole)
        .filter((k) => typeof UserRole[k as any] === 'number')
        .map((e) => new DropdownOption(localizedDynamic(e), UserRole[e as any]));

    return options.filter((option) => option.label !== 'Admin');
}

export function getOrganizationRoleDropdownOptions(): DropdownOption[] {
    return Object.keys(OrganizationRole)
        .filter((k) => typeof OrganizationRole[k as any] === 'number')
        .map((e) => new DropdownOption(localizedDynamic(e), OrganizationRole[e as any]));
}

export function getServiceToolRoleDropdownOptions(): DropdownOption[] {
    return Object.keys(ServiceToolRole)
        .filter((k) => typeof ServiceToolRole[k as any] === 'number')
        .map((e) => new DropdownOption(localizedDynamic(e), ServiceToolRole[e as any]));
}

import { Stack } from '@mui/material';
import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { FacilityDto, IFacility } from '../../../../api/api';
import { localized, localizedInterpolation } from '../../../../i18n/i18n';
import { createFacility, updateFacility } from '../../../../state/features/facilities/operation';
import { nameof } from '../../../../utilities/platform-helpers/nameof-helper';
import { requiredValidator } from '../../../../utilities/platform-helpers/validator-helper';
import { Form } from '../../default-components/form/form';
import { TextInput } from '../../default-components/form/text-input';
import { RoundButton } from '../round-button';
import { DividerWithMargin } from '../users/user-form-etac';
import { GridContainer } from '../grid/grid-container';

interface Props {
    handleClose: () => void;
    existingFacility?: IFacility;
    dealerId: number | undefined | null;
    itemsPerRow?: number;
}

export const FacilityForm: FC<Props> = React.memo(({ handleClose, existingFacility, dealerId, itemsPerRow = 1 }) => {
    const dispatch = useDispatch();
    const save = useCallback(
        (data: any) => {
            if (isNaN(Number(dealerId))) return;

            const facility = new FacilityDto();
            facility.init(data);
            facility.organizationId = Number(dealerId);

            if (existingFacility) {
                dispatch(updateFacility(facility));
            } else {
                dispatch(createFacility(facility));
            }
        },
        [dispatch, existingFacility, dealerId],
    );

    return (
        <Form onSubmit={save} defaultValues={existingFacility}>
            <TextInput name={nameof<FacilityDto>('name')} label={localized('Name')} validators={requiredValidator} />
            <DividerWithMargin />
            <GridContainer itemsPerRow={itemsPerRow}>
                <TextInput name={nameof<FacilityDto>('address')} label={localized('Address')} fullWidth />
                <TextInput name={nameof<FacilityDto>('postalCode')} label={localized('PostalCode')} fullWidth />
                <TextInput name={nameof<FacilityDto>('city')} label={localized('City')} fullWidth />
                <TextInput name={nameof<FacilityDto>('district')} label={localized('District')} fullWidth />
                <TextInput name={nameof<FacilityDto>('country')} label={localized('Country')} fullWidth />
                <TextInput name={nameof<FacilityDto>('contact')} label={localized('Contact')} fullWidth />
                <TextInput name={nameof<FacilityDto>('phone')} label={localized('Phone')} fullWidth />
                <TextInput name={nameof<FacilityDto>('email')} label={localized('Email')} fullWidth />
            </GridContainer>
            <Stack spacing={1} direction={'row'} marginTop={'auto'}>
                <RoundButton width={'50%'} slim color={'secondary'} onClick={handleClose}>
                    {localized('Cancel')}
                </RoundButton>
                <RoundButton width={'50%'} type={'submit'} slim>
                    {localizedInterpolation(existingFacility !== undefined ? 'SaveInt' : 'CreateNewInt', {
                        area: localized('Facility'),
                    })}
                </RoundButton>
            </Stack>
        </Form>
    );
});

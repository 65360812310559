import { DealerDrawerState } from './types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from '../../store';
import { IOrganization } from '../../../api/api';
import { deleteOrganization, saveOrganization, updateOrganization } from '../organization/operation';

const initialState: DealerDrawerState = {
    drawerOpen: false,
};

export const dealerDrawerSlice = createSlice({
    name: 'dealerDrawer',
    initialState,
    reducers: {
        openDealerDrawer: (state) => {
            state.drawerOpen = true;
        },
        closeDealerDrawer: (state) => {
            state.drawerOpen = false;
            state.dealerForEdit = undefined;
        },
        openDealerDrawerForEdit: (state, action: PayloadAction<IOrganization | undefined>) => {
            state.dealerForEdit = action.payload;
            state.drawerOpen = true;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(saveOrganization.fulfilled, (state) => {
            state.drawerOpen = false;
            state.dealerForEdit = undefined;
        });

        builder.addCase(updateOrganization.fulfilled, (state) => {
            state.drawerOpen = false;
            state.dealerForEdit = undefined;
        });

        builder.addCase(deleteOrganization.fulfilled, (state) => {
            state.drawerOpen = false;
            state.dealerForEdit = undefined;
        });
    },
});

export const selectDealerDrawerOpen = (store: AppState) => store.dealerDrawerReducer.drawerOpen;
export const selectDealerForEdit = (store: AppState) => store.dealerDrawerReducer.dealerForEdit;

export const { openDealerDrawer, closeDealerDrawer, openDealerDrawerForEdit } = dealerDrawerSlice.actions;

export default dealerDrawerSlice.reducer;

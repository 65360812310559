import { Box, Typography } from '@mui/material';
import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { clientLogout } from '../../../../auth/b2c/b2c-auth-provider';
import { localized, localizedInterpolation } from '../../../../i18n/i18n';
import { updateUserConsent } from '../../../../state/features/users/operation';
import { CurrentConsentVersion } from '../../../../utilities/constants';
import { BasicButton } from '../buttons/basic-button';
import { FlexColumn } from '../flex-column';
import { FlexRow } from '../flex-row';

interface Props {
    setModalOpen: (value: boolean) => void;
}
export const ConsentCheckBox: FC<Props> = (props) => {
    const dispatch = useDispatch();

    const acceptPolicy = useCallback(() => {
        dispatch(updateUserConsent(CurrentConsentVersion));
        props.setModalOpen(false);
    }, []);

    return (
        <Box>
            <Typography variant="h3">{localized('Privacy')}</Typography>
            <Typography variant="body1">
                {localizedInterpolation('PrivacyConsent', { yes: localized('Yes') })}
                <a target="_blank" href="https://www.etac.com/about-us/about-etac/legal/privacy-policy/">
                    {localized('Privacy')}
                </a>
            </Typography>
            <FlexRow justifyContent="center" marginTop="10px">
                <FlexColumn>
                    <BasicButton
                        sx={{ marginRight: 1 }}
                        buttonColor={'secondary'}
                        onClick={clientLogout}
                        text={localized('No')}
                    />
                </FlexColumn>
                <FlexColumn>
                    <BasicButton
                        onClick={acceptPolicy}
                        sx={{ marginLeft: 1 }}
                        buttonColor={'primary'}
                        text={localized('Yes')}
                    />
                </FlexColumn>
            </FlexRow>
        </Box>
    );
};

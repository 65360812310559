import { Autocomplete, Popper, SxProps, TextField } from '@mui/material';
import React, { FC, SyntheticEvent, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useAuth } from '../../../../auth/auth-provider';
import { selectOrganizationsForDropdown } from '../../../../state/features/organization/organization-slice';
import { SortByProperty } from '../../../../utilities/platform-helpers/sorting-helper';
import { AutoCompleteOptions } from './device-details-dealer-and-facility';

const styles: { [key: string]: SxProps } = {
    selectStyle: {
        flex: 1,
        minWidth: 160,
        maxWidth: 160,
    },
    popperStyle: {
        width: 'fit-content',
    },
};

interface Props {
    selectedOrganizationId: number | '';
    onDealerSelected: (newDealerId: number) => void;
}

export const DealerSelect: FC<Props> = ({ selectedOrganizationId, onDealerSelected }) => {
    const { isGlobalAdmin, isAdmin, isHoistManager } = useAuth();

    const allOrganizations = useSelector(selectOrganizationsForDropdown);

    const allOrganizationLabelsSorted: AutoCompleteOptions[] = useMemo(() => {
        const orgsWithAName = allOrganizations.filter((o) => o.name);
        orgsWithAName.sort((a, b) => SortByProperty(a.name, b.name));

        return orgsWithAName.map((o, i) => ({ label: o.name ?? '', id: o.id }));
    }, [allOrganizations]);

    const chosenOrganization = useMemo(() => {
        const org = allOrganizationLabelsSorted.find((o) => o.id === selectedOrganizationId);
        return org;
    }, [selectedOrganizationId, allOrganizationLabelsSorted]);

    const handleNewOrganizationSelected = useCallback(
        (e: SyntheticEvent<Element, Event>, selectedOrg: AutoCompleteOptions | null) => {
            const org = allOrganizations.find((o) => o.id === selectedOrg?.id);
            if (!org) return;
            onDealerSelected(org.id);
        },
        [allOrganizations],
    );

    const customPopper = (props: any) => {
        return <Popper {...props} style={styles.popperStyle} placement="top-start" />;
    };

    const autoCompleteRenderOption = useCallback((props, option) => {
        return (
            <li {...props} key={option?.id}>
                {option?.label}
            </li>
        );
    }, []);

    const autocompleteRender = useCallback((params) => <TextField {...params} />, []);

    return (
        <Autocomplete
            value={chosenOrganization || null}
            disabled={!isGlobalAdmin && !isAdmin && !isHoistManager}
            options={allOrganizationLabelsSorted}
            renderOption={autoCompleteRenderOption}
            onChange={handleNewOrganizationSelected}
            sx={styles.selectStyle}
            renderInput={autocompleteRender}
            PopperComponent={customPopper}
        />
    );
};

import {SortDirection} from '@mui/material';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {createSelector} from 'reselect';
import {AppState} from '../../store';
import {PageHeaderSettingState, ViewType} from './types';

const initialState: PageHeaderSettingState = {
  pageSettings: [],
};

export const pageSettingSlice = createSlice({
  name: 'pageSetting',
  initialState,
  reducers: {
    toggleView: (state, action: PayloadAction<{value: ViewType; key: string}>) => {
      let settingExist = state.pageSettings.find((setting) => setting.key === action.payload.key);
      if (settingExist) {
        settingExist.itemView = action.payload.value;
      } else {
        state.pageSettings.push({
          key: action.payload.key,
          sort: 'asc',
          itemView: action.payload.value,
        });
      }
    },
    searchChanged: (state, action: PayloadAction<{value: string; key: string}>) => {
      state.searchString = action.payload.value;
    },
    toggleSort: (state, action: PayloadAction<{value: SortDirection; key: string}>) => {
      let settingExist = state.pageSettings.find((setting) => setting.key === action.payload.key);
      if (settingExist) {
        settingExist.sort = action.payload.value;
      } else {
        state.pageSettings.push({
          key: action.payload.key,
          sort: action.payload.value,
          itemView: ViewType.BoxView,
        });
      }
    },
  },
});

export const selectPageSetting = (store: AppState) => store.pageSettingReducer.pageSettings;

export const selectPageSettingByKey = (key: string) =>
  createSelector(selectPageSetting, (settings) => {
    return settings.find((setting) => setting.key === key);
  });

export const {toggleView, searchChanged, toggleSort} = pageSettingSlice.actions;

export default pageSettingSlice.reducer;

import { Button, Divider, SxProps, TextField, Typography } from '@mui/material';
import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IDeviceDetailsDto } from '../../../../api/api';
import { useAuth } from '../../../../auth/auth-provider';
import { localized } from '../../../../i18n/i18n';
import { updateFacilityAndDealerOnDevice } from '../../../../state/features/devices/operation';
import { selectFacilitiesForDealerAndSubDealers } from '../../../../state/features/facilities/facilities-slice';
import { getFacilitiesForDealerAndSubDealers } from '../../../../state/features/facilities/operation';
import { selectFacilityDrawerOpen } from '../../../../state/features/facility-drawer/facility-drawer-slice';
import { selectOrganizationsForDropdown } from '../../../../state/features/organization/organization-slice';
import { FlexColumn } from '../../default-components/flex-column';
import { FlexRow } from '../../default-components/flex-row';
import { FacilityDialog } from '../facilities/facility-dialog';
import { DealerSelect } from './dealer-select';
import { FacilitySelect } from './facility-select';

const styles: { [key: string]: SxProps } = {
    selectRow: {
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: 225,
        maxWidth: 250,
        marginRight: 2,
    },
    buttonRow: {
        justifyContent: 'left',
        alignItems: 'center',
        width: 175,
    },
    textStyle: {
        marginRight: 2,
    },
    dividerStyle: {
        marginRight: 2,
    },
};

interface Props {
    device: IDeviceDetailsDto;
    closeOuterDialog: () => void;
}

export const DeviceDetailsDealerAndFacility: FC<Props> = React.memo(({ device, closeOuterDialog }) => {
    const dispatch = useDispatch();

    const allOrganizations = useSelector(selectOrganizationsForDropdown);
    const allFacilities = useSelector(selectFacilitiesForDealerAndSubDealers);
    const facilityDrawerOpen = useSelector(selectFacilityDrawerOpen);

    const [selectedOrganizationId, setSelectedOrganizationId] = useState<number | ''>(device.dealerId);
    const [selectedFacilityId, setSelectedFacilityId] = useState<number | ''>(device.facilityId);
    const [selectedDepartment, setSelectedDepartment] = useState<string | undefined>(device.department);

    const [saveDisabled, setSaveDisabled] = useState<boolean>(true);
    const [departmentDisabled, setDepartmentDisabled] = useState<boolean>(true);

    useEffect(() => {
        setSelectedFacilityId(device.facilityId);
        setSelectedOrganizationId(device.dealerId);
        setSelectedDepartment(device.department);
        setDepartmentDisabled(device.facilityId === 0);
    }, [device]);

    useEffect(() => {
        if (selectedOrganizationId === '' || selectedOrganizationId === null || facilityDrawerOpen) return;

        dispatch(getFacilitiesForDealerAndSubDealers(selectedOrganizationId));
    }, [selectedOrganizationId, facilityDrawerOpen, dispatch]);

    useEffect(() => {
        setSaveDisabled(selectedOrganizationId === '');
    }, [selectedFacilityId, selectedOrganizationId]);

    const handleNewOrganizationSelected = useCallback(
        (selectedOrgId: number) => {
            const org = allOrganizations.find((o) => o.id === selectedOrgId);

            setSelectedOrganizationId(org?.id ?? '');
            setSelectedFacilityId('');
            setSelectedDepartment('');
            setDepartmentDisabled(true);
        },
        [allOrganizations],
    );

    const handleNewFacilitySelected = useCallback(
        (selectedFacilityId: number) => {
            const facility = allFacilities.find((f) => f.id === selectedFacilityId);
            if (!facility) return;

            setSelectedFacilityId(facility.id);
            setDepartmentDisabled(false);
            setSelectedDepartment('');

            if (selectedOrganizationId === facility.organizationId) return;

            setSelectedOrganizationId(facility.organizationId);
        },
        [allFacilities],
    );

    const handleDepartmentChange = useCallback(
        (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setSelectedDepartment(e.target.value);
        },
        [device],
    );

    const handleSubmitChangeDealerAndFacility = useCallback(() => {
        dispatch(
            updateFacilityAndDealerOnDevice({
                deviceSerialNumber: device.serialNumber,
                facilityId: selectedFacilityId === '' ? 0 : selectedFacilityId,
                organizationId: selectedOrganizationId === '' ? 0 : selectedOrganizationId,
                department: selectedDepartment,
            }),
        );
        closeOuterDialog();
    }, [selectedFacilityId, selectedOrganizationId, selectedDepartment, device]);

    return (
        <FlexRow>
            <FacilityDialog dealerId={selectedOrganizationId === '' ? null : selectedOrganizationId} />
            <FlexRow sx={styles.selectRow}>
                <Typography sx={styles.textStyle}>{localized('Dealer')}</Typography>
                <DealerSelect
                    selectedOrganizationId={selectedOrganizationId ?? 0}
                    onDealerSelected={handleNewOrganizationSelected}
                />
            </FlexRow>
            <Divider orientation="vertical" sx={styles.dividerStyle} />
            <FlexColumn>
                <FlexRow sx={styles.selectRow}>
                    <FlexColumn>
                        <Typography sx={styles.textStyle}>{localized('Facility')}</Typography>
                    </FlexColumn>
                    <FlexColumn>
                        <FacilitySelect
                            selectedFacilityId={selectedFacilityId ?? 0}
                            selectedOrganizationId={selectedOrganizationId ?? 0}
                            onNewFacilitySelected={handleNewFacilitySelected}
                            allowCreateNewFacilities={true}
                        />
                    </FlexColumn>
                </FlexRow>
            </FlexColumn>
            <Divider orientation="vertical" sx={styles.dividerStyle} />
            <FlexRow sx={styles.selectRow}>
                <Typography sx={styles.textStyle}>{localized('Department')}</Typography>
                <TextField
                    defaultValue={selectedDepartment}
                    value={selectedDepartment}
                    sx={styles.selectStyle}
                    disabled={departmentDisabled}
                    onChange={handleDepartmentChange}
                />
            </FlexRow>
            <Divider orientation="vertical" sx={styles.dividerStyle} />
            <FlexRow sx={styles.buttonRow}>
                <Button onClick={handleSubmitChangeDealerAndFacility} disabled={saveDisabled}>
                    {localized('Save')}
                </Button>
            </FlexRow>
        </FlexRow>
    );
});

export interface AutoCompleteOptions {
    label: string;
    id: number;
}

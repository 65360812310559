import React, { FC, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from '../../default-components/form/form';
import { TextInput } from '../../default-components/form/text-input';
import { nameof } from '../../../../utilities/platform-helpers/nameof-helper';
import { IOrganization, Organization } from '../../../../api/api';
import { localized, localizedInterpolation } from '../../../../i18n/i18n';
import { requiredValidator } from '../../../../utilities/platform-helpers/validator-helper';
import { DividerWithMargin } from '../users/user-form-etac';
import { Stack } from '@mui/material';
import { RoundButton } from '../round-button';
import { saveOrganization, updateOrganization } from '../../../../state/features/organization/operation';
import { selectOrganizations } from '../../../../state/features/organization/organization-slice';
import { SelectInput } from '../../default-components/form/select-input';
import { DropdownOption } from '../../default-components/baisc-dropdown/basic-search-dropdown';
import { SortByProperty } from '../../../../utilities/platform-helpers/sorting-helper';
import { useAuth } from '../../../../auth/auth-provider';
import { selectOrganization } from '../../../../state/features/settings/settings-slice';

interface Props {
    handleClose: () => void;
    existingDealer?: IOrganization;
}

export const DealerForm: FC<Props> = React.memo(({ handleClose, existingDealer }) => {
    const dispatch = useDispatch();
    const { isGlobalAdmin, user } = useAuth();
    const selectedOrganization = useSelector(selectOrganization);
    const allOrganizations = useSelector(selectOrganizations);

    const allOrganizationsOptions = useMemo(() => {
        //Map organizations to dropdown - ignore self
        let allOrganizationsTmp = allOrganizations.flatMap((o) =>
            o.id !== (existingDealer?.id ?? -1) && o.name ? new DropdownOption(o.name ?? '', o.id) : [],
        );
        allOrganizationsTmp.sort((a, b) => SortByProperty(a.label, b.label));
        //Add empty field to remove parent;
        if (isGlobalAdmin) {
            allOrganizationsTmp.push(new DropdownOption(localized('None'), null));
        }
        return allOrganizationsTmp;
    }, [allOrganizations]);

    const save = useCallback(
        (data: any) => {
            data.parentOrganization = allOrganizations.find((a) => a.id === data.parentOrganization);
            const dealer = new Organization();
            dealer.init(data);

            if (existingDealer) {
                dispatch(updateOrganization(dealer));
            } else {
                dispatch(saveOrganization(dealer));
            }
        },
        [dispatch, existingDealer],
    );

    const defaultValues = useMemo(() => {
        if (existingDealer) {
            return {
                ...existingDealer,
                parentOrganization: existingDealer.parentOrganization?.id,
            };
        } else if (!isGlobalAdmin) {
            return { parentOrganization: selectedOrganization?.id };
        }
    }, [existingDealer]);

    return (
        <Form onSubmit={save} defaultValues={defaultValues}>
            <TextInput
                name={nameof<Organization>('name')}
                label={localized('Name') + ' *'}
                validators={requiredValidator}
            />
            <DividerWithMargin />
            <SelectInput
                name={nameof<Organization>('parentOrganization')}
                label={localized('ParentOrganization')}
                options={allOrganizationsOptions}
                noDefault={isGlobalAdmin}
            />
            <TextInput name={nameof<Organization>('department')} label={localized('Department')} />
            <TextInput name={nameof<Organization>('address1')} label={localized('Address1')} />
            <TextInput name={nameof<Organization>('address2')} label={localized('Address2')} />
            <TextInput name={nameof<Organization>('postalCode')} label={localized('PostalCode')} />
            <TextInput name={nameof<Organization>('city')} label={localized('City')} />
            <TextInput name={nameof<Organization>('country')} label={localized('Country')} />
            <TextInput name={nameof<Organization>('phone')} label={localized('Phone')} />
            <TextInput name={nameof<Organization>('email')} label={localized('Email')} />
            <TextInput name={nameof<Organization>('comment')} label={localized('Comment')} />
            <Stack spacing={1} direction={'row'} marginTop={'auto'}>
                <RoundButton width={'50%'} slim color={'secondary'} onClick={handleClose}>
                    {localized('Cancel')}
                </RoundButton>
                <RoundButton width={'50%'} type={'submit'} slim>
                    {localizedInterpolation(existingDealer !== undefined ? 'SaveInt' : 'CreateNewInt', {
                        area: localized('Dealer'),
                    })}
                </RoundButton>
            </Stack>
        </Form>
    );
});

import {FC, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {RedirectUriStorageKey} from '../utilities/constants';
import {useAuth} from './auth-provider';

export const LoggedOut: FC = () => {
  const {isAuthenticated} = useAuth();
  const navigate = useNavigate();

  // This is a failsafe if we somehow should land on this page after login
  useEffect(() => {
    if (isAuthenticated) {
      let redirectUrl: string | null = window.sessionStorage.getItem(RedirectUriStorageKey);
      if (redirectUrl) {
        window.sessionStorage.removeItem(RedirectUriStorageKey);
        navigate(redirectUrl);
      } else {
        navigate('/');
      }
    }
  }, [isAuthenticated, navigate]);

  // This page is shown after log out redirect and before login redirect
  // The page is empty to hide that there are two redirects on log out
  return null;
};

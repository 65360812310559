import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { localized } from '../../../i18n/i18n';
import { getOrganizations } from '../../../state/features/organization/operation';
import { PageHeader } from '../../components/default-components/page-header.tsx/page-header';
import { PageTitle } from '../../components/default-components/page-title/page-title';
import { MainPageContainer } from '../../components/etac-components/main-page-container';
import { UsersTable } from '../../components/etac-components/users/users-table';
import { UsersHeaderContent } from '../../components/etac-components/users/users-header-content';

export const Users: FC = React.memo(() => {
    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState('');
    const [dealerFilter, setDealerFilter] = useState<string>();

    useEffect(() => {
        dispatch(getOrganizations());
    }, [dispatch]);

    return (
        <PageTitle title={localized('Users')}>
            <PageHeader title={localized('UserAdministration')} area={'users'}>
                <UsersHeaderContent
                    searchText={searchText}
                    setSearchText={setSearchText}
                    setDealerFilter={setDealerFilter}
                />
            </PageHeader>
            <MainPageContainer>
                <UsersTable searchText={searchText} dealerFilter={dealerFilter} />
            </MainPageContainer>
        </PageTitle>
    );
});

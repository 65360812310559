import React, { FC, useCallback, useState } from 'react';
import { PageTitle } from '../../components/default-components/page-title/page-title';
import { localized } from '../../../i18n/i18n';
import { PageHeader } from '../../components/default-components/page-header.tsx/page-header';
import { MainPageContainer } from '../../components/etac-components/main-page-container';
import { DealersTable } from '../../components/etac-components/dealers/dealers-table';
import { RoundButton } from '../../components/etac-components/round-button';
import { Stack, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { openDealerDrawer } from '../../../state/features/dealer-drawer/dealer-drawer-slice';
import { useAuth } from '../../../auth/auth-provider';
import { DealerDrawer } from '../../components/etac-components/dealers/dealer-drawer';
import { SimpleSearchbar } from '../../components/etac-components/simple-searchbar';

export const Dealers: FC = React.memo(() => {
    const dispatch = useDispatch();
    const { isAdmin } = useAuth();

    const [searchText, setSearchText] = useState('');

    const openDrawer = useCallback(() => {
        dispatch(openDealerDrawer());
    }, [dispatch]);

    return (
        <PageTitle title={localized('Dealers')}>
            <PageHeader title={localized('Dealers')} area={'dealers'}>
                <Stack direction={'row'} spacing={'8px'} marginLeft={'auto'} alignItems={'center'}>
                    <SimpleSearchbar searchText={searchText} setSearchText={setSearchText} />
                    {isAdmin && (
                        <RoundButton slim width={'178px'} onClick={openDrawer}>
                            <Typography>{localized('NewDealer')}</Typography>
                        </RoundButton>
                    )}
                </Stack>
            </PageHeader>
            <MainPageContainer>
                <DealersTable searchText={searchText} />
            </MainPageContainer>
            <DealerDrawer />
        </PageTitle>
    );
});

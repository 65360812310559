import { createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { AppState } from '../../store';
import {
    deleteFromOrganization,
    deleteUser,
    getAllUsers,
    getUserByAuthId,
    getCurrentUserPermissions,
    getUsers,
    resendInvitationEmail,
    saveUser,
    updateUser,
    updateUserConsent,
    updateUserLastActive,
    stopSimulation,
    createSimulation,
} from './operation';
import { UsersState } from './types';
import { ICurrentUserPermissions } from '../../../api/api';

const initialState: UsersState = {
    users: [],
    activeUser: undefined,
    pending: false,
    isSuccess: false,
    allUsers: [],
    currentUserPermissions: {} as ICurrentUserPermissions,
    simulateUserRefreshPending: false,
};

export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        refreshAfterSimulate: (state) => {
            if (!state.simulateUserRefreshPending) return;

            setTimeout(() => window.location.reload(), 1000);
            state.simulateUserRefreshPending = false;
        },
    },
    extraReducers: (builder) => {
        // Get
        builder.addCase(getUsers.fulfilled, (state, action) => {
            state.pending = false;
            state.users = action.payload;
        });
        // Get by auth id
        builder.addCase(getUserByAuthId.fulfilled, (state, action) => {
            state.pending = false;
            state.activeUser = action.payload;
        });
        // Save
        builder.addCase(saveUser.fulfilled, (state, action) => {
            state.pending = false;
            state.isSuccess = true;
            state.allUsers.push(action.payload);
        });
        //Update User last active time
        builder.addCase(updateUserLastActive.fulfilled, (state, action) => {
            state.pending = false;
            state.isSuccess = true;
        });
        // Delete
        builder.addCase(deleteUser.fulfilled, (state, action) => {
            state.pending = false;
            const index = state.allUsers.findIndex((user) => user.id === action.meta.arg.id);
            if (index > -1) {
                state.allUsers.splice(index, 1);
            }
        });
        // Delete organization user
        builder.addCase(deleteFromOrganization.fulfilled, (state, action) => {
            state.pending = false;
            state.isSuccess = true;
            const index = state.allUsers.findIndex((user) => user.id === action.meta.arg.user.id);
            if (index > -1) {
                state.allUsers.splice(index, 1);
            }
        });
        // Simulation
        builder.addCase(stopSimulation.fulfilled, (state, action) => {
            state.simulateUserRefreshPending = true;
        });
        builder.addCase(createSimulation.fulfilled, (state, action) => {
            state.simulateUserRefreshPending = true;
        });
        // Get All
        builder.addCase(getAllUsers.fulfilled, (state, action) => {
            state.pending = false;
            state.allUsers = action.payload;
        });
        builder.addCase(getCurrentUserPermissions.fulfilled, (state, action) => {
            state.pending = false;
            state.currentUserPermissions = action.payload;
        });
        // Update
        builder.addMatcher(
            isAnyOf(updateUser.fulfilled, updateUserConsent.fulfilled, resendInvitationEmail.fulfilled),
            (state, action) => {
                state.pending = false;
                state.isSuccess = true;
                const index = state.allUsers.findIndex((user) => user.id === action.payload.id);
                if (index > -1) {
                    state.allUsers[index] = action.payload;
                }
            },
        );

        builder.addMatcher(
            isAnyOf(
                deleteFromOrganization.pending,
                deleteFromOrganization.rejected,
                updateUserConsent.pending,
                updateUserConsent.rejected,
                updateUserLastActive.pending,
                updateUserLastActive.rejected,
                updateUser.pending,
                updateUser.rejected,
                saveUser.pending,
                saveUser.rejected,
                getCurrentUserPermissions.pending,
                getCurrentUserPermissions.rejected,
                stopSimulation.pending,
                stopSimulation.rejected,
                createSimulation.pending,
                createSimulation.rejected,
            ),
            (state) => {
                state.isSuccess = false;
            },
        );
        builder.addMatcher(
            isAnyOf(
                resendInvitationEmail.pending,
                getAllUsers.pending,
                deleteUser.pending,
                getUserByAuthId.pending,
                getUsers.pending,
                deleteFromOrganization.pending,
                updateUserConsent.pending,
                updateUserLastActive.pending,
                updateUser.pending,
                saveUser.pending,
                getCurrentUserPermissions.pending,
                stopSimulation.pending,
                createSimulation.pending,
            ),
            (state) => {
                state.pending = true;
            },
        );
        builder.addMatcher(
            isAnyOf(
                resendInvitationEmail.rejected,
                getAllUsers.rejected,
                deleteUser.rejected,
                getUserByAuthId.rejected,
                getUsers.rejected,
                deleteFromOrganization.rejected,
                updateUserConsent.rejected,
                updateUserLastActive.rejected,
                updateUser.rejected,
                saveUser.rejected,
                getCurrentUserPermissions.rejected,
                stopSimulation.rejected,
                createSimulation.rejected,
            ),
            (state) => {
                state.pending = false;
            },
        );
    },
});

export const selectUsers = (store: AppState) => store.usersReducer.users;
export const selectSimulateUserRefreshPending = (store: AppState) => store.usersReducer.simulateUserRefreshPending;

export const selectCurrentUser = (store: AppState) => store.usersReducer.activeUser;

export const selectCurrentUserIsSimulation = (store: AppState) =>
    store.usersReducer.currentUserPermissions?.isSimulated ?? false;

export const selectUserById = (id: number) =>
    createSelector(selectUsers, (users) => {
        return users.find((user) => user.id === id);
    });

export const selectUserByAuthId = (authId: string) =>
    createSelector(selectUsers, (users) => {
        return users.find((user) => user.authId === authId);
    });

export const selectAllUsers = (store: AppState) => store.usersReducer.allUsers;

export const selectCurrentUserPermissions = (store: AppState) => store.usersReducer.currentUserPermissions;

// eslint-disable-next-line
export const { refreshAfterSimulate } = usersSlice.actions;

export default usersSlice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUserDto } from '../../../api/api';
import { AppState } from '../../store';
import { deleteFromOrganization, saveUser, updateUser } from '../users/operation';
import { UserDrawerState } from './types';

const initialState: UserDrawerState = {
    drawerOpen: false,
    forSimulation: false,
};

export const userDrawerSlice = createSlice({
    name: 'userDrawer',
    initialState,
    reducers: {
        setUserToEdit: (state, action: PayloadAction<IUserDto | undefined>) => {
            state.activeUser = action.payload;
            state.drawerOpen = true;
        },
        openEditUserDrawer: (state) => {
            state.drawerOpen = true;
        },
        closeEditUserDrawer: (state) => {
            state.activeUser = undefined;
            state.drawerOpen = false;
            state.forSimulation = false;
        },
        setForSimulation: (state, action: PayloadAction<boolean>) => {
            state.forSimulation = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(saveUser.fulfilled, (state, _) => {
            state.drawerOpen = false;
            state.forSimulation = false;
            state.activeUser = undefined;
        });

        builder.addCase(updateUser.fulfilled, (state, _) => {
            state.drawerOpen = false;
            state.forSimulation = false;
            state.activeUser = undefined;
        });

        builder.addCase(deleteFromOrganization.fulfilled, (state) => {
            state.drawerOpen = false;
            state.forSimulation = false;
            state.activeUser = undefined;
        });
    },
});

export const selectUserForEdit = (store: AppState) => store.userDrawerReducer.activeUser;
export const selectUserDrawerOpen = (store: AppState) => store.userDrawerReducer.drawerOpen;
export const selectForSimulation = (store: AppState) => store.userDrawerReducer.forSimulation;
export const { setUserToEdit, openEditUserDrawer, closeEditUserDrawer, setForSimulation } = userDrawerSlice.actions;

export default userDrawerSlice.reducer;

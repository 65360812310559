import React, { FC } from 'react';
import { localized } from '../../../../i18n/i18n';
import { BasicHeadCell, BasicTable } from '../basic-table/basic-table';
import { TableBody } from '@mui/material';
import { IDeviceSnapshotSlimDto } from '../../../../api/api';
import { ServiceHistoryTableRow } from './service-history-table-row';

const headCells: BasicHeadCell[] = [
    { id: 'time', label: localized('Date&Time'), width: '170px' },
    { id: 'name', label: localized('Name'), width: '190px' },
    { id: 'reason', label: localized('Reason'), width: '160px' },
    { id: 'remarks', label: localized('Remarks') },
];

interface Props {
    serviceHistory: IDeviceSnapshotSlimDto[];
}

export const ServiceHistoryTable: FC<Props> = React.memo(({ serviceHistory }) => {
    return (
        <BasicTable headCells={headCells}>
            <TableBody>
                {serviceHistory.map((snapshot) => (
                    <ServiceHistoryTableRow snapshot={snapshot} />
                ))}
            </TableBody>
        </BasicTable>
    );
});

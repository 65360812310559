import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { localized } from '../../../i18n/i18n';
import { getDeviceErrorsBySerialNumber } from '../../../state/features/devices/operation';
import { PageTitle } from '../../components/default-components/page-title/page-title';
import { DeviceErrorTable } from '../../components/etac-components/device-errors/device-error-table';
import { MainPageContainer } from '../../components/etac-components/main-page-container';
import { SubPageHeader } from '../../components/etac-components/sub-page-header';

export const DeviceErrors: FC = React.memo(() => {
    const { serialNumber } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        if (serialNumber === undefined) return;

        dispatch(getDeviceErrorsBySerialNumber(Number(serialNumber)));
    }, [serialNumber, dispatch]);

    return (
        <PageTitle title={localized('DeviceErrors')}>
            <SubPageHeader title={localized('DeviceErrors')} includeMetadata />
            <MainPageContainer>
                <DeviceErrorTable />
            </MainPageContainer>
        </PageTitle>
    );
});

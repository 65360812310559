import { IconButton, MenuItem, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useSnackbar } from 'notistack';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { ReactComponent as SaveIcon } from '../../../assets/icons/save-icon.svg';
import { localized } from '../../../i18n/i18n';
import { useDownloadFile } from '../../../services/useDownloadFile';
import { fileDownloadClient } from '../../../state/api-clients';
import { primaryColor, primaryTextColor } from '../../../styles/color-constants';
import { FlexColumn } from '../default-components/flex-column';

enum FileToDownload {
    ServiceTool = 'Molift Service Tool Setup.exe',
    Driver = 'MoliftServiceTool-cabledriver.exe',
}

export const DownloadButton: FC = React.memo(() => {
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [showDropdown, setShowDropdown] = useState<boolean>(false);
    const [fileToDownload, setFileToDownload] = useState<FileToDownload>();
    const preDownloading = useCallback(() => {
        setLoading(true);
    }, []);

    const postDownloading = useCallback(() => {
        setLoading(false);
    }, []);

    const getFileName = useCallback(() => {
        return fileToDownload?.toString() ?? '';
    }, [fileToDownload]);

    const onError = useCallback(() => {
        setLoading(false);
        enqueueSnackbar(localized('DownloadWentWrong'), {
            variant: 'error',
            autoHideDuration: 3000,
        });
    }, [enqueueSnackbar]);

    const apiDefinition = useCallback(() => {
        return fileToDownload === FileToDownload.ServiceTool
            ? fileDownloadClient.downloadTool()
            : fileDownloadClient.downloadDriver();
    }, [fileToDownload]);

    const { ref, url, download, name } = useDownloadFile({
        apiDefinition,
        preDownloading,
        postDownloading,
        onError,
        getFileName,
    });

    const handleDownload = useCallback(
        (file: FileToDownload) => {
            setFileToDownload(file);
        },
        [fileToDownload],
    );

    useEffect(() => {
        if (fileToDownload !== undefined) {
            download();
            setFileToDownload(undefined);
        }
    }, [fileToDownload]);

    const toggleDropdown = useCallback(() => {
        setShowDropdown(!showDropdown);
    }, [showDropdown]);

    return (
        <>
            <IconButton
                disabled={loading}
                sx={{
                    marginTop: '8px',
                    textTransform: 'none',
                    color: primaryTextColor,
                    '&:hover': {
                        backgroundColor: 'transparent',
                        color: primaryColor,
                    },
                }}
                disableRipple
                onClick={toggleDropdown}>
                <FlexColumn alignItems={'center'}>
                    {!loading ? <SaveIcon height={'30px'} width={'30px'} /> : <CircularProgress color={'inherit'} />}
                    {!showDropdown ? (
                        <Typography fontSize={'14px'}>{localized('Download')}</Typography>
                    ) : (
                        <FlexColumn sx={{ paddingTop: 2 }}>
                            <MenuItem
                                sx={{ padding: 0, paddingLeft: 1, paddingRight: 1, color: 'black', fontSize: 14 }}
                                onClick={() => handleDownload(FileToDownload.ServiceTool)}>
                                {localized('ServiceTool')}
                            </MenuItem>
                            <MenuItem
                                onClick={() => handleDownload(FileToDownload.Driver)}
                                sx={{ padding: 0, paddingLeft: 1, paddingRight: 1, color: 'black', fontSize: 14 }}>
                                {localized('USBDriver')}
                            </MenuItem>
                        </FlexColumn>
                    )}
                </FlexColumn>
            </IconButton>
            <a href={url} download={name} className="hidden" ref={ref} />
        </>
    );
});

import React, { FC, useMemo } from 'react';
import { IDeviceDetailsDto } from '../../../../api/api';
import { Divider, Stack } from '@mui/material';
import { DeviceDetailsLine } from './device-details-line';
import { localized, localizedInterpolation } from '../../../../i18n/i18n';
import { formatDateTimeFull, formatDateTimeShort } from '../../../../utilities/helpers/date-format-helper';
import { modelNumberToType, noInspectionVersions } from '../../../../utilities/helpers/model-type-helper';
import moment from 'moment';

interface Props {
    device: IDeviceDetailsDto | undefined;
}

export const DeviceDetailsFields: FC<Props> = React.memo(({ device }) => {
    const deviceType = useMemo(() => modelNumberToType(device?.productNumber), [device]);
    const showInspections = !noInspectionVersions.includes(deviceType);
    const nextInspectionField = useMemo(() => {
        return device?.lastInspection === undefined
            ? undefined
            : formatDateTimeShort(moment(device?.lastInspection).add(1, 'years').toDate());
    }, [device?.lastInspection]);

    const displayServiceInterval = useMemo(
        () =>
            device !== undefined &&
            device.remainingLiftsToService !== undefined &&
            device.liftsSinceService !== undefined &&
            device.remainingLiftsToService + device.liftsSinceService >= 1000,
        [device],
    );

    return (
        <>
            <Stack spacing={'8px'} width={'300px'} divider={<Divider />}>
                <DeviceDetailsLine text={localized('ServiceCount')} value={device?.servicesPerformed} />
                <DeviceDetailsLine
                    text={localized('LastServiceTime')}
                    value={formatDateTimeFull(device?.lastService)}
                />
                <DeviceDetailsLine
                    text={localized('ServiceInterval')}
                    value={
                        displayServiceInterval
                            ? (device?.remainingLiftsToService ?? 0) + (device?.liftsSinceService ?? 0)
                            : ''
                    }
                />
                {showInspections && (
                    <>
                        <DeviceDetailsLine
                            text={localized('InspectionCount')}
                            value={device?.inspectionsPerformed}
                            divider
                        />
                        <DeviceDetailsLine
                            text={localized('LastInspectionTime')}
                            value={formatDateTimeShort(device?.lastInspection)}
                            divider
                        />
                        <DeviceDetailsLine text={localized('NextPeriodicInspection')} value={nextInspectionField} />
                    </>
                )}
            </Stack>
            <Stack spacing={'8px'} width={'240px'} divider={<Divider />}>
                <DeviceDetailsLine
                    text={localizedInterpolation('TotalLiftsClass', { classNumber: 1 })}
                    value={device?.liftsClass1}
                />
                <DeviceDetailsLine
                    text={localizedInterpolation('TotalLiftsClass', { classNumber: 2 })}
                    value={device?.liftsClass2}
                />
                <DeviceDetailsLine
                    text={localizedInterpolation('TotalLiftsClass', { classNumber: 3 })}
                    value={device?.liftsClass3}
                />
                <DeviceDetailsLine
                    text={localizedInterpolation('TotalLiftsClass', { classNumber: 4 })}
                    value={device?.liftsClass4}
                />
                <DeviceDetailsLine text={localized('Sum')} value={device?.totalLifts} />
                <DeviceDetailsLine text={localized('StdLiftsAfterService')} value={device?.liftsSinceService} />
                <DeviceDetailsLine
                    text={localized('RemainingStdLiftsTillService')}
                    value={displayServiceInterval ? device?.remainingLiftsToService : ''}
                />
            </Stack>
            <Stack spacing={'8px'} width={'260px'} divider={<Divider />}>
                <DeviceDetailsLine text={localized('SerialNumber')} value={device?.serialNumber} />
                <DeviceDetailsLine text={localized('SoftwareVersion')} value={device?.softwareVersion} />
                <DeviceDetailsLine text={localized('MaxKg')} value={device?.maxKg} />
                <DeviceDetailsLine text={localized('MaxCurrent')} value={device?.maxCurrent} />
                <DeviceDetailsLine text={localized('LatestSnapshot')} value={formatDateTimeFull(device?.appTime)} />
            </Stack>
        </>
    );
});

import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
    EnhancedTable,
    EnhancedTableCell,
    EnhancedTableRow,
    getComparator,
    HeadCell,
    Order,
} from '../enhanced-table/enhanced-table';
import { localized } from '../../../../i18n/i18n';
import { IconButton, TableBody } from '@mui/material';
import { EnhancedTablePagination } from '../enhanced-table/enhanced-table-pagination';
import { useDispatch, useSelector } from 'react-redux';
import { selectOrganizations } from '../../../../state/features/organization/organization-slice';
import { IOrganization } from '../../../../api/api';
import { AppState } from '../../../../state/store';
import { getOrganizations } from '../../../../state/features/organization/operation';
import { primaryTextColor } from '../../../../styles/color-constants';
import { ReactComponent as GearIcon } from '../../../../assets/icons/gear-icon.svg';
import { openDealerDrawerForEdit } from '../../../../state/features/dealer-drawer/dealer-drawer-slice';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../../state/routes';
import { LeftBorderRadius, RightBorderRadius } from '../../../../styles/styling-constants';
import { useAuth } from '../../../../auth/auth-provider';

const headCells: HeadCell[] = [
    { id: 'name', label: localized('Name'), width: '350px' },
    { id: 'department', label: localized('Department'), width: '200px' },
    { id: 'address1', label: localized('Address'), width: '200px' },
    { id: 'postalCode', label: localized('PostalCode'), width: '150px' },
    { id: 'city', label: localized('City'), width: '150px' },
    { id: 'country', label: localized('Country'), width: '150px' },
    { id: 'phone', label: localized('Phone'), width: '150px' },
    { id: 'email', label: localized('Email'), width: '150px' },
    { id: 'comment', label: localized('Comment'), width: '150px' },
    { id: 'parentOrganization.name', label: localized('ParentOrganization'), width: '150px' },
    { id: 'edit', label: '', width: '25px' },
];

interface Props {
    searchText: string;
}

export const DealersTable: FC<Props> = React.memo(({ searchText }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isAdmin } = useAuth();

    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof any>('name');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    const dealers: any[] = useSelector(selectOrganizations);
    const showSpinner = useSelector(
        (store: AppState) => store.organizationReducer.pending && store.organizationReducer.organizations.length === 0,
    );

    useEffect(() => {
        dispatch(getOrganizations());
    }, [dispatch]);

    useEffect(() => {
        if (page !== 0) {
            setPage(0);
        }
        // eslint-disable-next-line
    }, [searchText, order, orderBy]);

    const openDrawer = useCallback(
        (dealer: IOrganization) => (e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            dispatch(openDealerDrawerForEdit(dealer));
        },
        [dispatch],
    );

    const displayFacilities = useCallback(
        (dealer: IOrganization) => () => {
            navigate(`${routes.facilities}/${dealer.id}`);
        },
        [navigate],
    );

    const sortedDealers = useMemo(
        () =>
            dealers
                .slice()
                .filter(
                    (d) =>
                        d.name.toLowerCase().includes(searchText.toLowerCase()) ||
                        (d.parentOrganization !== undefined &&
                            d.parentOrganization.name.toLowerCase().includes(searchText.toLowerCase())),
                )
                .sort(getComparator(order, orderBy)),
        [dealers, order, orderBy, searchText],
    );

    return (
        <>
            <EnhancedTable
                headCells={headCells}
                order={order}
                setOrder={setOrder}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
                showSpinner={showSpinner}>
                <TableBody>
                    {sortedDealers
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row: IOrganization) => (
                            <EnhancedTableRow key={row.id} sx={{ cursor: 'pointer' }} onClick={displayFacilities(row)}>
                                <EnhancedTableCell sx={LeftBorderRadius}>{row.name}</EnhancedTableCell>
                                <EnhancedTableCell>{row.department}</EnhancedTableCell>
                                <EnhancedTableCell>{`${row.address1 ?? ''} ${row.address2 ?? ''}`}</EnhancedTableCell>
                                <EnhancedTableCell>{row.postalCode}</EnhancedTableCell>
                                <EnhancedTableCell>{row.city}</EnhancedTableCell>
                                <EnhancedTableCell>{row.country}</EnhancedTableCell>
                                <EnhancedTableCell>{row.phone}</EnhancedTableCell>
                                <EnhancedTableCell>{row.email}</EnhancedTableCell>
                                <EnhancedTableCell>{row.comment}</EnhancedTableCell>
                                <EnhancedTableCell>{row.parentOrganization?.name}</EnhancedTableCell>
                                <EnhancedTableCell sx={RightBorderRadius}>
                                    <IconButton
                                        sx={{ padding: 0, color: primaryTextColor }}
                                        onClick={openDrawer(row)}
                                        disabled={!isAdmin}>
                                        <GearIcon />
                                    </IconButton>
                                </EnhancedTableCell>
                            </EnhancedTableRow>
                        ))}
                </TableBody>
            </EnhancedTable>
            <EnhancedTablePagination
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                count={dealers.length}
            />
        </>
    );
});

import { Autocomplete, TextField } from '@mui/material';
import React, { FC, useCallback, useMemo } from 'react';
import { localized } from '../../../../i18n/i18n';

export enum FilterOptions {
    User = 'User',
    Facility = 'Facility',
    Dealer = 'Dealer',
    Department = 'Department',
    DeviceModel = 'DeviceModel',
}

type FilterOptionsMapper = Record<FilterOptions, any>;
export const filterOptionsMapper: FilterOptionsMapper = {
    [FilterOptions.User]: localized('Users'),
    [FilterOptions.Facility]: localized('Facility'),
    [FilterOptions.Dealer]: localized('Dealer'),
    [FilterOptions.Department]: localized('Department'),
    [FilterOptions.DeviceModel]: localized('Model'),
};

interface Props {
    handleFilterChange: (val: FilterOptions) => void;
}

export const DeviceFilterSelector: FC<Props> = React.memo((props) => {
    const autocompleteRender = useCallback((params) => <TextField {...params} label={localized('ChooseFilter')} />, []);

    const autoCompleteRenderOption = useCallback((props, option) => {
        return (
            <li {...props} key={option?.id}>
                {option?.label}
            </li>
        );
    }, []);

    const filterOptions = useMemo(() => {
        return Object.entries(FilterOptions).map((e) => {
            return { id: e[0], label: filterOptionsMapper[e[1]] };
        });
    }, []);

    const handleCurFilterChanged = useCallback(
        (event: any, value: { label: string; id: string | FilterOptions } | null) => {
            if (!value) return;
            props.handleFilterChange(value.id as unknown as FilterOptions);
        },
        [],
    );

    return (
        <Autocomplete
            sx={{ width: 250, backgroundColor: 'white' }}
            options={filterOptions}
            renderOption={autoCompleteRenderOption}
            renderInput={autocompleteRender}
            onChange={handleCurFilterChanged}
        />
    );
});

import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateUserLastActive } from '../../state/features/users/operation';

export const UpdateUserActiveTime: FC = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateUserLastActive());
    }, []);
    return null;
};
